@import '../../RootVariable.scss';

.PriceAndDiscountWrapper {
    .top {
        display: flex;
        align-items: center;
        &.right {
            justify-content: flex-end;
        }
        @include montSerrat(18px, 900, 1.22);
        .basic {
        }
        .point {
            color: red;
            margin-left: 8px;
        }
    }
    .bottom {
        @include montSerrat(14px, 500, 1.29);
        text-decoration: line-through;
        color: $grey_4;
        &.right {
            text-align: right;
        }
    }
}
