/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import './RootVariable.scss';

html {
  height: 100vh;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  // font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  color: #101010;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Thin.otf') format(otf);
//     font-weight: 100;
// }

// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Light.otf') format(otf);
//     font-weight: 300;
// }
// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.otf') format(otf);
//     font-weight: 400;
// }

// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.otf') format(otf);
//     font-weight: 500;
// }

// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf') format(otf);
//     font-weight: 800;
// }

//notoSans fonts definition
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Thin.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Thin.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Light.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Light.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Medium.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Black.woff2) format('woff2'), url(./assets/fonts/Noto_Sans_KR/NotoSansKR-Black.woff) format('woff');
  font-display: swap;
}

//Montserrat fonts definition
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('./assets/fonts/Montserrat/Montserrat-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/Montserrat/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('./assets/fonts/Montserrat/Montserrat-Black.woff') format('woff');
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select {
  font-family: 'Noto Sans KR', sans-serif;
}

// @font-face {
//     font-family: 'NotoSansKR';
//     src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf') format(otf);
//     font-weight: normal;
// }

/* @font-face {
    font-family: 'NotoSansKR';
    src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf') format(otf);
    font-style: bold;
} */
/* @font-face {
    font-family: 'NotoSansKR';
    src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Bold.otf') format('otf');
    font-style: bold;
}
@font-face {
    font-family: 'NotoSansKR';
    src: url('./assets/fonts/Noto_Sans_KR/NotoSansKR-Thin.otf') format('otf');
    font-style: thin;
} */

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Medium.woff');
  unicode-range: U+0030-0039;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Bold.woff');
  unicode-range: U+0030-0039;
  font-weight: 800;
}

body {
  font-family: 'Noto Sans KR';
}

// .App {
//     overflow: hidden;
// }
.appTopArea {
  transition: all 300ms;

  &.border {
    border-bottom: 1px solid #ebebeb;
  }
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px !important;
  height: 40px !important;
  top: 44% !important;
  position: absolute;
  transform: translateY(-50%);
}

.swiper-button-next {
  right: -20px !important;
}

.swiper-button-prev {
  left: -20px !important;
}

.swiper-button-next:focus {
  outline: unset;
}

.swiper-button-prev:focus {
  outline: unset;
}

.swiper-button-next::after {
  background-image: url('./assets/p-btn-arrow-next-defualt-72.svg');
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.swiper-button-next:hover::after {
  background-image: url('./assets/p-btn-arrow-next-over-72.svg') !important;
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.swiper-button-prev::after {
  background-image: url('./assets/p-btn-arrow-prev-defualt-72.svg') !important;
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.swiper-button-prev:hover::after {
  background-image: url('./assets/p-btn-arrow-prev-over-72.svg') !important;
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* tab index -1 인 경우 == disable */
.swiper-button-prev[aria-disabled='true']::after {
  background-image: url('./assets/p-btn-arrow-prev-disable-72.svg') !important;
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: default;
}

.swiper-button-disabled {
  opacity: 1 !important;
}

.swiper-button-next[aria-disabled='true']::after {
  background-image: url('./assets/p-btn-arrow-next-disable-72.svg') !important;
  content: '' !important;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: default;
  opacity: 1 !important;
}

.hr {
  border-bottom: solid 1px #ebebeb;
  width: 100%;
  margin-top: 16px;
  height: 0;
}

@media (min-width: $breakPointTbl) {
  .swiper-button-next::after {
    background-image: url('./assets/p-btn-arrow-next-defualt-72.svg');
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-next:hover::after {
    background-image: url('./assets/p-btn-arrow-next-over-72.svg') !important;
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .swiper-button-prev::after {
    background-image: url('./assets/p-btn-arrow-prev-defualt-72.svg') !important;
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
  }

  .swiper-button-prev:hover::after {
    background-image: url('./assets/p-btn-arrow-prev-over-72.svg') !important;
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  /* tab index -1 인 경우 == disable */
  .swiper-button-prev[aria-disabled='true']::after {
    background-image: url('./assets/p-btn-arrow-prev-disable-72.svg') !important;
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: default;
  }
  .swiper-button-disabled {
    opacity: 1 !important;
  }

  .swiper-button-next[aria-disabled='true']::after {
    background-image: url('./assets/p-btn-arrow-next-disable-72.svg') !important;
    content: '' !important;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: default;
    opacity: 1 !important;
  }
}

.swiper-pagination-bullet {
  opacity: 1 !important;
}

.AppContentArea {
  margin-top: $GNBHeightMo + $GNBSubCollectionHeightMo;

  &.noSub {
    margin-top: $GNBHeightMo;
  }

  &.mobileNoSub {
    margin-top: $GNBHeightMo;
  }
}

@media (min-width: $breakPointTbl) {
  .AppContentArea {
    &.mobileNoSub {
      margin-top: $GNBHeightMo + $GNBSubCollectionHeightMo;
    }
  }
}

@media (min-width: $breakPointDt) {
  .projectWrapper .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .hr {
    margin-top: 20px;
  }

  .AppContentArea {
    margin-top: $GNBHeightDt + $GNBSubCollectionHeightDt;

    &.noSub {
      margin-top: $GNBHeightDt;
    }

    &.mobileNoSub {
      margin-top: $GNBHeightDt + $GNBSubCollectionHeightDt;
    }
  }
}

.projectWrapper {
  //height: auto;
  // overflow-X를 지우면 모바일에서 x축 스크롤이 발생함
  //overflow-x: hidden;
  //overflow-y: auto;
  // 불 필요한 스크롤이 생기는 걸 방지해 주는 역할을 함
  padding-bottom: 10px;
  margin-bottom: -10px;
}

// .projectWrapper {
//     -ms-overflow-style: none; /* IE and Edge */
//     scrollbar-width: none; /* Firefox */
// }
// .projectWrapper::-webkit-scrollbar {
//     display: none; /* Chrome, Safari, Opera*/
// }

.WrapperDimBg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: map-get($map: $zIndex, $key: dimBg);
  display: none;
  // z-index: map-get($map: $zIndex, $key: header);
  &.dimBgOn {
    display: block;
  }
}

.SpinnerWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: map-get($map: $zIndex, $key: spinner);
  display: none;
  // z-index: map-get($map: $zIndex, $key: header);
}

.SpinnerWrapper.SpinnerOn {
  display: flex;
  align-items: center;
  justify-content: center;
}

hr {
  border-color: $grey_5;
  border-top: none;
  margin: 0;
}

// * {
//     outline: solid 1px blue;
// }
*:focus {
  outline: none;
}

input:focus {
  // border: solid 1px ;
  box-shadow: 0 0 0 1px $black;
}

input {
  @include notoSans(13px, normal, 1.38);

  color: $black;
  padding: 15px 16px;
  border: none;
  background-color: $grey_1;
  width: 100%;
  border-radius: 6px;

  &.inputError {
    color: $red;
    box-shadow: 0 0 0 1px $red;
  }
}

// ::placeholder {
//     color: black;
//     // border: solid 1px $red;
// }

input.inputError::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $red;
}

input.inputError::-moz-placeholder {
  /* Firefox 19+ */
  color: $red;
}

input.inputError:-ms-input-placeholder {
  /* IE 10+ */
  color: $red;
}

input.inputError:-moz-placeholder {
  /* Firefox 18- */
  color: $red;
}

// input::placeholder {
//     color: $grey_6;
// }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $grey_6;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $grey_6;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $grey_6;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $grey_6;
}

.SpinnerWrapper {
  .spinner {
    height: 1em;
    width: 1em;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.5em;
    margin-top: -0.5em;
    content: '';
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
    background: url('./assets/spinnertmp.svg') center center;
    background-size: cover;
    line-height: 1;
    text-align: center;
    font-size: 2em;
    color: rgba(0, 0, 0, 0.75);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.cartCountIcon {
  width: 29px;
  height: 29px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $black;
  color: $white;
  @include montSerrat(13.2px, bold, 1.27);

  &:hover {
    cursor: pointer;
  }
}
