@import '../../RootVariable.scss';

.noLogInImg {
    width: 64px;
    height: 64px;
}
.noLogInWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
}

.noLogInText {
    margin-left: 8px;
    @include notoSans(13px, 500, 1.38);
}

.linkIcon {
    margin-left: 10px;
}
.personalizedContainer {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
}

// @media (min-width: $breakPointTbl) {
//     .noLogInWrapper {
//         justify-content: center;
//         // height: 120px;
//     }

//     .personalizedContainer.homeContainers {
//         margin-top: 128px;
//         padding-top: 0;
//         padding-bottom: 0;
//         width: 100vw !important;
//         max-width: unset !important;
//         // display: flex;
//         // justify-content: center;
//         // align-items: center;
//     }

//     .noLogInImg {
//         // width: 160px;
//         // height: 160px;
//         // position: relative;
//         // top: -40px;
//     }

//     .noLogInText {
//         margin-left: 8px;
//         // @include notoSans(26px, bold, 1.46);
//     }

//     // .linkIcon {
//     //     margin-left: 4px;
//     //     width: 20px;
//     //     height: 20px;
//     // }
// }

@media (min-width: $breakPointDt) {
    .noLogInWrapper {
        justify-content: center;
        height: 120px;
        border-radius: 16px;
    }

    .personalizedContainer {
        border-radius: 16px;
    }

    .personalizedContainer.homeContainers {
        margin-top: 128px;
        padding: 0 64px;
        // width: 100vw;
        max-width: 1200px !important;
        border-radius: 16px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }

    .noLogInImg {
        width: 160px;
        height: 160px;
        position: relative;
        top: -25px;
    }

    .noLogInText {
        margin-left: 8px;
        @include notoSans(26px, bold, 1.46);
    }

    .linkIcon {
        margin-left: 4px;
        width: 20px;
        height: 20px;
    }
}
