@import '../../RootVariable.scss';

.topBannerWrapper {
    padding-left: 16px;
    padding-right: 36px;
    padding-top: 11px;
    padding-bottom: 11px;
    background-color: $black;
    color: $white;
    text-align: center;
    position: relative;
    @include notoSans(13px, normal, 1.38);

    &.banner {
        &:hover {
            cursor: pointer;
        }
    }
}

.topBannerCloseIcon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

@media (min-width: $breakPointTbl) {
    .topBannerCloseIcon {
        // position: absolute;
        right: 16px;
        // top: 50%;
        // transform: translateY(-50%);
    }
}

@media (min-width: $breakPointDt) {
    .topBannerWrapper {
        padding-left: 16px;
        padding-right: 36px;
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: $black;
        color: $white;
        text-align: center;
        position: relative;
        @include notoSans(15px, 500, 1.47);
    }

    .topBannerCloseIcon {
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
    }
}
