@import '../../RootVariable.scss';

.CartCouponAndPointContainer {
    width: 100%;
    padding: 16px 0 40px;

    .couponTopText {
        @include notoSans(18px, 900, 1.44);
        margin-bottom: 24px;
    }
}

.couponSelector {
    padding: 20px 16px;
    margin-bottom: 10px;
    border: 1px solid $grey_5;
    border-radius: 16px;
}

@media (min-width: $breakPointTbl) {
    .CartCouponAndPointContainer {
        padding: 0;

        .couponTopText {
            margin-bottom: 0;
            padding: 16px 0;
            @include notoSans(18px, 900, 1.44);
        }
    }
}

@media (min-width: $breakPointDt) {
    .CartCouponAndPointContainer {
        padding: 0;

        .couponTopText {
            padding: 24px 0;
            margin-bottom: 0;
            @include notoSans(26px, bold, 1.46);
        }

        .couponSelector {
            padding: 32px;
            margin-bottom: 20px;
            border-radius: 16px;
        }
    }
} ;
