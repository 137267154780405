@import '../../RootVariable.scss';

.CustomHtmlPage {
    // display: flex !important;
    // flex-direction: column !important;
    // justify-content: center !important;
    // text-align: center;
    margin-top: 140px;

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .section-eatsfun-lineup {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        .list-eatsfun-lineup {
            display: flex;
            flex-wrap: wrap;
            .item {
                flex-grow: 1;
                display: block !important;

                img {
                    margin: 0 auto;
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .CustomHtmlPage {
        margin-top: 140px;
    }
}
