@import '../../RootVariable.scss';

.maintenance-page-wrapper {
  top: 0;
  left: 0;
  @include notoSans(40px, bold, normal);
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
  z-index: 9999;
}
