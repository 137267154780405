@import '../../RootVariable.scss';

.dropdownWrapper {
    user-select: none;
    cursor: pointer;
    border: solid 1px transparent;
    border-radius: 6px;
    transition: all 500ms;

    &.isExpanded {
        border: solid 1px $black;
        background-color: $white;

        .defaultTextArea {
            color: $black;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        .dropDowns {
            // overflow: visible;
            height: unset;
            min-height: 30px;
            max-height: 238px;
            overflow-y: auto;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .dropdownItems,
        .defaultTextArea {
            &:hover {
                background-color: $grey_2;
                @include notoSans(13px, bold, 1.38);

                .optionName {
                    font-weight: bold;
                }
            }
        }
    }
}

.defaultTextArea {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropDowns {
    // display: flex;
    height: 0;
    min-height: 0px;
    transition: all 500ms;
    overflow: hidden;
}

.dropdownItems {
    min-height: 40px;
    padding: 11px 16px;
    color: $black;

    &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .priceWrapper {
        display: flex;
        align-items: center;

        .displayPrice {
            margin-right: 2px;
        }
    }

    .optionName {
        &.soldout {
            color: #999999;
        }
    }

    .soldout {
        color: $red;
        margin-left: auto;
    }

    &.dogFood {
        &:hover {
            .dogFoodOptioName {
                font-weight: bold;
            }
        }
        .dogFoodOptioName {
            margin-bottom: 4px;
            @include notoSans(13px, normal, 1.38);

            span {
                color: #999999;
            }

            &.soldout {
                color: #999999;
            }
        }

        .dogFoodPriceWrapper {
            display: flex;
            align-items: center;

            .dogFoodPrice {
                margin-right: 2px;
                @include montSerrat(13px, bold, 1.38);
            }

            .dogFoodRegularPrice {
                color: #bbbbbb;
                @include montSerrat(12px, 500, 1.25);
                text-decoration: line-through;
            }

            .dogFoodDiscountRate {
                margin-left: 6px;
                color: $red;
                @include montSerrat(13px, 900, 1.38);
            }

            .soldout {
                margin-left: auto;
                color: $red;
                @include notoSans(12px, normal, 1.33);
            }
        }
    }
}

.dropdownItems.options {
    height: 68px;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
    }

    .optionInfo {
        display: flex;
        flex-direction: column;

        .optionName {
            margin-left: 0;
            margin-bottom: 4px;
            @include notoSans(13px, normal, 1.38);

            span {
                color: #999999;
            }

            &.soldout {
                color: #999999;
            }
        }

        .optionPrice {
            display: flex;
            align-items: center;
            margin-right: 2px;

            .displayPrice.options {
                @include montSerrat(13px, bold, 1.38);
            }
            .regularPrice {
                margin-left: 2px;
                color: $grey_4;
                @include montSerrat(12px, 500, 1.25);
                text-decoration: line-through;
            }

            &.soldout {
                color: $red;
                @include notoSans(12px, normal, 1.33);
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .dropdownWrapper {
        border-radius: 10px;

        .defaultTextArea {
            padding: 20px 24px;
            @include notoSans(16px, normal, 1.5);

            .expandButton {
                width: 32px;
                height: 32px;
            }
        }

        .dropdownItems.options {
            .optionInfo {
                &:hover {
                    .optionName {
                        font-weight: bold;
                    }
                }
                .optionName {
                    @include notoSans(16px, normal, 1.5);
                }

                .optionPrice {
                    .displayPrice.options {
                        @include montSerrat(16px, bold, 1.13);
                    }

                    .regularPrice {
                        @include montSerrat(14px, 500, normal);
                    }
                }
            }
        }

        &.isExpanded {
            .defaultTextArea {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                @include notoSans(16px, normal, 1.5);

                &:hover {
                    @include notoSans(16px, bold, 1.5);
                }
            }

            .dropdownItems {
                padding: 20px 24px;
                @include notoSans(16px, normal, 1.5);

                &:hover {
                    @include notoSans(16px, bold, 1.5);
                }

                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                &.dogFood {
                    .dogFoodOptioName {
                        @include notoSans(16px, normal, 1.5);
                    }

                    &:hover {
                        .dogFoodOptioName {
                            font-weight: bold;
                        }
                    }

                    .dogFoodPriceWrapper {
                        .dogFoodPrice {
                            margin-right: 2px;
                            @include montSerrat(16px, bold, 1.13);
                        }

                        .dogFoodRegularPrice {
                            @include montSerrat(14px, 500, normal);
                        }

                        .dogFoodDiscountRate {
                            margin-left: 8px;
                            @include montSerrat(16px, bold, 1.13);
                        }

                        .soldout {
                            @include notoSans(14px, normal, 1.43);
                        }
                    }
                }
            }
        }
    }
}
