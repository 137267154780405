@import '../../RootVariable.scss';

body {
    overflow-x: unset;
}

.BBPortfolioPage {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    text-align: center;
    // margin-top: 100px;
    height: 100%;

    .wpb_column {
        .vc_column_container {
            padding-top: 100px;
        }
    }

    a {
        text-decoration: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

@media (min-width: $breakPointDt) {
    .BBPortfolioPage {
        margin-top: 150px;
    }
}
