@import '../../RootVariable.scss';

.CouponSelectorCategoryText {
    margin-bottom: 16px;
    @include notoSans(14px, bold, 1.43);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .couponCnt {
        color: $red;
        @include notoSans(12px, bold, 1.33);
    }
}

.couponDropDown {
    margin-bottom: 24px;
    color: $grey_6;
    @include notoSans(13px, normal, 1.38);
    background-color: $grey_1;

    .dropdownItems {
        .couponDesc {
            color: #999999;
            @include notoSans(12px, normal, 1.33);
        }
    }
}

.couponDropDown.addCouponCode {
    margin-bottom: 8px;
}

.couponCodeBox {
    display: flex;
    margin-bottom: 24px;

    input {
        width: 100%;
        margin-right: 8px;
    }

    .applyCouponBtn {
        padding: 14px 19px;
        border: 1px solid $grey_5;
        border-radius: 6px;
        @include notoSans(14px, 500, 1.43);
        white-space: nowrap;

        &:hover {
            cursor: pointer;
        }
    }
}

.appliedCouponList {
    display: flex;
    flex-direction: column;

    .appliedCouponItem {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .couponPriceBox {
        display: flex;
        align-items: center;

        .couponDeleteBtn {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .couponName {
        // width: 160px;
        width: 60%;
        color: $grey_6;
        @include notoSans(13px, normal, 1.38);
    }

    .couponPrice {
        @include montSerrat(13px, bold, 1.38);
        margin-right: 8px;
    }
}

@media (min-width: $breakPointDt) {
    .CouponSelectorCategoryText {
        margin-bottom: 20px;
        @include notoSans(18px, bold, 1.44);

        .couponCnt {
            @include notoSans(14px, bold, 1.43);
        }
    }

    .couponDropDown {
        margin-bottom: 40px;

        .dropdownItems {
            .couponDesc {
                color: #999999;
                @include notoSans(14px, normal, 1.43);
            }
        }
    }

    .couponCodeBox {
        margin-bottom: 40px;
        input {
            border-radius: 10px;
            padding: 20px 24px;
            @include notoSans(16px, normal, 1.5);
        }

        .applyCouponBtn {
            padding: 20px 32px;
            border-radius: 10px;
            @include notoSans(16px, 500, 1.5);
        }
    }

    .appliedCouponList {
        .appliedCouponItem {
            margin-bottom: 12px;
        }

        .couponName {
            @include notoSans(16px, normal, 1.5);
        }

        .couponPrice {
            @include montSerrat(16px, bold, 1.13);
            margin-right: 16px;
        }
    }
}
