@import '../../RootVariable.scss';

.OrderDetails {
    padding: 93px 32px 40px;
    background-color: #fff;

    &.subscription {
        background-color: $grey_2;
    }

    .orderDetailTitle {
        margin-bottom: 4px;
        @include notoSans(26px, 900, 1.38);
    }

    .orderDetailSubTitle {
        color: $grey_4;
        @include notoSans(13px, 500, 1.38);
    }

    .orderInfos {
        margin-top: 24px;
        padding: 20px;
        background-color: #fff;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

        table {
            width: 100%;

            tbody {
                tr {
                    th {
                        width: 80px;
                        margin-right: 8px;
                        color: $grey_4;
                        @include notoSans(13px, normal, 1.38);
                        text-align: left;
                    }

                    td {
                        border: 0px solid transparent;
                        background-clip: padding-box;
                        @include notoSans(13px, 500, 1.38);

                        .copyBtn {
                            color: $grey_6;
                            text-decoration: underline;
                            display: inline-block;
                            float: right;

                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }

                tr > td + td {
                    border-left-width: 10px;
                }

                tr + tr > td {
                    border-top-width: 10px;
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .OrderDetails {
        padding: 123px 120px 40px;

        .orderDetailTitle,
        .orderDetailSubTitle {
            text-align: center;
        }
    }
}

@media (min-width: $breakPointDt) {
    .OrderDetails {
        padding: 180px 0 72px;

        .orderDetailsContainer {
            max-width: 1200px;
            margin: 0 auto;
        }

        .orderDetailTitle {
            margin-bottom: 16px;
            @include notoSans(44px, 900, 1.36);
        }

        .orderDetailSubTitle {
            color: $grey_4;
            @include notoSans(16px, 500, 1.5);
        }

        .orderInfos {
            max-width: 588px;
            margin: 56px auto 0;
            padding: 32px;

            table {
                tbody {
                    tr {
                        th {
                            width: 96px;
                            @include notoSans(16px, normal, 1.5);
                        }

                        td {
                            @include notoSans(16px, 500, 1.5);
                        }
                    }
                }
            }
        }
    }
}
