@import '../../RootVariable.scss';

.homeSingleProductHighlightContainer {
    margin-top: 80px;
    .defaultPagenation {
        display: flex;
        justify-content: center;
    }
}

.homeSingleProductHighlightHeadText {
    @include notoSans(24px, 900, 1.42);
}

.homeSingleProductSlider {
    margin-top: 24px;

    .productCardThumbNailArea {
        border-radius: 20px;
        .productCardThumbNail {
            div {
                border-radius: 20px;
            }
        }

        // .label {
        //     padding: 1px 6px;
        //     color: $white;
        //     background-color: $black;
        //     @include notoSans(11px, 500, normal);
        //     white-space: nowrap;
        //     z-index: 11;
        //     border-radius: 4px;
        //     transform: rotate(-15deg);
        //     position: absolute;
        //     top: 20px;
        // }
    }
}

.productCardOnTheImageBadge {
    position: absolute;
    background-color: $black;
    @include notoSans(11px, 500, unset);
    color: $white;
    border-radius: 4px;
    padding: 5px 5px;
    padding-bottom: 7px;
    transform: rotate(-20deg);
    left: -3.5%;
    top: 8%;
    display: block;
    z-index: 1;
}

.goToProductListBtn {
    width: 100%;
    margin-top: 24px;
    padding: 12px 100px 10px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    color: #999999;
    @include notoSans(13px, normal, 1.38);
    text-align: center;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        border-color: $black;
        color: $black;
        transition: all 0.3s ease-in;
    }
}

@media (min-width: $breakPointTbl) {
    .homeSingleProductHighlightContainer {
        .defaultPagenation {
            justify-content: flex-end;
            position: relative;
            top: -60px;
        }
        .productCardThumbNailArea {
            border-radius: 30px;

            .productCardThumbNail {
                div {
                    border-radius: 30px;
                }
            }
        }
    }

    // .homeSingleProductHighlightHeadText {
    //     @include notoSans(40px, 900, normal);
    // }

    .homeSingleProductSlider {
        margin-top: 56px;

        .swiper-button-prev,
        .swiper-button-next {
            top: 45% !important;
        }

        .swiper-button-prev {
            left: -17px !important;
        }

        .swiper-button-next {
            right: -17px !important;
        }
    }

    .productCardOnTheImageBadge {
        top: 12%;
        left: -2%;
    }

    .goToProductListBtn {
        width: 344px;
        margin: 0 auto;

        &:hover {
            width: 360px;
        }
    }
}

@media (min-width: $breakPointDt) {
    .homeSingleProductHighlightContainer {
        .defaultPagenation {
            justify-content: flex-end;
            position: relative;
            top: -105px;
        }

        @include setSliderNavigationSizeAndPosition(56px, 40%);

        .swiper-button-next,
        .swiper-button-prev {
            top: 45% !important;
        }

        .swiper-button-prev {
            left: -30px !important;
        }

        .swiper-button-next {
            right: -30px !important;
        }
    }

    .homeSingleProductHighlightHeadText {
        @include notoSans(40px, 900, normal);
    }

    .homeSingleProductSlider {
        margin-top: 56px;
    }

    .productCardOnTheImageBadge {
        padding: 5px 8px;
        @include notoSans(14px, 500, 1.43);
    }

    .goToProductListBtn {
        width: 176px;
        margin: 0;
        padding: 18px 43px 16px;
        border-radius: 36px;
        @include notoSans(15px, normal, 1.47);

        &:hover {
            width: 192px;
        }
    }
}
