@import '../../../RootVariable.scss';
.navSliderContentWrapper {
    cursor: pointer;
    position: relative;
    word-break: keep-all;
    padding-top: 12px;
    padding-bottom: 12px;
}

.navSlider {
    .swiper-slide {
        width: fit-content;
    }

    position: relative;
    // overflow: visible;
    .navSliderPrevNav {
        position: absolute;
        top: 50%;
        height: 100%;
        align-items: center;
        display: flex;
        transform: translateY(-50%);
        left: 0;
        z-index: map-get($map: $zIndex, $key: onSliderCard);
        background-image: linear-gradient(
                        to right,
                        #fff 63%,
                        rgba(255, 255, 255, 0)

        );
    }
    .navSliderNextNav {
        position: absolute;
        top: 50%;
        height: 100%;
        align-items: center;
        display: flex;
        transform: translateY(-50%);
        right: 0;
        z-index: map-get($map: $zIndex, $key: onSliderCard);
        background-image: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0),
                        #fff 63%
        );
    }
}
.navSliderContentImg {
    // width: 70px;
    height: 24px;
}

.navSliderContentText {
    @include notoSans(16px, bold, 1.5);
    color: $grey_4;

    &.targetEl {
        color: $black;
    }

    &.badge {
        display: flex;
        align-items: center;

        img {
            width: 18px;
            height: 18px;
            margin-right: 2px;
        }
    }
}

.navSliderHighlighterBar {
    width: 100%;
    height: 2px;
    background-color: $black;
    bottom: -5px;
    position: absolute;
}

// @media (min-width: $breakPointTbl) {
//     .navSliderContentImg {
//         // width: 70px;
//         height: 36px;
//     }
//     .navSliderContentWrapper {
//         height: 36px;
//         display: flex;
//         align-items: center;
//     }

//     .navSliderHighlighterBar {
//         bottom: -16px;
//     }
// }

.navSliderPrevNav {
    cursor: pointer;
}
.navSliderNextNav {
    cursor: pointer;
}
.navSliderPrevNav {
    .disabledPrevButton {
        display: none;
    }
}
.navSliderNextNav {
    .disabledNextButton {
        display: none;
    }
}

.navSliderPrevNav[aria-disabled='true'] {
    .disabledPrevButton {
        display: block;
    }
    .activePrevButton {
        display: none;
    }
}

.navSliderNextNav[aria-disabled='true'] {
    .disabledNextButton {
        display: block;
    }
    .activeNextButton {
        display: none;
    }
}

.navSliderNavButtons {
    display: none;
}

// 여기를 고쳐서 이미지를 갈아 끼우면 됨

@media (min-width: $breakPointDt) {
    .navSlider {
        &.withSizeConditionalNavigation {
            &.collection {
                .swiper-container {
                    overflow: hidden !important;
                }
            }
            .swiper-container {
                padding-left: 34px;
                padding-right: 34px;
                overflow: visible;
            }
            // overflow: hidden;
        }
    }

    .navSliderNavButtons {
        display: block;
    }

    .navSliderContentImg {
        // width: 70px;
        height: 34px;
    }
    .navSliderContentWrapper {
        height: 36px;
        display: flex;
        align-items: center;
    }

    .navSliderHighlighterBar {
        bottom: -16px;
    }

    .navSliderContentText {
        &.badge {
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}
