@import '../../RootVariable.scss';

.productPageWrapper {
    //margin-top: 120px;
    //overflow-x: hidden;
    // max-width: 100vw;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;

    .miniCartArea {
        width: 600px;
    }
}

#NavigationTab {
    width: 100%;
    // height: 57px;
    position: sticky;
    // top: 0 !important;
    align-self: flex-start;
    background-color: white;
    z-index: 8;
    transition: all 500ms ease 0s;
}

.navigationLine {
    margin-top: 40px;
    height: 8px;
    width: 100%;
    background-color: $grey_1;
}

.content-area {
    // margin-top: 60px;
    width: 100%;
    // overflow-x: hidden;
    .evnetBanner {
        margin-top: 60px;
        cursor: pointer;

        padding-left: 32px;
        padding-right: 32px;

        display: flex;
        justify-content: center;
        width: 100%;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0;
    }
}

.mobilCTA {
    width: 100%;
    height: 88px;
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 7%);
    z-index: 21;

    .bottom-area {
        padding-top: 0;

        .naverPayBtn {
            height: 20px;

            &.white {
                background-color: white;
            }
        }
    }
}

// .miniCartArea {
//     margin:0 32px;
// }

@media (min-width: $breakPointTbl) {
    .mobilCTA {
        display: none;
    }
    .productPageWrapper {
        padding-left: $TblContentPadding;
        padding-right: $TblContentPadding;
    }
}

@media (min-width: $breakPointDt) {
    .productPageWrapper {
        margin-top: 188px;
        max-width: 1328px;
        width: 100%;
        margin-left: $TblContentPadding;
        margin-right: $TblContentPadding;
    }
    .productPageDtPositioning {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.dtBanner {
    display: none;
}

// #NavigationTab{
//     transition: all 500ms ease 0s;
// }

// #ch-plugin-launcher{
//     display: none;
// }
.menu-area {
    display: none;
}

#review {
    padding-right: 32px;
    padding-left: 32px;
}

#related {
    overflow: hidden;
}

.product-name {
    @include notoSans(300, 14px, 14px);
    color: $black;
}

// .widthSetter{
//     padding-left: 0;
//     padding-right: 0;
//     width: 100%;
// }
.product-page-banner-mo {
    cursor: pointer;
    width: 100%;
    height: 40px;
    background-color: $blue_bacon;
    color: $white;
    @include flex_center;
    @include notoSans(14px, bold, unset);
    position: relative;
}
.product-page-banner-dt {
    display: none;
}

.upSellingPopUp {
    // transition: $transition_default;

    &.popup-bg {
        background-color: $dim_bg;
        display: flex;
        align-items: flex-end;

        .popup-box {
            position: relative;
            height: 440px;
            width: 100%;
            top: unset;
            left: unset;
            right: unset;
            // bottom: 0;

            background-color: $main_bg;
            transition: transform 500ms;
            // transition-delay: 1s;

            transform: translateY(100%);
            // overflow: hidden;
        }
        overflow: hidden;
    }

    .upSellButton {
        width: 100%;
        position: absolute;
        bottom: 0;
    }

    &.visible {
        opacity: 1;
        z-index: 40;
        &.popup-bg {
            .popup-box {
                transform: translateY(0);
            }
        }
    }

    &.invisible {
        opacity: 0;
        z-index: -100;
    }

    .upSellContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 32px;

        .discountBadge {
            padding: 5px 12px;
            background-color: $main-point;
            @include notoSans(500, 12px, 12px);
            color: $main-bg;
        }

        .upSellText1 {
            margin-top: 16px;
            @include notoSans(300, 18px, 18px);
            color: $black;
        }

        .upSellText2 {
            margin-top: 8px;
            @include notoSans(300, 13px, 13px);
            color: $black;
            text-align: center;
        }

        .upSellImage {
            margin-top: 24px;
            width: 264px;
            height: auto;
        }

        .upSellClose {
            position: absolute;
            right: 16px;
        }

        .upSellClose.mo {
            display: inline;
            top: -46px;
        }

        .upSellClose.dt {
            display: none;
            top: 16px;
        }
    }
}

// #NavigationTab{
//     // position: sticky;
//     //padding-top: 48px;
//     //z-index: 10;
//     // top: 61.8px;
//     //width: 100%;
//     //background-color: #FFFFF5;
// }

// #NavigationTab.fixed-mo{
//     // position: sticky;
//     // top: 56px;
//     //background-color: #FFFFF5;
//     // opacity: 0.9;
//     width: 100%;
//     z-index: 9;
//     left: 0;
//     display: flex;
//     justify-content: center;
//     .navigation-wrapper{
//         margin-top: 0;
//         //padding-top: 48px;
//         // padding-left: 0;
//         // padding-right: 0;
//         width: 100%;

//     }

// }

.cartIconWidthNumberDt,
.cartIconWidthNumberMo {
    &.hidden {
        display: none;
    }
}

.product-page-wrapper {
    // overflow-x: hidden;
    //margin-top: 180px;
    justify-content: center;

    .navigation-wrapper {
        padding-left: 0;
        padding-right: 0;
        background-color: $white;
    }
}

@media (min-width: $breakPointTbl) {
    .content-area {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // margin-top: 129px;
    }
    .info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 100%;

        // .widthSetter{
        //     width : 500px;

        // }
    }

    // .purchaseMenu-wrapper{
    //     padding-right: 48px;
    //     padding-left: 48px;
    // }

    // #NavigationTab{

    //     .widthSetter{
    //         width : 100%;

    //     }

    // }

    .product-page-wrapper {
        .navigation-wrapper {
            // padding-left: 48px;
            // padding-right: 48px;
        }
    }
}

@media (min-width: $breakPointDt) {
    #review {
        padding-right: unset;
        padding-left: unset;
    }
    .navigationLine {
        display: none;
    }

    .dtBanner {
        display: block;
        // margin-top: 30px;
    }
    .evnetBannerUnerSlider {
        margin-top: 30px;
        width: 100%;
        // animation: fade-in 500ms;
        // animation-fill-mode: forwards;

        text-align: center;
        overflow: visible;
        position: relative;
        // padding-left: 64px;
        // padding-right: 64px;
        // height: 72px;
        img {
            width: 1200px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .eventImgContent {
            position: relative;
            height: 72px;
            div {
                background-position: center center !important;
                background-repeat: no-repeat !important;
                background-size: 1200px !important;
            }
        }
    }

    .product-page-banner-mo {
        display: none;
    }

    .product-page-banner-dt {
        display: block;
        cursor: pointer;
        width: 100%;
        height: 40px;
        background-color: $blue_bacon;
        color: $white;
        @include flex_center;
        @include notoSans(14px, 500, 14px);
        position: relative;
        margin-bottom: 48px;

        .downloadIcon {
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .product-page-wrapper {
        display: flex;
        height: 100%;
        .coupon-item-wrapper .circle {
            background-color: $main-bg;
        }

        &.scroll {
            height: 100%;
            overflow: hidden;
            -ms-overflow-style: none;
            //scrollbar-width: 0px;
            ::-webkit-scrollbar {
                width: 16px;
            }
            ::-webkit-scrollbar-track {
                background-color: $product_back;
                //background: transparent;
            }
        }
        &.scroll:root {
            scrollbar-width: none !important;
        }
        &.scroll:hover {
            -ms-overflow-style: auto;
            ::-webkit-scrollbar {
                transition: $transitionDefault;
                display: unset;
            }

            ::-webkit-scrollbar-thumb {
                background-color: #babac0;
                border-radius: 16px;
                border: 4px solid #fff;
            }
        }
    }
    .mobile-menu {
        display: none;
    }

    .mobile-button {
        display: none;
    }

    .content-area {
        //height: 100vh;
        // margin-top: 180px;
        // margin-top: 201.5px;
        margin-top: 48px;
        max-width: 793px;
        padding-right: 103px;
        overflow-x: unset;

        &.scroll {
            height: 100%;
            overflow-y: auto;
            overflow-x: unset;
        }
    }

    .menu-area {
        // margin-top: 180px;
        // margin-top: 201.5px;
        // padding-top: 201.5px;
        padding-top: 48px;
        max-width: 407px;
        width: 100%;
        display: block;
        //background-color: $product_back;
        // height: 100%;
        //min-height: 100vh;

        border-left: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;

        .menu-area-inner {
            padding-bottom: 100px;
            &.fixed {
                position: sticky;
                top: 201.5px;
            }
            .purchaseMenu-wrapper {
                z-index: 8;
                // transition: $transition_default;

                &.atTheBottom {
                    transform: translate(-50%, 0);
                    top: auto;
                    // bottom: 380px;
                    min-height: auto;
                }
            }
        }

        &.scroll {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }

    // .widthSetter{
    //     width : 100%;

    // }

    .navigation-wrapper {
        margin-top: 0;
        // padding-left: 64px;
        // padding-right:64px;
    }

    // #NavigationTab{
    //     position: sticky;
    //     top: 120px;
    //     background-color: white;
    //     z-index: 8;
    //     transition: all 500ms ease 0s;
    // }

    // #NavigationTab.fixed-dt{

    //     //background-color: $main-bg;
    //     // opacity: 0.9;
    //     // width: 50%;
    //     padding-top: 84px;
    //     top:0;
    //     left: 0;
    //     display: flex;
    //     z-index: 9;
    //     left: -16px;
    //     // justify-content: center;
    //     .widthSetter{
    //         width: 100%;
    //     }
    //     .navigation-wrapper{
    //         margin-top: 0;

    //         width: 100%;
    //     }
    // }

    .upSellingPopUp {
        // transition: $transition_default;

        &.popup-bg {
            background-color: $dim_bg;
            display: flex;

            .popup-box {
                height: 606px;
                width: 460px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                // transition: opacity 1000ms;
                background-color: $main_bg;
                transition: unset;
                opacity: 0;
            }
        }

        .upSellButton {
            margin-top: 40px;
            width: 332px;
            position: relative;
            bottom: 0;
        }

        &.visible {
            opacity: 1;
            z-index: 40;
            &.popup-bg {
                .popup-box {
                    opacity: 1;
                    transform: translate(-50%, -50%);
                }
            }
        }

        &.invisible {
            opacity: 0;
            z-index: -20;
        }

        .upSellContent {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 64px;

            .discountBadge {
                padding: 5px 14px;
                background-color: $main-point;
                @include notoSans(500, 14px, 14px);
                color: $main-bg;
            }

            .upSellText1 {
                margin-top: 30px;
                @include notoSans(300, 20px, 20px);
                color: $black;
            }

            .upSellText2 {
                margin-top: 8px;
                @include notoSans(300, 14px, 14px);
                color: $black;
                text-align: center;
            }

            .upSellImage {
                margin-top: 32px;
                width: 332px;
                height: auto;
            }

            .upSellClose.mo {
                display: none;
            }

            .upSellClose.dt {
                display: inline;
            }
        }
    }

    // #NavigationTab{

    //     z-index: 8;
    // }

    #detail {
        width: calc(100% - 20px);
        margin: 0 auto;
    }
}

//product-detail 추가 업데이트 사항

.wpb_button,
.wpb_content_element,
ul.wpb_thumbnails-fluid > li {
    margin-bottom: 0px !important;
}

video {
    max-width: 100% !important;
}
