@import '../../RootVariable.scss';

//css가 minicart 중 유닉크한 경우에만 설정해야한다.

.miniCartPopup {
    width: 100%;
    height: auto;
    left: 0;
    bottom: 0;
    max-height: calc(100vh - 50px);
    padding: 32px;
    position: fixed;
    background-color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: miniCartPopup);

    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    // transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
    animation-name: off;

    @keyframes off {
        from {
            bottom: 0;
        }

        to {
            bottom: -100%;
        }
    }

    &.on {
        animation-name: on;

        @keyframes on {
            from {
                // top:100%;
                bottom: -100%;
            }

            to {
                //   top:150px;
                bottom: 0;
            }
        }
    }

    .OptionTitle {
        margin-bottom: 24px;
        @include notoSans(14px, bold, 1.43);
        display: flex;

        .productImg {
            width: 40px;
            height: 40px;
            border-radius: 6px;
        }

        .productInfoArea {
            margin-left: 8px;
            display: flex;
            flex-direction: column;

            .productTitle {
                @include notoSans(14px, bold, 1.43);
            }

            .priceArea {
                margin-top: 6px;
                display: flex;

                .priceInfo {
                    margin-right: 8px;

                    .price {
                        display: flex;
                        @include montSerrat(14px, 700, 1.29);
                    }

                    .won {
                        @include notoSans(14px, bold, 1.29);
                    }

                    .regularPrice {
                        @include montSerrat(12px, 500, 1);
                        text-align: unset;
                    }
                }

                .discount {
                    @include montSerrat(14px, 900, 1.29);
                    color: $red;
                }
            }
        }
    }

    .optionDropdown {
        background-color: $grey_1;

        .defaultTextArea {
            padding: 15px 16px;
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }

        &.isExpanded {
            background-color: $white;

            .defaultTextArea,
            .dropdownItems {
                color: $black;
                @include notoSans(13px, normal, 1.38);

                &:hover {
                    @include notoSans(13px, bold, 1.38);
                    background-color: $grey_2;
                }
            }
        }
    }

    .optionList,
    .productBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        &.margin {
            margin-bottom: 16px !important;
        }

        .optionItem {
            display: flex;
            flex-direction: column;
            padding: 16px;
            margin-bottom: 8px;
            background-color: $grey_1;
            border-radius: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            .optionItemHeadArea,
            .optionItemBodyArea {
                display: flex;
                // align-items: center;
                justify-content: space-between;
            }

            .optionItemHeadArea {
                margin-bottom: 8px;

                .imgAndName {
                    display: flex;

                    .optionImg {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                        border-radius: 6px;
                    }

                    .optionName {
                        color: $black;
                        @include notoSans(13px, 500, 1.38);
                    }
                }

                .deleteOptionBtn {
                    height: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .priceSection {
                display: flex;

                .discountPercentage {
                    margin-right: 10px;
                    color: $red;
                    @include montSerrat(14px, 900, 1.43);
                }

                .showPrice {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;

                    .displayPrice {
                        @include montSerrat(14px, bold, 1.43);
                    }

                    .regularPrice {
                        @include montSerrat(12px, 500, 1);
                    }
                }
            }
        }
    }

    .bottom-area {

        .cta-btns {
            margin-top: 16px;
            display: flex;
            justify-content: space-around;
            height: unset;

            .cta-btn {
                // padding: 14px 71px;
                width: 99%;
                color: $white;
                background-color: $black;
                border-radius: 24px;
                @include notoSans(14px, bold, 1.43);
                text-align: center;
                line-height: 48px;
                margin-right: 8px;
                cursor: pointer;

                &.red {
                    background-color: $red;
                }

                //마지막은 margin-right x
                &:last-child {
                    margin-right: 0;
                }
            }

            .naverPayBtn.white {
                padding: 13px;
                display: flex;

                .npay-white-btn {
                    height: 20px;
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .miniCartPopup {
        // width: 540px;
        // height: auto;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // max-height: calc(100vh - 50px);

        // padding: 32px 32px 40px 32px;
        // bottom: unset;
        // border-radius: 20px;
        // display: flex;
        // flex-direction: column;

        animation-duration: 0.1s;
        animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
        animation-name: offTa;

        @keyframes offTa {
            from {
                // top:50%;
                opacity: 1;
                //   bottom: unset;
            }

            to {
                // top:150px;
                opacity: 0.5;
                //   bottom: unset;
            }
        }

        &.on {
            // display: flex;
            // flex-direction: column;
            // top: 50%;
            // bottom: unset;
            animation-name: onTa;

            @keyframes onTa {
                from {
                    // top:100%;
                    opacity: 0.5;
                    //   bottom: unset;
                }

                to {
                    //   top:150px;
                    opacity: 1;
                    //   bottom: unset;
                }
            }
        }

        .OptionTitle {
            margin-bottom: 24px;
            @include notoSans(14px, bold, 1.43);
            display: flex;

            .productImg {
                width: 88px;
                height: 88px;
                // max-width: 88px;
                // max-height: 88px;
                border-radius: 10px;
            }

            .productInfoArea {
                margin-left: 8px;
                display: flex;
                flex-direction: column;

                .productTitle {
                    @include notoSans(14px, bold, 1.43);
                }

                .priceArea {
                    margin-top: 6px;
                    display: flex;

                    .priceInfo {
                        // display: flex;
                        margin-right: 8px;

                        .price {
                            display: flex;
                            @include montSerrat(14px, 700, 1.29);
                        }

                        .won {
                            @include notoSans(14px, bold, 1.29);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                            text-align: unset;
                        }
                    }

                    .discount {
                        @include montSerrat(14px, 900, 1.29);
                        color: $red;
                    }
                }
            }
        }

        .optionDropdown {
            // height: 48px;
            // margin-bottom: 24px;
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }
                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            max-height: 280px;
            overflow: auto;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(14px, bold, 1.43);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                        }
                    }
                }
            }
        }

        .bottom-area {
            border-top: 1px solid #ebebeb;
            padding-top: 16px;

            .total-price-area {
                // display: flex;
                // justify-content: space-between;

                .total-price-text {
                    @include notoSans(13px, bold, 1.38);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(14px, 700, 1.29);
                    color: $black;

                    .won {
                        @include notoSans(13px, bold, 1.23);
                        // background-color: $black;
                    }
                }
            }

            .cta-btns {
                margin-top: 16px;
                display: flex;
                justify-content: space-around;
                height: unset;

                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    color: $white;
                    background-color: $black;
                    border-radius: 24px;
                    @include notoSans(14px, bold, 1.43);
                    text-align: center;
                    line-height: 48px;
                    // vertical-align: middle;
                    margin-right: 8px;
                    cursor: pointer;

                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .miniCartPopup {
        width: 640px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 40px 48px 48px 48px;
        position: fixed;
        bottom: unset;
        border-radius: 20px;
        z-index: map-get($map: $zIndex, $key: miniCartPopup);

        .closeMiniCartPopupBtn {
            // position: absolute;
            top: -40px;
            right: -2px;

            .close-popup-button {
                width: 40px;
                height: 40px;
            }
        }

        

        .OptionTitle {
            margin-bottom: 32px;
            @include notoSans(24px, bold, 1.43);

            .productImg {
                width: 88px;
                height: 88px;
                // border-radius : 10px;
            }

            .productInfoArea {
                margin-left: 16px;
                display: flex;
                flex-direction: column;

                .productTitle {
                    @include notoSans(24px, bold, 1.5);
                }

                .priceArea {
                    margin-top: 6px;
                    display: flex;

                    .priceInfo {
                        // display: flex;
                        margin-right: 8px;

                        .price {
                            display: flex;
                            @include montSerrat(20px, bold, 1.2);
                        }

                        .won {
                            @include notoSans(18px, bold, unset);
                        }

                        .regularPrice {
                            @include montSerrat(16px, 500, 1.13);
                            text-align: unset;
                        }
                    }

                    .discount {
                        @include montSerrat(20px, 900, 1.2);
                        color: $red;
                    }
                }
            }
        }

        .optionDropdown {
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;

                .selectedText {
                    @include notoSans(16px, normal, 1.5);
                    color: $grey_6;
                }
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }
                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            max-height: 280px;
            overflow: auto;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(16px, 900, 1.13);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(16px, bold, 1.13);
                        }

                        .regularPrice {
                            @include montSerrat(14px, 500, normal);
                        }
                    }
                }
            }
        }

        .bottom-area {
            border-top: 1px solid #ebebeb;
            padding-top: 16px;

            .total-price-area {

                .total-price-text {
                    @include notoSans(14px, bold, 1.71);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(16px, 700, 1.13);
                    color: $black;

                    display: flex;
                    align-items: center;
                    height: 19px;
                    .won {
                        @include notoSans(15px, bold, 1.07);
                    }
                }
            }

            .cta-btns {
                margin-top: 40px;
                display: flex;
                justify-content: space-around;
                height: unset;

                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    border-radius: 36px;
                    @include notoSans(16px, bold, 1.5);
                    line-height: 56px;
                    height: 56px;
                    margin-right: 8px;
                    cursor: pointer;

                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }

                .naverPayBtn.white {
                    padding: 15px;
                    display: flex;
                    border-radius: 32px;

                    .npay-white-btn {
                        height: 24px;
                    }
                }
            }
        }
    }
}
