@import '../../RootVariable.scss';

// .styleReset {
//     all: unset;
// }

.buttonDefault {
    background-color: $black;

    // border-radius: 24px;
    @include notoSans(14px, bold, 1.43);
    color: $white;
    border: none;
    padding: 14px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    transition: all 0.3s ease-in;
    &.shadow {
        @include defaultShadow;
    }

    cursor: pointer;

    &.reversed {
        background-color: $white;
        color: $black;
    }

    &.highlighted {
        background-color: $red;
    }

    &:hover {
        padding: 14px 32px;
    }
}
.buttonDefault:focus {
    outline: none;
}

.buttonDefault:active {
    transform: scale(0.98);
}

@media (min-width: $breakPointDt) {
    .buttonDefault {
        background-color: $black;

        // border-radius: 24px;
        @include notoSans(16px, bold, 1.5);
        color: $white;
        border: none;
        padding: 20px 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 36px;
        cursor: pointer;

        &:hover {
            padding: 20px 48px;
        }
    }
}
