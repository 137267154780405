@import '../../RootVariable.scss';

.fullWidth {
    margin: 0;
    width: 100%;
}
.defaultPagenation {
    margin-top: 24px;
    .swiper-pagination-bullet {
        background-color: $grey_3;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background-color: $black;
    }
}
.thumbnailPaginationWrapper {
    margin-bottom: 26px;
}
.thumbNailPageNations {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-position: center center;
    background-size: 140%;
    background-repeat: no-repeat;
    opacity: 0.5;
    cursor: pointer;
    overflow: hidden;
}

.videoThumbnail {
    width: 100%;
}
.swiper-slide-thumb-active {
    .thumbNailPageNations {
        opacity: 1;
    }
}

.withProgress {
    position: relative !important;
    left: unset !important;
    right: unset !important;
}
.navWithProgressWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
    margin-right: 16px;
    position: relative;
    z-index: map-get($map: $zIndex, $key: onSliderCard);
}
.sliderProgressPosition {
    display: flex;
}

@media (min-width: $breakPointTbl) {
    .navWithProgressWrapper {
        width: 78px;
        margin-right: 16px;
    }
}

@media (min-width: $breakPointDt) {
    .thumbnailPaginationWrapper {
        margin-bottom: 32px;
    }

    .navWithProgressWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 140px;
        margin-right: 24px;

        @include setSliderNavigationSizeAndPosition(56px, 30%);
    }

    .thumbNailPageNations {
        width: 78px;
        height: 78px;
    }
}

// .swiper-slide:last-child {
//     margin-right: calc(100% - (100% / 3));
// }
