@import '../../RootVariable.scss';

.SNSCardWrapper {
    max-height: 370px;
    // border: solid 0.6px #ebebeb;
    border-radius: 20px;
    background-color: $white;
    // box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

.SNSCardTopArea {
    padding: 0;
    // background-color: $/;
}

.SNSCardImage {
    width: 100%;
    // height: 60%;
    height: 222px;
    object-fit: cover;
}

.SNSCardBottomArea {
    // border: solid 1px $grey_5;
    border-top: unset;
    background-color: $white;
    padding: 24px 20px;
    // align-items: flex-start;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid #ebebeb;
}

.nameInfo {
    display: flex;
    align-items: center;
}

.SNScardName {
    @include notoSans(16px, bold, 1.5);
}
.SNScardUserId {
    @include notoSans(14px, 500, 1.43);
    margin-left: 4px;
}
.textInfo {
    @include notoSans(13px, normal, 1.38);
    @include ellipsis(13px, 1.38, 2);
    margin-top: 12px;
    color: $grey_6;
}

.SNScardLink {
    @include notoSans(13px, normal, unset);
    cursor: pointer;
    display: flex;
    align-items: center;
    // justify-content: center;
    margin-top: 12px;
}

.SNSDtArrow {
    display: none;
}
@media (min-width: $breakPointTbl) {
    .SNSCardWrapper {
        height: 240px;
        flex-direction: row;
        border-radius: 16px;

        .SNSCardBottomArea {
            width: 50%;
        }
    }
    .SNSCardImage {
        width: 50%;
        height: 100%;
    }

    .SNSCardBottomArea {
        position: relative;
        padding: 24px 20px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 20px;
        // border: 1px solid #ebebeb;
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
    }

    .SNScardName {
        // @include notoSans(24px, bold, 1.5);
    }
    .SNScardUserId {
        // @include notoSans(18px, 500, 1.44);
        // margin-left: 4px;
    }
    .textInfo {
        // @include notoSans(16px, normal, 1.5);
        // @include ellipsis(16px, 1.5, 10);
        // margin-top: 29px;
        color: $grey_6;
    }

    .SNScardLink {
        // font-size: 16px;
        position: absolute;

        bottom: 24px;
        left: 20px;
    }

    // .SNSMoArrow {
    //     display: none;
    // }
    // .SNSDtArrow {
    //     display: block;
    //     position: relative;
    //     top: -1px;ew
    // }
}

@media (min-width: $breakPointDt) {
    .SNSCardWrapper {
        flex-direction: row;
        height: 450px;

        // .SNSCardBottomArea {
        //     min-width: 50%;
        // }
    }

    .SNSCardBottomArea {
        position: relative;
        padding: 40px 48px;
        // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    }

    .SNScardName {
        @include notoSans(24px, bold, 1.5);
    }
    .SNScardUserId {
        @include notoSans(18px, 500, 1.44);
        margin-left: 4px;
    }
    .textInfo {
        @include notoSans(16px, normal, 1.5);
        @include ellipsis(16px, 1.5, 10);
        margin-top: 29px;
        color: $grey_6;
    }

    .SNScardLink {
        font-size: 16px;
        position: absolute;

        bottom: 40px;
        left: 52px;
    }

    .SNSMoArrow {
        display: none;
    }
    .SNSDtArrow {
        display: block;
        position: relative;
        top: -1px;
    }
}
