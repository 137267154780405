@import '../../RootVariable.scss';

.productSearchWrapper {
    min-height: 100vh;
    height: 100%;
    background-color: #f4f4f4;
    position: fixed;
    top: 0;
    z-index: map-get($map: $zIndex, $key: search);
    width: 100%;
    opacity: 0;
    max-height: 0;
    overflow-y: scroll;
    display: none;

    &.show {
        display: block;
        opacity: 1;
        animation: fade-in 500ms;
        animation-fill-mode: forwards;
        max-height: unset;
    }

    &.resultBg {
        background-color: $white;
    }

    .contentArea {
        position: fixed;
        background-color: $white;
        width: 100vw;
        z-index: map-get($map: $zIndex, $key: searchHeader);

        //box-shadow: 0 16px 0 0 rgba(0, 0, 0, 0.06);
        .topArea {
            width: 100%;
            height: 56px;
            padding: 13px 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .dt {
                display: none;
            }

            .homeIcon {
                &.mobileHide {
                    display: none;
                }
            }

            .backBtn {
                display: block;
            }

            .homeIcon .closeIcon {
                height: 30px;
                width: 30px;
            }
        }

        .bodyArea::-webkit-scrollbar {
            display: none;
            /* Chrome, Safari, Opera*/
        }
        .bodyArea {
            align-items: center;
            display: flex;
            height: 98px;
            overflow-y: scroll;
            overflow-x: hidden;
            .searchArea {
                width: 100%;
                position: relative;
                padding: 1px;
                padding-left: 32px;
                padding-right: 32px;

                .searchInput {
                    @include notoSans(13px, normal, 1.38);
                    width: 100%;
                    height: 48px;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    background-color: #f4f4f4;
                }

                .searchInput::placeholder {
                    color: $black;
                }

                .searchButton {
                    position: absolute;
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    top: 9px;
                    right: 44px;
                }
            }
        }
    }

    .searchContent {
        padding-top: 170px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        //overflow-y: scroll;

        padding-left: 32px;
        padding-right: 32px;

        .popularArea {
            margin-top: 40px;

            .popularTitle {
                @include notoSans(18px, 700, 26px);
                color: $black;
                margin-bottom: 8px;
            }

            .popularContents {
                display: flex;
                flex-wrap: wrap;
                .popularItem {
                    @include notoSans(13px, 700, 18px);
                    width:fit-content;
                    color: $grey_6;
                    height: 34px;
                    margin-top: 8px;
                    margin-right: 8px;
                    padding: 8px 14px;
                    border-radius: 17px;
                    border: solid 1px $grey_5;
                    background-color: $white;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .searchResult {
            margin-top: 40px;
        }

        .noSearchResultWrapper {
            .title {
                @include notoSans(26px, 900, 1.38);
                margin-bottom: 4px;
            }

            .desc {
                @include notoSans(13px, 500, 1.38);
                color: #bbbbbb;
            }
        }

        .eventBanner {
            width: calc(100vw - 64px);
            padding: 15px 16px;
            border-radius: 16px;
            position: fixed;
            bottom: 40px;
            @include notoSans(13px, 500, 1.38);

            &:hover {
                cursor: pointer;
            }

            &:after {
                width: 16px;
                height: 16px;
                content: '';
                display: block;
                background: url('../../assets/m-icon-link-arrow-10-white.svg')
                no-repeat center;
                position: absolute;
                top: 15px;
                right: 16px;
            }
        }
    }

    .contentArea::after {
        content: '';
        width: inherit;
        height: 16px;
        opacity: 0.06;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000000);
        position: absolute;
    }
}

@media (min-width: $breakPointTbl) {
    .productSearchWrapper {
        .contentArea {
            .topArea {
                .homeIcon {
                    &.mobileHide {
                        display: unset;
                    }
                }

                .backBtn {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .productSearchWrapper {
        min-height: 100vh;
        background-color: #f4f4f4;
        .contentArea {
            background-color: $white;

            .topArea {
                width: 100%;
                height: 73px;
                padding-left: 80px;
                padding-right: 80px;
                padding-top: 32px;
                padding-bottom: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dt {
                    display: inherit;
                }
                .mo {
                    display: none;
                }

                .homeIcon,
                .closeIcon {
                    height: 40px;
                    width: 40px;
                }
            }

            .bodyArea {
                height: 146px;
                margin-left: auto;
                margin-right: auto;
                max-width: 1200px;
                max-height: 100vh;
                padding-top: 40px;
                padding-bottom: 40px;
                .searchArea {
                    width: 100%;
                    position: relative;
                    .searchInput {
                        @include notoSans(16px, normal, 1.5);
                        width: 100%;
                        height: 64px;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                    }

                    .searchButton {
                        position: absolute;
                        cursor: pointer;
                        width: 40px;
                        height: 40px;
                        top: 16px;
                        //right:0px;
                    }
                }
            }
        }
        .searchContent {
            padding-top: 235px;
            .popularArea {
                margin-top: 80px;
                .popularTitle {
                    @include notoSans(26px, 700, 38px);
                    margin-bottom: 12px;
                }

                .popularContents {
                    .popularItem {
                        @include notoSans(16px, 700, 24px);
                        width:fit-content;
                        height: 40px;
                        margin-top: 12px;
                        margin-right: 8px;
                        border-radius: 20px;
                    }
                }
            }

            .noSearchResultWrapper {
                .title {
                    @include notoSans(44px, 900, 1.36);
                    margin-bottom: 16px;
                }

                .desc {
                    @include notoSans(16px, 500, 1.5);
                }
            }

            .eventBanner {
                padding: 27px 32px;
                bottom: 80px;
                max-width: 1136px;
                border-radius: 30px;
                @include notoSans(18px, bold, 1.44);

                &:after {
                    width: 24px;
                    height: 24px;
                    top: 27px;
                    right: 32px;
                }
            }
        }
    }
}
