@import '../../RootVariable.scss';

.bBChildItemBox {

    display: flex;
    flex-direction: column;

    padding: 20px;
    border-radius: 16px;
    background-color: #f4f4f4;

    .boxChildItemTitle {

        @include notoSans(14px, bold, 1.43);
        color: #000000;
    }

    .boxChildItemDesc {
        @include notoSans(13px, normal, 1.38);
        color: #666666;
      
    }

    .bbChildItemBtn {
        margin-top: 16px;
        height: 48px;
        display: flex;
        padding: 4px 16px;

        justify-content: center;
        align-items: center;

        border-radius: 10px;
        background-color: #00c2b9;
        cursor: pointer;

        .bbIcon {
            width: 40px;
            height: 40px;
        }
        .btnText {
            padding-left: 8px;
            width: 100%;
            @include notoSans(13px, bold, 1.38);
            color: #ffffff;

        }

    }
}

@media (min-width: $breakPointDt) {

    .bBChildItemBox {

        // display: flex;
        // flex-direction: column;
    
        padding: 20px;
        border-radius: 16px;
        // background-color: #f4f4f4;
    
        .boxChildItemTitle {
    
            @include notoSans(16px, bold, 1.63);
            // color: #000000;
        }
    
        .boxChildItemDesc {
            margin-top: 3px;

            @include notoSans(14px, normal, 1.43);
            // color: #666666;
          
        }
    
        .bbChildItemBtn {
            margin-top: 18px;
            height: 48px;
            display: flex;
            padding: 4px 16px;
    
            justify-content: center;
            align-items: center;
    
            border-radius: 10px;
            background-color: #00c2b9;
    
            .bbIcon {
                width: 40px;
                height: 40px;
            }
            .btnText {
                padding-left: 8px;
                width: 100%;
                @include notoSans(13px, bold, 1.38);
                // color: #ffffff;
    
            }
    
        }
    }
}