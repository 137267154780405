@import '../../RootVariable.scss';

.loginContent {
    background-color: $white;
    // height: 3000px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: inherit;
}

.loginContent::-webkit-scrollbar {
    display: none;
}

.signupBenefits {
    // margin-top: 48px;

    .signupBenefit {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: unset;
        }

        .benefitImg {

            background-color: #999999;
            width: 56px;
            height:56px;
        }

        .benefitTexts {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            .benefitTitle {
                @include notoSans(16px, bold, 1.5);
                color:#101010;
            }

            .benefitDesc {
                @include notoSans(13px, normal, 1.38);
                color:#999999;
            }
        }
    }
}

.loginHeadText {
    color: $black;
    @include notoSans(18px, 900, 1.44);
    margin-bottom: 48px;
}
.loginSubText {
    @include notoSans(13px, normal, 1.38);
    color: $grey_6;
    margin-top: 8px;
}

.loginMethodWrapper {
    // margin-top: 48px;

    .loginMethodDesc {
        // width: 186px;
        width:fit-content;
        // max-width: 186px;
        background-color: #ebebeb;
        padding: 4px 10px;
        border-radius: 14px;

        @include notoSans(11px, 500, 1.45);
        color : #151515;
        position: relative;

        &::after {
            content:"";
            position: absolute;
            top:100%;
            left:10%;
            border-top: 4px solid #ebebeb; 
            border-left: 6px solid transparent; 
            border-right: 1px solid transparent; 
            border-bottom: 0px solid transparent; 
            
        }
    }

}

.loginMethod {
    border-radius: 24px;
    border: solid 1px $grey_5;
    padding: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 16px;
    background-color: $white;

    &.hide {
        display: none;
    }
}
.loginMethodText {
    color: $black;
    @include notoSans(14px, 500, 1.43);
}
.loginLogo {
    width: 28px;
    height: 28px;
    position: absolute;
    left: 10px;
    top: 10px;
    img {
        width: 100%;
    }
}

.naverPayContainer {
    margin-top: 40px;
    margin-bottom: 40px;
    .contentNaverPay {
        margin-bottom: 16px;
        @include notoSans(13px, normal, 1.38);
    }

    .naverPayButton {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6px;
        padding: 14px 80px;
        border-radius: 24px;
        border: solid 1px $grey_5;
        text-align: center;
        background-color: #00c73c;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 109px;
            height: 20px;
        }
    }
}
.emailInput {

    height: 0;
    overflow: hidden;
    transition: all 500ms;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    padding-bottom: 0;

    &.open {
        height: auto;
        visibility: visible;
        overflow: visible;
        opacity: 1;
        margin-top: 40px;
    }

    .inputWithValidation {
        height: 48px;
    }
}
.errMsg {
    display: block;
    color: $red;
    @include notoSans(12px, normal, 1.33);
    margin-top: 4px;
    margin-bottom: 16px;
}

.loginFailMsg {
    display: block;
    color: $red;
    @include notoSans(12px, normal, 1.33);
    // margin-top: 4px;
    margin-top: 12px;
    margin-bottom: 16px;
}

.emailLoginButton {
    height: 48px;
    border-radius: 24px;
    width: 100%;
    margin-top: 16px;
}
.signinInCheck {
    margin-top: 10px;
    .errMsg {
        display: block;
        color: $red;
        @include notoSans(12px, normal, 1.33);
        margin-top: 4px;
        margin-bottom: 10px;
    }
}
.signinInCheck:first-of-type {
    margin-top: 16px;
}

.signinButton {
    margin: 32px 0;
    width: 100%;
    background-color: $grey_3;
    transition: background-color 300ms;
    &.active {
        background-color: $black;
    }
}

.findEmailText {
    margin-top: 16px;
    width: fit-content;
    @include notoSans(13px, normal, 1.38);
    color: $grey_6;
    cursor: pointer;

    .underLine {
        text-decoration: underline;
    }
}

.loginBottomArea {
    // width: 100%;
    background-color: #f4f4f4;
    padding:24px 32px;

    margin-top: 64px;
    margin-left: -32px;
    margin-right: -32px;

    .loginBottomHeader {
        @include notoSans(14px, 900, 1.43);
        color:#101010;
    }

    .loginBottomDesc {
        margin-top: 8px;
        @include notoSans(13px, 500, 1.38);
        color:#999999;
    }

    .loginToggleBtn {
        margin-top: 16px;
        @include notoSans(13px, normal, 1.23);
        color:#101010;
        cursor: pointer;
        display: flex;
    }

}

.loginPasswordWrapper {
    transition: all 500ms;
    max-height: 0;
    overflow: visible;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    margin-top: 8px;
    &.existing {
        max-height: 100px;
        visibility: visible;
        z-index: inherit;
        opacity: 1;
        // overflow: visible;
    }
}

.singUpWrapper {
    margin-top: 48px;
}

.signinInput {
    margin-top: 8px;
}

.searchAddressBtn {
    margin-left: 8px;
    padding: 15px 19px;
    @include notoSans(14px, 500, 1.43);
    white-space: nowrap;
    border: 1px solid #ebebeb;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
    }
}

.withBtn {
    display: flex;
    align-items: center;
}

.uploadFileBtn {
    margin-left: 8px;
    padding: 15px 19px;
    @include notoSans(14px, 500, 1.43);
    white-space: nowrap;
    border: 1px solid #ebebeb;
    border-radius: 6px;

    &:hover {
        cursor: pointer;
    }

    input {
        display: none;
    }
}

.alreadyRegisteredModalBg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: map-get($map: $zIndex, $key: modal);
}

.alreadyRegisteredContent {
    background-color: $white;
    width: 312px;
    padding: 32px;
    border-radius: 8px;
}

.alreadyRegisteredMainText {
    @include notoSans(16px, bold, 1.5);
}

.alreadyRegisteredIdContainer {
    margin-top: 24px;
    width: 100%;
    background-color: $grey_2;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    img {
        margin-right: 8px;
    }
}

.alreadyRegisteredButtons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.alreadyRegisteredCancleButton {
    width: calc(50% - 2px);
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $grey_5;
    border-radius: 7px;
    margin-top: 32px;
    cursor: pointer;
    @include notoSans(13px, 500, 1.38);
}

.alreadyRegisteredLoginButton {
    width: calc(50% - 2px);
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: solid 1px $grey_5;
    background-color: $red;
    border-radius: 7px;
    margin-top: 32px;
    cursor: pointer;
    @include notoSans(13px, 500, 1.38);
    color: $white;
}

.signupPartnerNotice {
    margin-top: 32px;
    color: #999999;
    @include notoSans(13px, normal, 1.38);
}

@media (min-width: $breakPointTbl) {
    .loginContent {
        background-color: $white;
        // height: 3000px;
        // max-height: calc(100vh - 100px);
        min-height: 542px;
        max-height: 712px;
        width: 500px;
        position: relative;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
        padding-top: 40px;
        padding-left: 32px;
        padding-right: 32px;
        // padding-bottom: 210px;
        // margin-bottom: 144px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        border-radius: 20px;
    }

    .loginBottomArea {

        // bottom: 0;

        .loginBottomHeader {
            @include notoSans(14px, 900, 1.43);
            color:#101010;
        }
    
        .loginBottomDesc {
            margin-top: 8px;
            @include notoSans(13px, 500, 1.38);
            color:#999999;
        }
    
        .loginToggleBtn {
            margin-top: 16px;
            @include notoSans(13px, normal, 1.23);
            color:#101010;
            cursor: pointer;
        }
    
    }

    .signinButton {
        margin-bottom: 0;
    }
}

@media(min-width: $breakPointDt) {

    .loginContent {

        min-height: unset;
        max-height: unset;
        padding: 40px 48px 0;
    }

    .loginBottomArea {

        padding:32px 48px;
        margin-left: -48px;
        margin-right: -48px;
    
        .loginBottomHeader {
            @include notoSans(20px, 900, 1.4);
            color:#101010;
        }
    
        .loginBottomDesc {
            margin-top: 4px;
            @include notoSans(16px, 500, 1.5);
            color:#999999;
        }
    
        .loginToggleBtn {
            margin-top: 24px;
            @include notoSans(18px, normal, 1.44);
            color:#101010;
            cursor: pointer;
        }
    
    }

    .findEmailText {
        margin-top: 24px;
        width: fit-content;
        @include notoSans(14px, normal, 1.43);
        color: $grey_6;
        cursor: pointer;
    
        .underLine {
            text-decoration: underline;
        }
    }
    .loginLogo {
        width: 40px;
        height: 40px;
    }

    .loginHeadText {
        // color: $black;
        @include notoSans(24px, 900, 1.5);
    }

    .loginMethodText {
        color: $black;
        @include notoSans(16px, bold, 1.5);
    }

    .loginMethod {
        height: 64px;
        border-radius: 36px;
        // border: solid 1px $grey_5;
        // padding: 14px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: 16px;
        background-color: $white;
        
        &:first-child {
            margin-top: 10px;
        }

        &.hide {
            display: none;
        }
    }

    .naverPayContainer {
        margin-top: 40px;
        margin-bottom: 40px;
        .contentNaverPay {
            margin-bottom: 16px;
            @include notoSans(13px, normal, 1.38);
        }
    
        .naverPayButton {
            height: 64px;
            border-radius: 32px;
            img {
                width: 131px;
                height: 24px;
            }
        }
    }

    .emailLoginButton {
        height: 64px;
        border-radius: 36px;
        width: 100%;
        margin-top: 16px;
    }

    .loginMethodWrapper {
        // margin-top: 48px;
    
        .loginMethodDesc {
            // width: 220px;
            background-color: #ebebeb;
            padding: 6px 12px 4px;
            border-radius: 14px;
    
            @include notoSans(13px, 500, 1.38);
            color : #151515;
            position: relative;
    
            &::after {
                content:"";
                position: absolute;
                top:100%;
                left:10%;
                border-top: 4px solid #ebebeb; 
                border-left: 6px solid transparent; 
                border-right: 1px solid transparent; 
                border-bottom: 0px solid transparent; 
                
            }
        }
    
    }
    .emailInput {
    
        .inputWithValidation {
            height: 64px;

            padding:20px 24px;
            @include notoSans(16px, normal, 1.5);
        }
    }

    .loginSubText {
        @include notoSans(16px, 500, 1.5);
        color: $grey_6;
        margin-top: 8px;
    }
}
