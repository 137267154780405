@import '../../RootVariable.scss';

.HamburgerMenu {
    width: calc(100vw - 24px);
    height: 100vh;
    position: fixed;
    left: 24px;
    top: 0;
    right: 0;
    background-color: #fff;
    transform: translateX(100%);
    z-index: map-get($map: $zIndex, $key: hamburger);

    &.slideIn {
        animation: slideIn 0.5s forwards;
    }

    .navAndAccountInfo {
        padding: 13px 24px 24px;
        background-color: $black;

        .navMenu {
            margin-bottom: 21px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                &:hover {
                    cursor: pointer;
                }
            }

            .iconWithGap {
                margin: 0 8px;
            }

            .rightSide {
                display: flex;
                align-items: center;
            }

            .cartWithItems {
                width: 24px;
                height: 24px;
                margin: 0 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: $black;
                @include montSerrat(11px, bold, 1.27);
                background-color: #fff;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .userAccountSection {
            .userAccountTitle {
                display: inline-block;
                margin-bottom: 8px;
                color: #fff;
                @include notoSans(18px, bold, 1.44);
                position: relative;

                &:hover {
                    cursor: pointer;
                }

                &.nonLogin {
                    display: flex;
                    align-items: center;

                    div {
                        padding-right: 8px;

                        &:last-child {
                            padding-left: 8px;
                        }
                    }

                    span {
                        width: 1px;
                        height: 13px;
                        background-color: #444;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:after {
                    width: 20px;
                    height: 20px;
                    display: block;
                    content: '';
                    position: absolute;
                    top: 2px;
                    right: -20px;
                    background: url('/assets/m-icon-link-arrow-16-black-copy-2.svg')
                        no-repeat;
                }
            }

            .userAccountDesc {
                color: #666666;
                @include notoSans(13px, normal, 1.38);

                span {
                    &:hover {
                        cursor: pointer;
                    }
                }

                .nonLogin {
                    color: #ffe700;
                    @include notoSans(13px, normal, 1.38);
                    display: flex;
                    align-items: center;

                    &:after {
                        content: '';
                        display: block;
                        width: 16px;
                        height: 16px;
                        background: url('../../assets/yellow-arrow-btn.svg')
                            no-repeat center;
                    }
                }
            }

            .dividerLine {
                display: inline-block;
                width: 1px;
                height: 10px;
                margin: 0 8px;
                background-color: #666666;
            }
        }
    }

    .whiteBgArea {
        height: calc(100vh - 148px);
        display: flex;
        flex-direction: column;
        align-self: stretch;
        justify-content: space-between;
        overflow-y: scroll !important;
        -webkit-overflow-scrolling: touch;
    }

    .collectionMenuList {
        padding: 0 24px;

        .eventBanner {
            width: 100%;
            margin: 32px 0;
            padding: 15px 16px;
            background-color: #00c2b9;
            color: #fff;
            @include notoSans(13px, 500, 1.38);
            border: none;
            border-radius: 16px;
            position: relative;
            text-align: left;
            box-shadow: 0 5px 10px 0 rgba(0, 194, 185, 0.2);

            &:hover {
                cursor: pointer;
            }

            &:after {
                width: 16px;
                height: 16px;
                content: '';
                display: block;
                position: absolute;
                top: 15px;
                right: 16px;
                background: url('/assets/m-icon-link-arrow-10-white.svg')
                    no-repeat;
            }
        }

        .collectionMenuItem {
            margin-bottom: 40px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px 0;

            .menuNameWrapper {
                display: flex;
                align-items: center;
                position: relative;

                .dot {
                    width: 4px;
                    height: 4px;
                    // margin-right: 6px;
                    border-radius: 50%;
                    background-color: $black;
                    position: absolute;
                    left: -10px;
                }

                .menuName {
                    color: $black;
                    @include notoSans(16px, bold, 1.5);

                    &:hover {
                        cursor: pointer;
                    }
                }

                .bacon {
                    margin-left: 4px;
                    color: $red;
                    @include notoSans(11px, bold, 1.27);
                }

                .showSubCollectionsBtn {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .subCollectionListArea {
            // width: 200%;
            padding: 20px;
            background-color: #f7f7f7;
            border-radius: 15px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px 0;
            color: #999999;
            @include notoSans(13px, 500, 1.38);
            position: relative;
            grid-column-start: 1;
            grid-column-end: 3;

            &:after {
                position: absolute;
                content: '';
                top: -7px;
                left: 50%;
                border-top: 0px solid transparent;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                border-bottom: 8px solid #f7f7f7;
            }

            .subCollectionItem {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .bestOrNewItems {
            button {
                margin-right: 8px;
                padding: 12px 12px 8px;
                color: $black;
                background-color: #f0f0f0;
                @include montSerrat(12px, 900, 1.33);
                font-style: italic;
                border: none;
                border-radius: 16px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .socialIcons {
        margin: 40px 0 80px;
        padding: 0 24px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            text-decoration: none;
            &:visited {
                color: $black;
            }
        }

        img {
            &:hover {
                cursor: pointer;
            }
        }

        .leftSide {
            display: flex;
            align-items: center;
            img {
                margin-right: 8px;
            }
        }

        .rightSide {
            display: flex;
            align-items: center;
            color: $black;
            @include notoSans(16px, bold, 1.5);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@keyframes slideIn {
    to {
        transform: translateX(0);
    }
}
