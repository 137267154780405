@import '../../RootVariable.scss';
.BBThemeContainerWrapper {
    width: 100%;
    // overflow-x: hidden;

    .navSliderContentText {
        @include montSerrat(14px, bold, 1.43);
        color: $grey_4;

        &.targetEl {
            color: $black;
        }
    }

    .sliderProgressPosition {
        display: none;
    }
    .sliderProgressPosition {
        margin-top: 16px;
    }
}

.BBThemeContainerTitle {
    @include notoSans(24px, 900, 1.33);
}

.monthlyThemeCard {
    &:hover,
    &:focus {
        cursor: pointer;
    }
}

.monthlyThemeImg {
    width: 100%;
}

.themeMonth {
    @include montSerrat(14px, 500, 1.29);
    margin-top: 8px;
}

.themeTitle {
    @include notoSans(14px, bold, 1.29);
    margin-top: 4px;
}
.themeMinHeight {
    // min-height: 400px;
    margin-top: 20px;
}

@media (min-width: $breakPointTbl) {
    .BBThemeContainerWrapper {
        .sliderProgressPosition {
            display: flex;
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 90% !important;
            transform: translateY(0);
        }
    }

    .themeMinHeight {
    }
}

@media (min-width: $breakPointDt) {
    .BBThemeContainerWrapper {
        .navSliderContentText {
            @include montSerrat(14px, bold, 1.43);

            &.targetEl {
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            top: 30% !important;
            transform: translateY(-50%);

            &:after {
                margin-top: 48px;
            }
        }

        .navSliderContentText {
            @include montSerrat(18px, bold, 1.43);
            color: $grey_4;

            &.targetEl {
                color: $black;
            }
        }
        .sliderProgressPosition {
            margin-top: 30px;
        }
    }

    .BBThemeContainerTitle {
        @include notoSans(40px, 900, normal);
    }

    .themeMonth {
        @include montSerrat(16px, bold, 1.33);
    }

    .themeTitle {
        @include notoSans(16px, bold, 1.33);
    }
    .themeMinHeight {
        // min-height: 400px;
        margin-top: 32px;
    }
}

@media (min-width: $breakPointDt) {
    .BBThemeContainerWrapper {
        .swiper-button-next,
        .swiper-button-prev {
            // top: 30% !important;

            &:after {
                margin-top: 55px;
            }
        }
    }
}
