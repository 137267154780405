@import '../../RootVariable.scss';

.holiday-delivery-modal-wrapper {
  .dimmer {
    background-color: rgba(0, 0, 0, 0.6);;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 60;
    top: 0;
    left: 0;
  }

  .holiday-delivery-modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 334px;
    z-index: 61;


    .holiday-delivery-modal-img {
      width: 100%;
      display: flex;
    }

    .holiday-delivery-confirm-button-wrapper {
      margin: auto;
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      height: 48px;


      .holiday-delivery-confirm-button {
        width: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        background-color: #010101;
        color: #FFFFFF;
        @include notoSans(14px, bold, 1.57);
        height: 48px;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: $breakPointTbl) {
  .holiday-delivery-modal-wrapper {
    .holiday-delivery-modal {
      width: 446px;

      .holiday-delivery-confirm-button-wrapper {
        height: 48px;

        .holiday-delivery-confirm-button {
          @include notoSans(16px, bold, 1.57);
        }
      }
    }
  }
}