@import '../../RootVariable.scss';

.BBSantaPresentList {
    width: 100vw;
    height: calc(100vh - 130px);
    padding: 40px 24px 80px;
    overflow-y: scroll;

    .title {
        img {
            width: 24px;
            height: 24px;
        }
        margin-bottom: 40px;
        @include notoSans(18px, 900, 1.44);
    }

    .presentsList {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px 12px;

        .presentItem {
            width: 100%;

            &.selected {
                img {
                    border: 2px solid $red;
                }
            }

            .imgWrapper {
                width: 100%;
                position: relative;
                border-radius: 6px;
                margin-bottom: 8px;

                .soldoutDim {
                    width: 100%;
                    // height: 100%;
                    height: calc(100% - 1px);
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    color: #ffffff;
                    @include montSerrat(14px, 900, 1.29);
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            img {
                width: 100%;

                border-radius: 6px;

                &:hover {
                    cursor: pointer;
                }
            }

            .productName {
                @include notoSans(12px, 500, 1.42);
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .BBSantaPresentList {
        width: 588px;
        padding: 56px 84px 138px;

        .title {
            margin-bottom: 56px;
        }

        .presentsList {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media (min-width: $breakPointDt) {
    .BBSantaPresentList {
        width: 760px;
        padding: 72px 102px 56px;

        .title {
            img {
                width: 36px;
                height: 36px;
            }
            @include notoSans(24px, bold, 1.5);
        }

        .presentsList {
            grid-gap: 24px 20px;

            .presentItem {
                .productName {
                    @include notoSans(15px, 500, 1.33);
                }
            }
        }
    }
}
