@import '../../RootVariable.scss';

.EnrollDogInfo {
    padding: 48px 32px 40px;

    .enrollDogInfoTitle {
        margin-bottom: 4px;
        @include notoSans(26px, 900, 1.38);
    }

    .enrollDogInfoSubTitle {
        color: $grey_4;
        @include notoSans(13px, 500, 1.38);
    }

    .baconboxInfos {
        margin-top: 24px;
        padding: 20px 16px;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

        .describeBox {
            display: flex;
            margin-bottom: 20px;

            img {
                width: 64px;
                height: 64px;
                margin-right: 8px;
                border-radius: 8px;
            }

            .info {
                flex-grow: 1;
                .productName {
                    margin-bottom: 6px;
                    @include notoSans(13px, bold, 1.38);
                }

                .optionName {
                    margin-bottom: 8px;
                    margin-left: 0;
                    color: $red;
                    @include notoSans(12px, normal, 1.33);
                }

                .enrollDogInfoBtn {
                    @include notoSans(13px, normal, 1.23);
                    text-align: right;
                    position: relative;
                    right: 10px;

                    &:hover {
                        cursor: pointer;
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 2px;
                        right: -10px;
                        background: url('/assets/m-icon-link-arrow-10-black.svg')
                            no-repeat;
                    }
                }
            }
        }
    }

    hr {
        border-style: dashed;
        border-color: $grey_5;
        border-width: 1px;
    }

    .detailBox {
        margin-top: 20px;
        color: $grey_6;
        @include notoSans(12px, normal, 1.33);
    }
}

@media (min-width: $breakPointTbl) {
    .EnrollDogInfo {
        padding-left: 120px;
        padding-right: 120px;

        .enrollDogInfoTitle,
        .enrollDogInfoSubTitle {
            text-align: center;
        }
    }
}

@media (min-width: $breakPointDt) {
    .EnrollDogInfo {
        padding: 88px 0 72px;

        .enrollDogInfoTitle {
            margin-bottom: 16px;
            @include notoSans(44px, 900, 1.36);
        }

        .enrollDogInfoSubTitle {
            @include notoSans(16px, 500, 1.5);
        }

        .baconboxInfos {
            max-width: 588px;
            margin: 56px auto 0;
            padding: 32px;

            .describeBox {
                img {
                    width: 96px;
                    height: 96px;
                    margin-right: 16px;
                }

                .info {
                    .productName {
                        margin-bottom: 8px;
                        @include notoSans(16px, bold, 1.5);
                    }

                    .optionName {
                        margin-bottom: 26px;
                        @include notoSans(14px, normal, 1.43);
                    }

                    .enrollDogInfoBtn {
                        @include notoSans(14px, normal, 1.43);
                        right: 16px;

                        &:after {
                            width: 16px;
                            height: 16px;
                            right: -16px;
                            background: url('/assets/m-icon-link-arrow-16-black.svg')
                                no-repeat;
                        }
                    }
                }
            }
        }

        .detailBox {
            margin-top: 24px;
            @include notoSans(14px, normal, 1.43);
        }
    }
}
