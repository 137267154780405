@import '../../RootVariable.scss';
.CollectionPageWrapper {
    // margin-top: 120px;
    // overflow-x: hidden;
    // max-width: 100vw;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    padding-top: 24px;
}

.moreDataBtn {
    margin: 28px 32px 80px;
    padding: 12px 70px;
    border: 1px solid #ebebeb;
    border-radius: 20px;
    color: #999999;
    @include notoSans(13px, normal, 1.38);
    text-align: center;
    transition: all 0.3s ease-in;

    &:hover {
        // padding: 12px 78px;
        border-color: $black;
        color: $black;
        cursor: pointer;
    }
}

.collectionPageDtPositioning {
    padding-bottom: 56px;
}

@media (min-width: $breakPointTbl) {
    .CollectionPageWrapper {
        padding-left: $TblContentPadding;
        padding-right: $TblContentPadding;
    }

    .moreDataBtn {
        width: 344px;
        margin: 28px auto 98px;

        &:hover {
            width: 360px;
        }
    }

    .collectionPageDtPositioning {
        padding-bottom: 88px;
    }
}

@media (min-width: $breakPointDt) {
    .CollectionPageWrapper {
        padding-top: 48px;
        max-width: 1328px;
        width: 100%;
        margin-left: $TblContentPadding;
        margin-right: $TblContentPadding;
    }
    .collectionPageDtPositioning {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 180px;
    }

    .moreDataBtn {
        width: 380px;
        margin: 80px auto 0;
        padding: 18px 70px;
        border-radius: 36px;
        @include notoSans(15px, normal, 1.47);

        &:hover {
            width: 400px;
        }
    }
}
