@import '../../RootVariable.scss';

.galleryMain {
    width: 100%;
    height: 100%;
    position: relative;

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 0px;
        margin-bottom: 24px;
    }

    .swiper-lazy-preloader-bacon {
        --swiper-theme-color: #001ed3;
    }
}

.swiper-main-container {
    // height: 500px;
    width: 100%;
    height: 100%;
}

.pagination-main-detail-images {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 9;
}

.swiper-pagination-bullet {
    // background-color: transparent;
    // border: solid 1px $black;
    background-color: rgba(16, 16, 16, 0.15);
}

.swiper-pagination-bullet-active {
    background-color: $black;
}

.imgSliderContent {
    background-size: contain;
    &.cover {
        background-size: cover;
    }
}

.imageThumbnail.swiper-pagination-bullet-active {
    // background-color: transparent;
    // border: solid 1px $black;
}

.pagination-dt {
    display: none;
}
.galleryThumbs {
    margin-top: 16px;
    display: none;
}
.galleryThumbnail {
    width: 78px;
    height: 78px;
    //border-radius: 50%;
    background-position: center center;
    //background-size: 140%;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.5;
    cursor: pointer;
    overflow: hidden;
    border-radius: 10px;
}

.swiper-slide-thumb-active {
    .galleryThumbnail {
        opacity: 1;
    }
}

@media (min-width: $breakPointDt) {
    .galleryThumbs {
        display: block;
    }
    .swiper-main-container {
        height: 690px;
        width: 690px;
        border-radius: 20px;
    }

    .imageThumbnail {
        width: auto;
        height: auto;
        border-radius: 0;
        background-color: #efebe2;
        border: none;
        opacity: 1;
    }

    .imageThumbnail.swiper-pagination-bullet-active {
        background-color: transparent;
        // border: solid 1px $black;
    }

    .pagination-dt {
        display: block;
    }

    .imgSliderContent {
        background-size: min(100%, 720px);
        &.cover {
            background-size: cover;
        }
    }

    .hoverCursorPrev {
        position: absolute;
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 5;
    }

    .hoverCursorNext {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
        z-index: 5;
    }

    .pagination-main-detail-images {
        display: none;
    }
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
}

.swiper-button-next-product,
.swiper-button-prev-product {
    width: 40px;
    height: 40px;
    top: 43%;
    position: absolute;
    cursor: pointer;
    display: none;
}

.swiper-button-prev-product {
    left: -48px;
    background-image: url('../../assets/p-btn-arrow-prev-40.svg');
    display: none;
}

.swiper-button-next-product {
    right: -48px;
    background-image: url('../../assets/p-btn-arrow-next-40.svg');
}

@media (min-width: $breakPointDt) {
    .swiper-button-next-product,
    .swiper-button-prev-product {
        display: block;
    }
}
