@import '../../RootVariable.scss';

.PartnerPage {
    width: 100vw;
    // margin-top: 100px;
    padding-bottom: 80px;

    .dt{
        display: none;
    }

    .headerSection {
        width: 100%;
        margin-bottom: 56px;
        padding-bottom: 61%;
        background: url('../../assets/partnerPageBg-mb@2x.jpg') no-repeat center;
        background-size: 100%;
        position: relative;

        h1 {
            position: absolute;
            @include notoSans(26px, 900, 1.38);
            left: 32px;
            top: 18.1%;
            color: $white;
        }
    }

    .contentsSection {
        padding: 0 32px;

        .contentItem {
            h3 {
                margin-bottom: 16px;
                @include notoSans(18px, 900, 1.44);
            }

            p {
                @include notoSans(14px, normal, 1.43);
            }

            div {
                display: flex;
                align-items: baseline;

                img {
                    margin-right: 6px;
                }
            }
        }

        hr {
            margin: 40px 0;
        }

        .partnerButton {
            width: 100%;
            margin-top: 24px;
            padding: 14px 35px;
            color: $white;
            @include notoSans(14px, bold, 1.43);
            background-color: $black;
            border: none;
            border-radius: 24px;
            box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .PartnerPage {
        .dt{
            display: block;
        }
        .headerSection {
            padding-bottom: 30%;
            background-image: url('../../assets/partnerPageBg-pc@2x.jpg');

            h1 {
                left: 64px;
                top: 24.3%;
            }
        }

        .contentsSection {
            padding: 0 64px;
            .partnerButton {
                width: 280px;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .PartnerPage {
        padding-bottom: 180px;
        .dt{
            display: block;
        }
        .headerSection {
            margin-bottom: 104px;

            h1 {
                width: 1328px;
                padding: 0 64px;
                @include notoSans(44px, 900, 1.36);
                left: 0;
                right: 0;
                top: 28.8%;
                margin: 0 auto;
            }
        }

        .contentsSection {
            max-width: 1328px;
            margin: 0 auto;

            .contentItem {
                h3 {
                    margin-bottom: 24px;
                    @include notoSans(26px, 900, 1.46);
                }

                p {
                    @include notoSans(16px, 500, 1.5);
                }

                div {
                    img {
                        margin-right: 9px;
                    }
                }
            }

            hr {
                margin: 80px 0;
            }

            .partnerButton {
                margin-top: 40px;
                padding: 20px 44px;
                @include notoSans(16px, bold, 1.5);
                border-radius: 36px;
            }
        }
    }
}
