@import '../../RootVariable.scss';

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.pathArea {
    margin-bottom: 32px;
    display: flex;
    align-items: center;


    .pathName {
        // font-family: NotoSansCJKkr;
        @include notoSans(300, 12px, 12px);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: $main-brown;
        margin-right: 4px;
    }

    .pathArrow {
        width: 8px;
        height: 8px;
        margin-right: 4px;
    }


}

.upsell-wrapper {

    display: flex;
    flex-direction: column;
    margin-top: 40px;

    .upsell-title {

        @include notoSans(13px, 400, 13px);
        color: $black;
        margin-bottom: 16px;
    }
}


.thunder {
    margin-right: 4px;

    &.dt {
        display: none;
    }

    &.mo {
        display: inline;
    }

}

.npay-button {

    display: flex;
    justify-content: center;
}

.npay-button-dt .npay_storebtn_bx {

    margin-top: 16px !important;
}

.npay-button-cart .npay_storebtn_bx {

    margin-bottom: 32px !important;
}

.purchaseMenu-wrapper {
    //padding: 40px 48px;
    // padding-right:  0;
    padding-bottom: 0;
    overflow: visible;
    // animation: fade-in 500ms;
    animation-fill-mode: forwards;

    .npay_button_box {
        animation: fade-in 500ms;
        animation-fill-mode: forwards;

    }

    .purchase-content {
        padding-left: 32px;
        padding-right: 32px;
        //margin-top: 40px;

        .collectionEvent {
            display: none;
            // margin-top: 103px;
            cursor: pointer;
        }
    }

    @media(max-width: $breakPointDt) {
        .purchase-content {
            margin-top: 40px;
        }


    }

    .purchaseMenu-category {
        @include notoSans(300, 13px, 13px);
        margin-bottom: 30vh;
        color: $black;
        letter-spacing: 0;

    }

    .purchaseMenu-mainItem {
        position: relative;
        z-index: 2;
    }

    .purchaseMenu-name {
        @include notoSans(100, 13px, 13px);
        color: $black;
        letter-spacing: 0;
        line-height: 16px;

        margin: 0;
        padding: 0;
        margin-bottom: 4px;
    }

    .purchaseMenu-mainText {
        @include notoSans(24px, 900, 34px);
        // margin-bottom: 18px;
        margin-bottom: 8px;
        color: $black;
    }

    .priceDesc {
        @include notoSans(12px, normal, 1.33);
        color:#ed2e1b;
    }

    .price-before {
        @include montSerrat(14px, 500, 18px);
        text-decoration: line-through;
        color: $disable-color;

    }

    .outofstock {
        margin-top: 8px;
        @include notoSans(12px, 500, 1.33);
        color: $red;

    }

    .additional-info {
        a {
            all: unset;
        }

        color: $black;
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        margin-bottom: 40px;


        .review-info {
            display: flex;
            align-items: center;

            .count {
                margin-left: 4px;
                @include montSerrat(14px, 700, 20px);
            }

            .text {
                @include notoSans(12px, 500, 1.33);
            }
        }

        .benefit-info {
            display: flex;
            align-items: center;

            .info-item {
                color: $thickGrey;
                @include notoSans(13px, 500, 14px);
                background-color: $grey_8;
                border-radius: 11px;
                padding: 4px 6px;
                margin-left: 4px;

            }
        }

        //margin-left: 16px;
        //position: absolute;
        //right: 0;

    }

    .purchaseMenu-price {
        display: flex;
        //margin-bottom: 24px;
        position: relative;
        align-items: baseline;

        .price-no-sale {
            @include montSerrat(18px, 700, 22px);
            color: $black;

            &.big-red {
                @include montSerrat(30px, 900, 1.2);
                color:#ed2e1b;
            }
        }

        .won {
            @include notoSans(14px, 700, 20px);
            color: $black;
            // margin-right: 8px;
            margin-right: 4px;

            &.red {
                color:#ed2e1b;
            }
        }


        .price-after,
        .discount-rate {
            @include montSerrat(18px, 900, 22px);
            color: $red;
        }



    }

    .buyNow-text {
        @include notoSans(300, 12px, 12px);
        color: $main_green_deep;
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .purchaseMenu-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .purchaseMenu-button {
            width: 50%;
        }

        & .purchaseMenu-button:first-of-type {
            margin-right: 8px;
        }
    }





}

.purchaseMenu-featured-info {
    display: flex;
    flex-direction: column;
}

.purchaseMenu-draggable {
    position: relative;
    z-index: 2;
    margin-top: 48px;
}

.purchaseMenu-list {
    display: flex;
}


.backWordArrow {
    margin-left: 16px;
}

.cartIconWidthNumberMo {
    margin-right: 10px;
}


@media(min-width: $breakPointDt) {

    .pathArea {
        margin-bottom: 32px;
        display: flex;
        align-items: center;


        .pathName {
            // font-family: NotoSansCJKkr;
            @include notoSans(300, 13px, 13px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $main-brown;
            margin-right: 4px;
            cursor: pointer;
        }

        .pathArrow {
            width: 8px;
            height: 8px;
            margin-right: 4px;
        }


    }


    .thunder {

        &.mo {
            display: none;
        }

        &.dt {
            display: inline;
        }

    }

    .purchaseMenu-wrapper {
        padding: 0;
        // max-width: 1024px;
        // div를 하나 더씌우고 아래 것들은 그아래에 넣어야 한다. 
        display: flex;
        // justify-content: center;
        flex-direction: column;
        // align-items: center;
        // min-height: 720px;
        position: relative;
        width: 100%;
        //margin-top: 88px;
        //left: 20%;
        //transform: translate(-50%, -50%);

        // &.fixed {
        //     position : sticky;
        //     //left: 60%;
        //     top: 180px;
        // }

        .purchaseMenu-mainItem {
            width: 40%;
        }

        .purchase-content {
            padding-left: 32px;
            padding-right: 32px;
            //margin-top: 32px;

            .collectionEvent {
                cursor: pointer;
                display: block;
                margin-top: 103px;
            }
        }


        .size-control-wrapper {
            padding: 0 48px;
            padding-top: 3%;
            width: 100%;
            max-width: $breakPointDt;
        }

        .purchaseMenu-category {
            @include notoSans(18px, 300, 18px);
            margin-bottom: 10vh;
        }

        .purchaseMenu-name {
            @include notoSans(14px, 300, 14px);
            margin-bottom: 0;
        }

        .purchaseMenu-mainText {
            @include notoSans(26px, 900, 38px);
            margin-bottom: 18px;
            color: $black;

        }

        .priceDesc {
            display: none;
        } 

        .additional-info {

            .review-info {
                display: flex;
                align-items: center;

                .count {
                    margin-left: 4px;
                    @include montSerrat(14px, 700, 20px);
                }

                .text {
                    @include notoSans(14px, 500, 1.43);
                }
            }
        }

        .purchaseMenu-price {
            //margin-bottom: 48px;

            .price-no-sale {
                @include montSerrat(20px, 700, 24px);
                color: $black;

            }

            .won {
                @include notoSans(18px, 700, 24px);
                color: $black;
                margin-right: 8px;
            }


            .price-after,
            .discount-rate {
                @include montSerrat(20px, 900, 24px);
                color: $red;
            }

        }

        .outofstock {
            @include notoSans(14px, 500, 1.43);

        }

        .buyNow-text {
            @include notoSans(300, 13px, 13px);
            color: $main_green_deep;
            margin-top: 24px;
            margin-bottom: 30px;
        }

        .purchaseMenu-buttons {}

        .price-before {
            @include montSerrat(16px, 500, 1.13);
            // text-decoration:line-through;
            // color: $disable-color;

        }
    }

    .purchaseMenu-draggable {
        margin-top: 10%;

    }

    .desktop-button-wrapper {
        width: 100%;
        display: flex;
        // margin-bottom: 24px;

        .desktop-button:first-of-type {
            margin-right: 8px;


        }

        .desktop-button {

            width: 50%;

        }

    }





    @media(min-height: 900px) {
        .purchaseMenu-price {
            // margin-bottom: 13%;
        }

        .purchaseMenu-draggable {
            margin-top: 11%;


        }

    }

}