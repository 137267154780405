@import '../../RootVariable.scss';

.ChangeBBOptionPopup {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 50px);
    position: fixed;
    bottom: 0;
    background-color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: overDimBg);

    &.santa {
        padding-bottom: 100px;
    }

    .closeOptionPopupBtn {
        position: absolute;
        top: -30px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .BBOptionSelectorWrapper {
        padding-bottom: 88px;
        height: auto;
        max-height: calc(100vh - 50px);
        // padding: 32px;
        // padding-bottom: 127px;
        overflow: scroll;
        position: static;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .changeOptionBtnContainer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 7500;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);

        .changeOptionBtn {
            width: calc(100% - 64px);
            margin: 16px 32px 24px;
            padding: 14px 71px;
            display: block;
            color: $white;
            background-color: $black;
            border-radius: 24px;
            @include notoSans(14px, bold, 1.43);
            text-align: center;
        }
    }
}

@media (min-width: $breakPointTbl) {
    .ChangeBBOptionPopup {
        width: 588px;
        height: 100vh;
        max-height: 100vh;
        // max-height: calc(100vh - 200px);
        right: 0;
        // transform: translate(-50%, -50%);
        border-radius: 0;
        animation: slide 0.3s;

        .closeOptionPopupBtn {
            top: 12px;
            right: 12px;
        }

        .changeOptionBtnContainer {
            width: 588px;
            // height: unset;
            position: fixed;
            left: unset;
            right: 0;
            bottom: 0;
            background-color: #fff;
            z-index: 7500;
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
            animation: slide 0.3s;

            .changeOptionBtn {
                width: calc(100% - 64px);
                margin: 16px 32px 24px;
                padding: 14px 71px;
                display: block;
                color: $white;
                background-color: $black;
                border-radius: 24px;
                @include notoSans(14px, bold, 1.43);
                text-align: center;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .ChangeBBOptionPopup {
        &.santa {
            width: 760px;

            .changeOptionBtnContainer {
                width: 760px;
            }
        }
        .changeOptionBtnContainer {
            .changeOptionBtn {
                height: 56px;
                @include notoSans(16px, bold, 1.5);
                border-radius: 36px;
            }
        }
    }
}

@keyframes slide {
    0% {
        right: -588px;
    }

    100% {
        right: 0;
    }
}
