@import '../../RootVariable.scss';

.Footer {
    padding: 48px 32px 53px;
    border-top: 1px solid #eeeeee;
    background-color: $white;

    a {
        color: #999999;
        text-decoration: none;
        &:visited {
            color: #999999;
        }
    }

    .categoryWrapper {
        margin-bottom: 17px;
        display: flex;
        color: $black;
        @include notoSans(18px, 900, 1.44);

        &.dt {
            display: none;
        }

        .baconboxCategory,
        .allProductCategory {
            &:hover {
                cursor: pointer;
            }
        }

        .baconboxCategory {
            margin-right: 24px;
        }

        .allProductCategory {
            display: flex;
            align-items: center;
        }
    }

    .categoryBox {
        margin-bottom: 16px;
        padding: 20px;
        background-color: #f7f7f7;
        border-radius: 15px;
        @include notoSans(13px, 500, 1.38);
        color: #999999;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px 0;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &:after {
            position: absolute;
            content: '';
            top: -7px;
            left: 50%;
            border-top: 0px solid transparent;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 8px solid #f7f7f7;
        }
    }

    .companyMenus {
        margin-bottom: 52px;
        color: #999999;
        @include notoSans(13px, normal, 1.38);

        a {
            &:hover {
                cursor: pointer;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        span {
            display: inline-block;
            &:hover {
                cursor: pointer;
            }

            &.first {
                padding-left: 0;
            }

            &.last {
                border-right: none;
            }
        }
    }

    .dividerWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
    }

    .dividerLine {
        display: inline-block;
        width: 1px;
        height: 10px;
        background-color: #dddddd;
    }

    .companyInfo {
        margin-bottom: 56px;

        .baconLogo {
            margin-bottom: 8px;
        }

        .companyName {
            @include notoSans(16px, 900, 1.5);
        }

        .infoContent {
            margin: 10px 0;
            color: #999999;
            @include notoSans(13px, normal, 1.54);

            a {
                color: #999999;
            }
        }

        .companyPolicy {
            margin-bottom: 16px;
            color: #999999;
            @include notoSans(13px, normal, 1.54);

            span {
                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .copyright {
            @include notoSans(13px, 500, 1.54);
        }
    }

    .socialIconsAndBtns {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .socialBtns {
            display: flex;
            img {
                margin-right: 8px;
            }
        }

        a {
            &:hover {
                cursor: pointer;
            }
        }

        .accountBtns {
            .loginBtn {
                margin-right: 8px;
            }

            .loginBtn,
            .signUpBtn {
                padding: 8px 14px;
                background-color: #f0f0f0;
                border-radius: 17px;
                border: none;
                @include notoSans(13px, bold, 1.38);

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .Footer {
        padding: 80px 64px;

        .categoryWrapper {
            &.mb {
                display: none;
            }

            &.dt {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 16px 0;

                .categoryItem {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .categoryBox {
            display: none;
        }

        .companyMenus {
            margin: 32px 0 88px;
        }

        .companyInfo {
            margin-bottom: 0;
        }

        .bottomLayout {
            display: flex;
            justify-content: space-between;

            .socialIconsAndBtns {
                align-items: flex-end;
            }

            .companyInfo,
            .socialIconsAndBtns {
                flex-grow: 1;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .Footer {
        padding: 80px 0;

        .upperLayout,
        .bottomLayout {
            max-width: 1328px;
            padding: 0 64px;
            margin: 0 auto;
        }

        .upperLayout {
            display: flex;
            margin-bottom: 88px;
            align-items: flex-start;
            // justify-content: space-between;

            .categoryWrapper.dt,
            .companyMenus {
                flex-grow: 1;
            }

            .companyMenus {
                margin: 0;
                text-align: right;
            }
        }

        .bottomLayout {
            .socialIconsAndBtns {
                flex-grow: 0.25;
            }
        }
    }
}
