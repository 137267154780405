@import '../../RootVariable.scss';

.inputWidthValidation {
    width: 100%;
}

.errMsg {
    // @include notoSans(200, 12px, 12px);
    // color: $main_point;
}

.readOnly:hover {
    outline: none;
    // border-bottom: 1px solid $main_line !important;
    cursor: pointer;
}

.readOnly:focus {
    outline: none;
    // border-bottom: 1px solid $main_line !important;
}

.relpositionMaker {
    position: relative;
    flex-grow: 1;
}

.inputDeleteContetnsButton {
    opacity: 0;
}

input:focus + .inputDeleteContetnsButton {
    opacity: 1;
}

.button {
}

.inputDeleteContetnsButton {
    position: absolute;
    right: 0;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
