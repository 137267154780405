@import '../../RootVariable.scss';

.orderItemsContainer {
    width: 100vw;
    padding: 40px 0 48px;
}

.itemPaddingWrapper {
    padding-left: 16px;
    padding-right: 16px;
}

.orderItemsTopArea {
    display: flex;
    justify-content: space-between;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    align-items: center;

    .orderItemsCount {
        @include notoSans(13px, bold, unset);
        color: $grey_6;
    }
}

.orderItemsTopText {
    @include notoSans(18px, 900, 1.44);
}

.orderItemsBodyArea {
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
}

.orderItemsThumbnail {
    cursor: pointer;
    width: 64px;
    height: 64px;
    object-fit: contain;
    object-position: center;
    background-color: $grey_3;
    border-radius: 4.5px;
}

.orderItemBox {
    margin-top: 16px;
    background-color: $white;
    border-radius: 8px;
    @include defaultShadow;
    // padding: 20px 16px;
    position: relative;
}

.orderItemHeadArea {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    border-bottom: 1px dashed $grey_5;
}

.border-line {
    height: 1px;
    border: dashed 1px #ebebeb;

    &.padding {
        margin-right: 16px;
        margin-left: 16px;
        // padding-right: 16px;
        // padding-left: 16px;
    }
}

.orderItemMiddleArea {
    padding-top: 20px;
    padding-bottom: 20px;
    // padding: 20px 16px;
    display: flex;
    flex-direction: column;

    .childItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0px;
        }
    }

    // justify-content: space-between;
    .itemDetail {
        @include notoSans(12px, normal, 1.33);
        color: $grey_6;
        margin-right: 24px;
    }
}

.orderItemInfoTexts {
    width: 100%;
    margin-left: 8px;
    @include notoSans(12px, normal, 1.33);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.baconbox {
        margin-left: 0;
    }

    .itemDesc {
        margin-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: unset;

        .line {
            width: 1px;
            height: 10px;
            background-color: #dddddd;
        }

        &.bbItem {
            display: flex;
            justify-content: space-between;
        }

        .plan {
            color: $red;
        }
    }

    .bb-btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 8px;

        .btn {
            cursor: pointer;
            @include notoSans(13px, normal, 1.23);
            color: $grey_6;
        }

        .pathArrow {
            width: 12px;
            height: 12px;
        }
    }

    .showTotalPrice {
        margin-top: 10px;
    }
}

.itemName {
    margin-bottom: 6px;
    @include notoSans(13px, bold, 1.38);
}

.orderItemBodyArea {
    padding: 20px 16px;
    border-bottom: 1px solid $grey_5;

    .bundleItem {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0px;
        }
        .itemList {
            &.bundle {
            }

            .itemInfoAndPrice {
                width: 100%;
                display: flex;
                justify-content: space-between;
            }
            .optionThumbnail {
                width: 40px;
                height: 40px;
            }

            .optionInfo {
                display: flex;
            }
        }
    }
}

.displayPrice {
    text-align: right;
    color: $black;
    @include montSerrat(13px, bold, 1.38);
}

.regularPrice {
    text-align: right;
    color: $grey_4;
    @include montSerrat(12px, 500, 1.25);
    text-decoration: line-through;
}

.showTotalPrice {
    white-space: nowrap;

    .displayPrice {
        @include montSerrat(14px, bold, 1.29);
    }

    .regularPrice {
        @include montSerrat(12px, 500, 1.25);
    }
}

.bundleItemBottomArea,
.orderItemBottomArea {
    padding: 22px 16px 23px 16px;
    display: flex;
    // justify-content: flex-end !important;
    justify-content: space-between !important;
    border-top: 1px solid #ebebeb;

    .btns {
        .btn {
            cursor: pointer;
            width: 98px;
            height: 28px;
            border-radius: 16px;
            background-color: #f4f4f4;
            padding: 6px 12px;

            color: $grey_6;
            @include notoSans(12px, bold, 1.33);
        }
    }

    &.bbItem {
        // margin-top: 20px;
        padding-top: 20px !important;
        justify-content: space-between !important;
    }
}

//일반상품
.orderItemBox.withOption,
.orderItemBox.withoutOption,
.orderItemBox.soldout,
.orderItemBox.subItems {
    .itemDesc {
        color: $grey_6;
        @include notoSans(12px, normal, 1.33);
        display: flex;
        // flex-direction: column;

        .optionName {
            margin-left: 0;

            &.baconbox {
                color: $red;
            }
        }

        .line {
            width: 1px;
            height: 10px;

            margin-left: 8px;
            margin-right: 8px;
            background-color: #dddddd;
        }

        .qty {
            // margin-left:
            @include notoSans(12px, normal, 1.33);
            color: $grey_6;
        }

        .deliveryInfo {
            color: $blue;
            font-weight: bold;
            margin-left: auto;
        }
    }

    .itemDesc.soldout {
        .optionName {
            margin-bottom: 4px;
        }

        .soldoutNotice {
            color: $red;
        }
    }

    .orderItemHeadArea {
        border-bottom: none;
    }

    .orderItemBottomArea {
        padding-top: 0;
        justify-content: space-between;
        align-items: center;

        .controlBox {
            display: flex;
        }

        .changeQty {
            margin-right: 8px;
        }
    }
}

.orderItemBox.subItems {
    .orderItemHeadArea {
        display: flex;
        flex-direction: column;
        border-bottom: 1px dashed $grey_5;

        .orderItemInfo {
            display: flex;
            margin-bottom: 25px;

            &.baconbox {
                margin-bottom: 0;
            }
        }

        .orderItemQtyAndPrice {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .orderItemBodyArea {
        .itemList {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;

            &.bundle {
                margin-bottom: 4px;
                margin-top: 13px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            .optionInfo {
                display: flex;
                flex-grow: 1;

                .optionThumbnail {
                    width: 40px;
                    height: 40px;
                }
            }

            .itemName {
                margin-bottom: 0;
            }

            .itemOptionName {
                width: 65%;
                color: $grey_6;
                @include notoSans(12px, normal, 1.33);
            }
        }
    }

    .optionThumbnail {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }

    .itemDesc {
        display: flex;
        flex-direction: column;

        .optionName {
            margin-bottom: 8px;
        }

        .deliveryInfo {
            text-align: rignt;
        }
    }

    .orderItemBottomArea {
        padding-top: 22px;
    }
}

.freeItemArea {
    .freeItemTop {
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-bottom: 16px;

        .freeItemIcon {
            width: 24px;
            height: 24px;
            margin-right: 6px;
        }

        .freeItemTitle {
            @include notoSans(16px, 900, 1.63);
            color: #101010;
        }
    }

    .orderItemInfoTexts {
        justify-content: unset;
    }
}

@media (min-width: $breakPointTbl) {
    .orderItemsContainer {
        width: 100%;
        padding: 0;

        .orderItemsTopArea {
            padding: 0;

            .orderItemsTopText {
                padding: 16px 0;
            }

            .orderItemsCount {
                @include notoSans(13px, bold, unset);
                color: $grey_6;
            }
        }

        .orderItemsBodyArea {
            padding: 0;
        }
    }

    .itemPaddingWrapper {
        padding-left: 16px;
        padding-right: 16px;
    }

    .border-line {
        // width: 100%;
        height: 1px;
        // margin: 0 32px;
        border: dashed 1px #ebebeb;

        &.padding {
            margin-right: 16px;
            margin-left: 16px;
            // padding-right: 16px;
            // padding-left: 16px;
        }
    }

    .orderItemMiddleArea {
        padding-top: 16px;
        padding-bottom: 16px;
        // padding: 16px 32px;
        display: flex;
        flex-direction: column;

        .childItem {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        // justify-content: space-between;
        .itemDetail {
            @include notoSans(12px, normal, 1.33);
            color: $grey_6;
            margin-right: 24px;
        }
    }

    .orderItemBox {
        border: 1px solid $grey_5;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
    }

    .subscribtionItemWrapper,
    .normalItemWrapper,
    .freeItemWrapper {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    .orderItemBox.withOption .itemDesc .optionName,
    .orderItemBox.withoutOption .itemDesc .optionName,
    .orderItemBox.subItems .itemDesc .optionName,
    .orderItemBox.withOption .itemDesc .deliveryInfo,
    .orderItemBox.withoutOption .itemDesc .deliveryInfo,
    .orderItemBox.subItems .itemDesc .deliveryInfo {
        @include notoSans(12px, normal, 1.33);
    }

    .freeItemArea {
        .freeItemTop {
            display: flex;
            align-items: center;
            margin-top: 32px;
            margin-bottom: 16px;

            .freeItemIcon {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }

            .freeItemTitle {
                @include notoSans(16px, 900, 1.63);
                color: #101010;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .orderItemsContainer {
        // width: 690px;
        padding: 0;

        .orderItemsTopArea {
            padding: 0;

            .orderItemsTopText {
                padding: 24px 0;
                @include notoSans(26px, bold, 1.46);
            }

            .orderItemsCount {
                @include notoSans(14px, bold, 1.43);
                color: $grey_6;
            }
        }

        .orderItemMiddleArea {
            .itemDetail {
                @include notoSans(14px, normal, 1.43);
            }
        }

        .orderItemsBodyArea {
            padding: 0;

            .orderItemsSummaryBox {
                max-height: 128px;
                padding: 24px 32px;
                border-radius: 16px;

                &:hover {
                    cursor: pointer;
                }

                .orderItemsThumbnail {
                    width: 80px;
                    height: 80px;
                    border-radius: 6px;
                }

                .orderItemsSummaryText {
                    margin-left: 16px;
                    @include notoSans(18px, bold, 1.44);
                }
            }
        }

        .orderItemBox.withOption,
        .orderItemBox.withoutOption,
        .orderItemBox.soldout,
        .orderItemBox.subItems {
            .itemDesc {
                .qty {
                    @include notoSans(14px, normal, 1.43);
                    color: $grey_6;
                }
            }
        }

        .itemDesc {
            .qty {
                @include notoSans(14px, normal, 1.43);
                color: $grey_6;
            }
        }

        .orderItemBodyArea {
            .itemList {
                &.bundle {
                    .optionThumbnail {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
        }
    }

    .orderItemBox {
        margin-top: 24px;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
    }

    .border-line {
        &.padding {
            margin-right: 34px;
            margin-left: 34px;
        }
    }

    .itemPaddingWrapper {
        padding-left: 32px;
        padding-right: 32px;
    }

    .orderItemHeadArea {
        padding-top: 32px;
        // padding-bottom: 16px;
        padding-bottom: 32px;
    }

    .orderItemsThumbnail {
        width: 96px;
        height: 96px;
        border-radius: 6px;
    }

    .orderItemInfoTexts {
        margin-left: 16px;
    }

    .itemName {
        margin-bottom: 8px;
        @include notoSans(16px, bold, 1.5);
    }

    .itemType {
        @include notoSans(14px, normal, 1.43);
    }

    .orderItemBodyArea {
        padding: 25px 32px;
    }

    .optionProductName {
        width: 356px;
        @include notoSans(14px, normal, 1.43);
    }

    .displayPrice {
        @include montSerrat(20px, bold, 1.2);
    }

    .regularPrice {
        @include montSerrat(16px, 500, 1.13);
    }

    .optionPrice {
        .displayPrice {
            @include montSerrat(16px, bold, 1.13);
        }

        .regularPrice {
            @include montSerrat(14px, 500, 1.13);
        }
    }

    .showTotalPrice {
        .displayPrice {
            @include montSerrat(20px, bold, 1.2);
        }

        .regularPrice {
            @include montSerrat(16px, 500, 1.13);
        }
    }

    .bundleItemBottomArea,
    .orderItemBottomArea {
        padding: 25px 32px 39px;

        .btns {
            .btn {
                width: 118px;
                height: 32px;
                border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                @include notoSans(14px, bold, 1.43);
            }
        }
    }

    .changeOptionBtn {
        width: 87px;
        height: 32px;
        padding: 6px 16px;
        border-radius: 20px;
        text-align: center;
        @include notoSans(14px, bold, 1.43);
    }

    .subscribtionItemWrapper,
    .normalItemWrapper,
    .freeItemWrapper {
        margin-bottom: 57px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    .orderItemBox.withOption .itemDesc .optionName,
    .orderItemBox.withoutOption .itemDesc .optionName,
    .orderItemBox.soldout .itemDesc .optionName,
    .orderItemBox.subItems .itemDesc .optionName {
        @include notoSans(14px, normal, 1.43);
        // margin-bottom: 8px;
    }

    .orderItemBox.withOption .itemDesc .deliveryInfo,
    .orderItemBox.withoutOption .itemDesc .deliveryInfo,
    .orderItemBox.soldout .itemDesc .deliveryInfo,
    .orderItemBox.subItems .itemDesc .deliveryInfo {
        @include notoSans(14px, bold, 1.43);
    }

    .orderItemBox.withOption .orderItemBottomArea .changeQty,
    .orderItemBox.withoutOption .orderItemBottomArea .changeQty,
    .orderItemBox.soldout .orderItemBottomArea .changeQty,
    .orderItemBox.subItems .orderItemBottomArea .changeQty {
        margin-right: 20px;
    }

    .orderItemBox.withOption .itemDesc.soldout .soldoutNotice,
    .orderItemBox.withoutOption .itemDesc.soldout .soldoutNotice,
    .orderItemBox.soldout .itemDesc.soldout .soldoutNotice,
    .orderItemBox.subItems .itemDesc.soldout .soldoutNotice {
        @include notoSans(14px, normal, 1.43);
    }

    .orderItemBox.subItems {
        .orderItemBodyArea {
            padding: 15px 32px;
        }

        .optionThumbnail {
            width: 56px;
            height: 56px;
        }

        .orderItemInfoTexts {
            margin-left: 12px;

            &.baconbox {
                margin-left: 0;

                .itemOptionName {
                    @include notoSans(14px, normal, 1.43);
                }
            }

            .pathArrow {
                width: 16px;
                height: 16px;
            }
        }
    }

    .orderItemsBottomArea {
        padding: 0;
        margin-bottom: 56px;

        .clearorderItems,
        .cartContainerShrinkButton {
            @include notoSans(14px, normal, 1.43);
        }
    }

    .freeItemWrapper {
        .orderItemHeadArea {
            border-bottom: none;
        }
    }

    .freeItemArea {
        .freeItemTop {
            display: flex;
            align-items: center;
            margin-top: 57px;
            margin-bottom: 21px;

            .freeItemIcon {
                width: 28px;
                height: 28px;
                margin-right: 8px;
            }

            .freeItemTitle {
                @include notoSans(18px, bold, 1.44);
                color: #101010;
            }
        }
    }
}
