$mobileContentPadding: 32px;
$TblContentPadding: 64px;
$desktopContentPadding: 40px;

$GNBHeightMo : 57px;
$GNBSubCollectionHeightMo : 50px;
$GNBHeightDt : 85px;
$GNBSubCollectionHeightDt : 68px;

$defaultButtonBackgroundColor: red;
$notoSans: 'Noto Sans KR', ' sans-serif';
$montserrat: 'Montserrat', 'sans-serif';
$black: #101010;
$grey_1: #f7f7f7;
$grey_2: #f4f4f4;
$grey_3: #dddddd;
$grey_4: #bbbbbb;
$grey_5: #ebebeb;
$grey_6: #999999;
$grey_7: #e8e8e8;
$thickGrey: #666666;
$grey_8: #efefef;
$blue: #3f8af4;
$blue_bacon: #001ed3;

$white: #ffffff;
$red: #ed2e1b;
$disable-color: #aaaaaa;
$dim_bg: rgba(96, 68, 72, 0.5);
$main_bg: #fffff5;
$main_line: #efebe2;
$main_etc: #ecd7ae;
$main_brown: #c0a691;
$main_black_2: #654a4e;
$main_green_deep: #3a5244;
$main_white: #ffffff;
$main_button: #d5ff00;
$main_point: #ff5316;
$main_point_light: #ff642e;
$main_point_light_transparent: #ff5e26;
$main_point_pale: #b96b4f;
$main_placeholder: #cbb6a3;
$main_real_black: #000000;
$main_green: #e0ff43;
$main_strong_brown: #a7856a;
$main_bg_thick: #f7f7ed;
$main_bg_yellow: #f2efe5;
$main_bg_grey: #e8e4dd;

$menu_back: rgba(239, 235, 226, 0.5);
$product_back: rgba(239, 235, 226, 0.2); //  rgb(251,251, 242)
$product_back_rgb: rgb(251, 251, 242);
$cart_back: rgba(239, 235, 226, 0.3);
$account_back: rgba(239, 235, 226, 0.15);
$account_bg: rgb(250, 249, 240);

@mixin flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

$zIndex: (
    homeSliderText: 2,
    onSliderCard: 2,
    rankText: 2,
    overFlowImg: 3,
    header: 11,
    modal: 19,
    coveringContent: 20,
    ctaBtn: 25,
    search: 30,
    searchHeader: 31,
    dimBg: 40,
    overDimBg: 41,
    miniCartPopup: 50,
    CartAddCompletePopup: 51,
    productMiniCartBtnArea: 52,
    hamburger: 60,
    loginModal: 70,
    commonPopup: 100,
    agreements: 1000,
    overlay: 8000,
    dropdown: 7000,
    spinner: 5000,
);

$breakPointDt: 1200px;
$breakPointTbl: 768px;
$transitionDefault: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

@mixin notoSans($fontSize, $weight, $lineHeight) {
    font-family: $notoSans;
    font-weight: $weight;
    font-size: $fontSize;
    line-height: $lineHeight;
    // color: $black;
}

@mixin montSerrat($fontSize, $weight, $lineHeight) {
    font-family: $montserrat;
    font-weight: $weight;
    font-size: $fontSize;
    line-height: $lineHeight;
}

@mixin defaultShadow {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
}

@mixin ellipsis($fontSize, $lineHeight, $lineToShow) {
    display: -webkit-box;
    // 글씨크기 * lineHeight * 보여질 줄 수
    height: $fontSize * $lineHeight * $lineToShow; /* Fallback for non-webkit */
    // margin-left: 16px;
    font-size: $fontSize;
    line-height: $lineHeight;
    -webkit-line-clamp: $lineToShow;
    -webkit-box-orient: vertical;
    // max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin setSliderNavigationSizeAndPosition(
    $size,
    $positionFromTop: 50%,
    $display: block
) {
    .swiper-button-next,
    .swiper-button-prev {
        width: $size !important;
        height: $size !important;
        top: $positionFromTop !important;
        position: absolute;
        visibility: visible !important;
        // display: block;
    }
    .swiper-button-next::after {
        width: $size;
        height: $size;
        display: $display;
    }
    .swiper-button-next:hover::after {
        width: $size;
        height: $size;
        display: $display;
    }

    .swiper-button-prev::after {
        width: $size;
        height: $size;
        display: $display;
    }

    .swiper-button-prev:hover::after {
        width: $size;
        height: $size;
        display: $display;
    }

    .swiper-button-prev.swiper-button-disabled::after {
        width: $size;
        height: $size;
        display: $display;
    }
    .swiper-button-next.swiper-button-disabled::after {
        width: $size;
        height: $size;
        display: $display;
    }
}
