@import '../../RootVariable.scss';
.purchaseBtns {
    display: none;
}
.CartPurchaseBox {
    width: 100%;
    height: 124px;
    padding: 16px 32px;
    position: fixed;
    bottom: 0;
    background-color: $white;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: onSliderCard);
    // overflow: hidden;
    // transition: height 1s;
    transition: height 500ms;

    .closePurchaseDetailBtn {
        position: absolute;
        top: -30px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .estimateBtnTbl {
        display: none;
    }

    .purchaseInfo {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include notoSans(14px, bold, 1.43);

        .purchaseDetail {
            display: flex;
            align-items: center;
            margin-right: 4px;

            &:hover {
                cursor: pointer;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .estimateBtn {
                margin-right: 7px;
                color: #999999;
                @include notoSans(13px, normal, normal);
                text-decoration: underline;

                &.opened {
                    display: none;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .purchasePrice {
            font-family: 'Montserrat';
        }
    }

    .deliveryInfoBox {
        width: 230px;
        padding: 20px 16px;
        position: absolute;
        top: 22px;
        left: -8%;
        border: 1px solid $black;
        border-radius: 6px;
        background-color: $white;
        color: $grey_6;
        @include notoSans(12px, normal, 1.33);
        @include defaultShadow;
        z-index: 1;

        &:before,
        &:after {
            bottom: 100%;
            left: 22%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 5px;
            margin-left: -5px;
        }

        &:before {
            border-color: rgba(16, 16, 16, 0);
            border-bottom-color: #101010;
            border-width: 6px;
            margin-left: -6px;
        }

        &.noShowing {
            display: none;
        }
    }

    .purchaseDetailWrapper {
        display: none;
        // opacity: 0;
    }

    .purchaseDetailWrapper.openDetail {
        display: block;
        // animation: slideFromBottom 1s;
        // opacity: 1;
    }

    .purchaseBtnBox {
        width: 100%;
        display: flex;
        align-items: center;

        .naverPayBtn,
        .goToPurchaseBtn {
            &:hover {
                cursor: pointer;
            }
        }

        .naverPayBtn {
            width: auto;
            padding: 14px 19px;
            margin-right: 8px;
            border-radius: 24px;
            background-color: $black;
            color: $white;

            &.noShow {
                display: none;
            }

            img {
                width: 51px;
                height: 20px;
            }
        }

        .goToPurchaseBtn {
            width: auto;
            padding: 15px 41px;
            text-align: center;
            color: $white;
            @include notoSans(14px, 500, 1.43);
            background-color: $red;
            border-radius: 24px;
            flex-grow: 2;
            white-space: nowrap;
        }
    }
}

.CartPurchaseBox.showing {
    height: auto;
    padding: 24px 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: map-get($map: $zIndex, $key: overDimBg);

    .closePurchaseDetailBtn {
        display: block;
        position: absolute;
        top: -30px;
        right: 12px;
    }

    .purchaseDetailTitle {
        padding: 0 32px;
        // margin-top: 8px;
        margin-bottom: 20px;
        @include notoSans(18px, 900, 1.44);

        &:hover {
            cursor: pointer;
        }
    }

    .detailType {
        @include notoSans(13px, 500, 1.38);
        position: relative;
    }

    .price {
        @include montSerrat(13px, bold, 1.38);
    }

    .priceInfo,
    .deliveryInfo,
    .discountInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .deliveryInfo {
        .detailType {
            display: flex;
            align-items: center;
            position: relative;
        }
        img {
            margin-left: 4px;
            &:hover {
                cursor: pointer;
            }
        }
    }

    .purchaseDetailDesc {
        padding: 20px 32px;
    }

    .estimateBtn {
        width: 100%;
        margin: 16px 0;
        padding: 12px 80px;
        border: 1px solid #ebebeb;
        border-radius: 20px;
        color: #999999;
        @include notoSans(13px, normal, 1.38);
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }

    .discountDetailDesc {
        padding: 20px 32px;
        margin-bottom: 16px;
        background-color: $grey_1;

        .price {
            color: $red;
        }
    }

    .purchaseInfo,
    .purchaseBtnBox {
        padding: 0 32px;
    }

    .purchaseBtns {
        display: none;
    }
}

.CartPurchaseBox.fullPage {
    z-index: map-get($map: $zIndex, $key: overDimBg);

    .purchaseBtnBox {
        .naverPayBtn {
            display: none;
        }
    }
}

@keyframes slideFromBottom {
    0% {
        bottom: -100%;
    }

    100% {
        bottom: 0;
    }
}

@media (min-width: $breakPointTbl) {
    .CartPurchaseBoxContainer {
        width: 45.66%;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 0;
        align-self: flex-start;

        &.fullPage {
            position: static;
        }
    }

    .CartPurchaseBox {
        height: auto;
        margin-top: 83px;
        // margin-left: 102px;
        margin-bottom: 16px;
        padding: 20px 0;
        position: sticky;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

        .deliveryInfoBox {
            top: 20px;
            left: -67px;

            &:after,
            &:before {
                left: 50%;
            }
        }

        .estimateBtnTbl {
            display: block;
            margin: 16px 16px 0;
            padding: 12px 70px;

            border: 1px solid #ebebeb;
            color: #999999;
            border-radius: 20px;
            text-align: center;
            @include notoSans(13px, normal, 1.38);
            transition: all 0.3s ease-in;

            &:hover {
                border-color: $black;
                color: $black;
                cursor: pointer;
            }

            &.noShow {
                display: none;
            }
        }

        .purchaseDetailTitle {
            padding: 0 16px;
            margin: 0;
            margin-bottom: 10px;
            @include notoSans(18px, 900, 1.44);

            img {
                display: none;
            }
        }

        .detailType {
            @include notoSans(13px, 500, 1.38);
            position: relative;
        }

        .price {
            @include montSerrat(13px, bold, 1.38);
        }

        .priceInfo,
        .deliveryInfo,
        .discountInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .estimateBtn {
            display: none;
        }

        .deliveryInfo {
            img {
                margin-left: 4px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .purchaseDetailDesc,
        .discountDetailDesc {
            padding: 20px 16px;
        }

        // .purchaseDetailDesc {
        //     padding: 20px 32px;
        // }

        .discountDetailDesc {
            // padding: 20px 32px;
            // margin-bottom: 26px;
            background-color: $grey_1;

            .price {
                color: $red;
            }
        }

        .purchaseInfo {
            padding: 20px 16px 0;
            margin-bottom: 0;

            .purchaseDetail {
                @include notoSans(14px, bold, 1.43);

                &:hover {
                    cursor: auto;
                }
            }

            .purchasePrice {
                @include montSerrat(14px, bold, 1.43);
            }
        }

        .purchaseDetailWrapper {
            display: block;
        }

        .purchaseBtnBox {
            display: none;
        }
    }

    .purchaseBtns {
        display: block;
        // margin-left: 102px;
        // width: 408px;

        .goToPurchaseBtn,
        .naverPayBtn {
            div {
                width: 109px;
                height: 20px;
                background-image: url('../../assets/common-logo-npay@2x.png');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            &:hover {
                cursor: pointer;
            }
        }

        .goToPurchaseBtn {
            width: 100%;
            padding: 14px 41px;
            margin-bottom: 9px;
            text-align: center;
            color: $white;
            @include notoSans(14px, bold, 1.43);
            background-color: $red;
            border-radius: 24px;
        }

        .naverPayBtn {
            width: 100%;
            padding: 14px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $grey_5;
            border-radius: 24px;

            img {
                width: 109px;
                height: 20px;
            }
        }
    }

    //orderandcheckout 페이지가 오픈되어 풀페이지가 되었을때의 레이아웃
    .CartPurchaseBox.fullPage,
    .CartPurchaseBox.fullPage.showing {
        width: 588px;
        height: 124px;
        padding: 0 32px;
        margin-top: 0;
        margin-bottom: 0;
        position: fixed;
        right: -588px;
        bottom: 0;
        border: none;
        border-radius: 0;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
        animation: slide 0.3s forwards;

        .bottomArea {
            height: 124px;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .purchaseInfo {
            // padding: 0;
            padding: 0 32px;

            .purchaseDetail {
                padding: 16px 0;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .purchaseDetailWrapper {
            display: none;
        }

        .purchaseBtnBox {
            display: block;
            // padding: 0;
            padding: 0 32px;
        }
    }

    .CartPurchaseBox.fullPage.showing {
        height: 100vh;
        padding: 0;
        display: flex;
        flex-direction: column;

        .purchaseDetailTitle {
            margin-top: 40px;
            display: flex;
            align-items: center;

            img {
                display: block;
            }
        }

        .purchaseDetailWrapper {
            padding: 0;
            display: block;
            visibility: visible;
            position: relative;
            bottom: 0;
            // animation: slideFromBottom 1s alternate;
        }

        .purchaseInfo,
        .purchaseBtnBox {
            position: relative;
            bottom: 0;
            padding: 0 32px;
        }

        .purchaseInfo {
            margin-top: auto;
        }

        .purchaseBtnBox {
            margin-bottom: 24px;
            .naverPayBtn {
                display: none;
            }
        }
    }

    .purchaseBtns.fullPage {
        display: none;
    }
}

@keyframes slide {
    0% {
        right: -588px;
    }

    100% {      
        right: 0;
    }
}

@media (min-width: $breakPointDt) {
    .CartPurchaseBoxContainer {
        display: flex;
        flex-direction: column;
    }

    .CartPurchaseBox {
        height: auto;
        // margin-top: 238px;
        margin-top: 172px;
        margin-left: 102px;
        margin-bottom: 16px;
        padding: 32px 0;
        position: sticky;
        width: 408px;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);

        .deliveryInfoBox {
            top: 22px;
            left: -60px;
        }

        .estimateBtnTbl {
            display: block;
            margin: 25px 32px 0;
            padding: 18px 80px;
            border-radius: 36px;
            @include notoSans(15px, normal, 1.47);
        }

        .purchaseDetailTitle {
            padding: 0 32px;
            margin-bottom: 20px;
            margin: 0;
            @include notoSans(26px, bold, 1.46);

            img {
                display: none;
            }
        }

        .detailType {
            @include notoSans(16px, normal, 1.5);
            position: relative;
        }

        .price {
            @include montSerrat(16px, bold, 1.13);
        }

        .priceInfo,
        .deliveryInfo,
        .discountInfo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .deliveryInfo {
            img {
                margin-left: 4px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .purchaseDetailDesc,
        .discountDetailDesc {
            padding: 24px 32px;
        }

        .purchaseDetailDesc {
            padding: 20px 32px;
        }

        .discountDetailDesc {
            padding: 20px 32px;
            margin-bottom: 26px;
            background-color: $grey_1;

            .price {
                color: $red;
            }
        }

        .purchaseInfo {
            padding: 0 32px;
        }

        .purchaseInfo {
            margin-bottom: 0;

            .purchaseDetail {
                @include notoSans(16px, bold, 1.5);

                &:hover {
                    cursor: auto;
                }

                img {
                    display: none;
                }
            }

            .purchasePrice {
                @include montSerrat(20px, bold, 1.2);
            }
        }

        .purchaseDetailWrapper {
            display: block;
        }

        .purchaseBtnBox {
            display: none;
        }
    }

    .purchaseBtns {
        display: block;
        margin-left: 102px;
        width: 408px;

        .goToPurchaseBtn,
        .naverPayBtn {
            div {
                width: 131px;
                height: 24px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .goToPurchaseBtn {
            width: 100%;
            padding: 23px 41px;
            margin-bottom: 16px;
            text-align: center;
            color: $white;
            @include notoSans(16px, 500, 1.13);
            background-color: $red;
            border-radius: 36px;
        }

        .naverPayBtn {
            width: 100%;
            padding: 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $grey_5;
            border-radius: 36px;

            img {
                width: 131px;
                height: 24px;
            }
        }
    }

    //orderandcheckout 페이지가 오픈되어 풀페이지가 되었을때의 레이아웃
    .CartPurchaseBox.fullPage,
    .CartPurchaseBox.fullPage.showing {
        width: 588px;
        height: 155px;
        padding: 0 48px;
        margin-bottom: 0;
        position: fixed;
        right: 0;
        bottom: 0;
        border: none;
        border-radius: 0;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);

        .bottomArea {
            height: 155px;
        }

        .purchaseInfo {
            padding: 0 48px;

            .purchaseDetail {
                padding: 18px 0;
            }
        }

        .purchaseDetailWrapper {
            display: none;
        }

        .purchaseBtnBox {
            display: block;
            padding: 0 48px;

            .goToPurchaseBtn {
                height: 64px;
                padding: 23px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }
        }
    }

    .CartPurchaseBox.fullPage.showing {
        height: 100vh;
        padding: 0;
        display: flex;
        flex-direction: column;

        .purchaseDetailTitle {
            padding: 0 48px;
            margin-top: 40px;
            margin-bottom: 16px;
            margin-right: 4px;
            display: flex;
            align-items: center;
            @include notoSans(24px, bold, 1.5);

            img {
                display: block;
                width: 20px;
                height: 20px;
            }
        }

        .detailType {
            @include notoSans(16px, 500, 1.5);
            position: relative;
        }

        .price {
            @include montSerrat(16px, bold, 1.13);
        }

        .purchaseDetailDesc {
            padding: 32px 48px;
        }

        .discountDetailDesc {
            padding: 32px 48px;
        }

        .purchaseDetailWrapper {
            padding: 0;
            display: block;
            visibility: visible;
        }

        .purchaseInfo,
        .purchaseBtnBox {
            position: relative;
            bottom: 0;
            padding: 0 48px;
        }

        .purchaseInfo {
            margin-top: auto;
        }

        .purchaseBtnBox {
            margin-bottom: 32px;
            .naverPayBtn {
                display: none;
            }
        }
    }

    .purchaseBtns.fullPage {
        display: none;
    }
}
