@import '../../../RootVariable.scss';
.SingleProductSliderContentBox {
    .productCardWrapper {
        align-items: center;
    }
}

.SingleProductSecondImageWrapper {
    display: none;
}

@media (min-width: $breakPointTbl) {
    .SingleProductSecondImageWrapper {
        display: block;
        width: 55%;
        // position: absolute;
        right: 0;
        margin-bottom: 50px;
        // 임시 컬러
        background-color: $grey_1;
        border-radius: 30px;
        // height: 366px;
        height: 0;
        padding-top: 55%;
        position: relative;
    }
    .SingleProductSecondImg {
        border-radius: 30px;
        // min-height: 600px;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .SingleProductSliderContentBox {
        display: flex;
        // width: 100%;
        justify-content: flex-end;
        position: relative;
        // background-color: $white;
    }

    .SingleProductSliderContentBox {
        .productCardWrapper {
            width: 48%;
            position: absolute;
            top: 5%;
            // transform: translateY(-43%);
            left: 0;
            border-radius: 10px;
            // background-color: white;
            // align-items: flex-start;
            align-items: flex-start;
            z-index: 5;
            .productCardThumbNailArea {
                @include defaultShadow;
            }

            .productCardTitle {
                @include notoSans(14px, bold, 1.2);
                margin-top: 16px;
            }
            .productCardMainPrice {
                // margin-top: 16px;
                @include montSerrat(14px, bold, 1.27);
            }

            .productCardDiscountRate {
                // margin-top: 16px;
                @include montSerrat(14px, bold, 1.27);
                color: $red;
                margin-left: 8px;
            }
            .productCardRegularPrice {
                margin-top: 2px;
                @include montSerrat(12px, 500, normal);
                color: $disable-color;
            }

            .productCardBadge {
                margin-top: 20px;
                padding: 4px 6px;
                background-color: $grey_2;
                border-radius: 4px;
                @include notoSans(13px, normal, 1.08);
                width: fit-content;
                color: $thickGrey;
            }
            .productCardCartIcon {
                position: absolute;
                width: 40px;
                height: 40px;
                bottom: 24px;
                right: 24px;
                cursor: pointer;
            }
        }
    }
    @include setSliderNavigationSizeAndPosition(32px, 40%);
}

@media (min-width: $breakPointDt) {
    .SingleProductSecondImageWrapper {
        display: block;
        width: 55%;
        // position: absolute;
        right: 0;
        margin-bottom: 100px;
    }
    .SingleProductSecondImg {
        width: 100%;
    }

    .SingleProductSliderContentBox {
        display: flex;
        // width: 100%;
        justify-content: flex-end;
        position: relative;
        // background-color: $white;
    }

    .SingleProductSliderContentBox {
        .productCardWrapper {
            width: 48%;
            position: absolute;
            top: 5%;
            // transform: translateY(-43%);
            left: 0;
            border-radius: 10px;
            // background-color: white;
            // align-items: flex-start;
            align-items: flex-start;
            z-index: 5;
            .productCardThumbNailArea {
                @include defaultShadow;
            }

            .productCardTitle {
                @include notoSans(24px, bold, 1.5);
                margin-top: 16px;
            }
            .productCardMainPrice {
                margin-top: 16px;
                @include montSerrat(24px, bold, 1.17);
            }

            .productCardDiscountRate {
                margin-top: 16px;
                @include montSerrat(24px, bold, 1.17);
                color: $red;
                margin-left: 8px;
            }
            .productCardRegularPrice {
                margin-top: 2px;
                @include montSerrat(18px, 500, normal);
                color: $disable-color;
            }

            .productCardBadge {
                margin-top: 20px;
                padding: 4px 6px;
                background-color: $grey_2;
                border-radius: 4px;
                @include notoSans(13px, normal, 1.08);
                width: fit-content;
                color: $thickGrey;
            }
            .productCardCartIcon {
                position: absolute;
                width: 40px;
                height: 40px;
                bottom: 24px;
                right: 24px;
                cursor: pointer;
            }
        }
    }
}
