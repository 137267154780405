@import '../../RootVariable.scss';

.ServiceSummaryMainText {
    @include notoSans(24px, 900, 1.42);
    margin-top: 20px;
}
.ServiceSummaryImage {
    width: 100%;
}

.ServiceSummarySubText {
    margin-top: 6px;
    @include notoSans(14px, normal, 1.43);
}

.ServiceSummaryButton {
    // margin: 20px 0 0 0;

    margin-top: 20px;
}

.ServiceSummaryCardBox {
    @include defaultShadow;
}

@media (min-width: $breakPointTbl) {
    .ServiceSummaryWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ServiceSummaryCardBox {
        align-items: center;
        justify-content: center;
        width: 100vw;
        // height: 600px;
        border-radius: unset !important;
        box-shadow: none;
    }
    .ServiceSummaryImage {
        max-width: 1200px;
    }
    // .ServiceSummaryMainText,
    // .ServiceSummaryButton,
    // .ServiceSummarySubText {

    // }
    .ServiceSummaryBottomArea {
        align-self: flex-start;
        position: relative;
        top: -30px;
    }
    .ServiceSummaryMainText {
        // @include notoSans(40px, 900, normal);
    }
    .ServiceSummarySubText {
        margin-top: 12px;
        // @include notoSans(16px, normal, normal);
    }
}

@media (min-width: $breakPointDt) {
    .ServiceSummaryWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ServiceSummaryCardBox {
        align-items: center;
        width: 100vw;
        height: 600px;
        border-radius: unset !important;
    }
    .ServiceSummaryImage {
        max-width: 1200px;
    }
    // .ServiceSummaryMainText,
    // .ServiceSummaryButton,
    // .ServiceSummarySubText {

    // }
    .ServiceSummaryBottomArea {
        align-self: flex-start;
        position: relative;
        top: -40px;
    }
    .ServiceSummaryMainText {
        @include notoSans(40px, 900, normal);
    }
    .ServiceSummarySubText {
        margin-top: 12px;
        @include notoSans(16px, normal, normal);
    }
}
