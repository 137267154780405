@import '../../RootVariable.scss';

.PersonalInfo {
    padding: 5px 32px 80px;

    .PersonalInfoTitle {
        margin: 24px 0 40px;
        @include notoSans(26px, 900, 1.38);
    }

    .infoType {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .typeName {
            @include notoSans(18px, 900, 1.44);
        }

        .changeInfoBtn {
            padding: 6px 12px;
            border-radius: 16px;
            background-color: $grey_2;
            color: $grey_6;
            @include notoSans(12px, bold, 1.33);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .withdrawBtn,
    .modifyPassword {
        margin-top: 20px;
        color: $grey_6;
        @include notoSans(13px, normal, 1.38);
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    .modifyPassword {
        margin-bottom: 55px;
    }

    table {
        width: 100%;

        tbody {
            tr {
                height: 27px;

                th {
                    width: 80px;
                    margin-right: 8px;
                    color: $grey_4;
                    @include notoSans(13px, normal, 1.38);
                    text-align: left;
                    white-space: nowrap;
                }

                td {
                    padding-left: 8px;
                    border: 0px solid transparent;
                    background-clip: padding-box;
                    @include notoSans(13px, 500, 1.38);

                    .copyBtn {
                        color: $grey_6;
                        text-decoration: underline;
                        display: inline-block;
                        float: right;
                    }
                }
            }

            tr > td + td {
                border-left-width: 10px;
            }

            tr + tr > td {
                border-top-width: 10px;
            }
        }
    }

    .divider {
        display: none;
    }
}

@media (min-width: $breakPointTbl) {
    .PersonalInfo {
        // padding: 131px 0 96px;
        // margin-left: 282px;
        // border-left: 1px solid $grey_5;
        padding: 0 0 123px;

        .PersonalInfoTitle,
        .userInfo,
        .deliveryAddressInfo,
        .withdrawBtn,
        .modifyPassword {
            padding: 0 64px;
        }

        .PersonalInfoTitle {
            margin-top: 20px;
        }

        .userInfo {
            margin-bottom: 32px;
        }

        .deliveryAddressInfo {
            margin-top: 40px;
        }

        .divider {
            width: 100%;
            height: 10px;
            display: block;
            background-color: $grey_1;
        }
    }
}

@media (min-width: $breakPointDt) {
    .PersonalInfo {
        // width: 892px;
        padding: 0 0 204px;
        // margin-left: 0;
        // margin: 0 auto;
        // border-right: 1px solid $grey_5;

        .PersonalInfoTitle,
        .userInfo,
        .deliveryAddressInfo,
        .withdrawBtn,
        .modifyPassword {
            padding: 0 80px;
        }

        .PersonalInfoTitle {
            margin-top: 80px;
        }

        .PersonalInfoTitle {
            margin-bottom: 56px;
            @include notoSans(40px, 900, 1.45);
        }

        .infoType {
            margin-bottom: 24px;

            .typeName {
                @include notoSans(26px, bold, 1.46);
            }

            .changeInfoBtn {
                padding: 6px 16px;
                border-radius: 20px;
                @include notoSans(14px, bold, 1.43);
            }
        }

        .modifyPassword {
            margin-bottom: 80px;
        }

        .deliveryAddressInfo {
            margin-top: 56px;
        }

        .withdrawBtn,
        .modifyPassword {
            @include notoSans(16px, normal, 1.5);
        }

        table {
            tbody {
                tr {
                    height: auto;

                    th {
                        width: 96px;
                        @include notoSans(16px, normal, 1.5);
                    }

                    td {
                        @include notoSans(16px, 500, 1.5);
                    }
                }
            }
        }

        .divider {
            display: block;
            height: 16px;
        }
    }
}
