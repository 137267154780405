@import '../../RootVariable.scss';

.selectableOption {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 8px;
    margin-top: 6px;
    border-radius: 8px;
    border: solid 1px $grey_5;
    &.selected {
        background-color: $grey_2;
        border: solid 1px $black;
    }

    .PriceAndDiscountWrapper .top {
        @include montSerrat(13px, bold, 1.22);
    }

    .PriceAndDiscountWrapper .bottom {
        @include montSerrat(12px, bold, 1.22);
        color: $grey_4;
    }
}

.stickToRight {
    display: flex;
    align-items: center;
    position: absolute;
    right: 16px;
}

.circleSelection {
    margin-left: 8px;
}

.selectableOptionThumbnail {
    width: 40px;
    height: 40px;
}
.optionName {
    margin-left: 8px;
    @include notoSans(13px, normal, 1.38);

    &.selected {
        font-weight: bold;
    }
}

@media (min-width: $breakPointDt) {
    .selectableOption {
        border-radius: 10px;
        border: solid 1px $grey_5;
        &.selected {
            background-color: $grey_2;
            border: solid 1px $black;
        }

        .PriceAndDiscountWrapper .top {
            @include montSerrat(16px, bold, 1.5);
        }

        .PriceAndDiscountWrapper .bottom {
            @include montSerrat(14px, bold, normal);
            color: $grey_4;
        }
    }

    .stickToRight {
        right: 24px;
    }

    .circleSelection {
        margin-left: 16px;
    }

    .selectableOptionThumbnail {
        width: 64px;
        height: 64px;
    }
    .optionName {
        @include notoSans(16px, normal, 1.38);
    }
}
