@import '../../RootVariable.scss';

.AgreementModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    overflow: scroll;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 500ms;

    &.AMvisible {
        visibility: visible;
        opacity: 1;
        z-index: map-get($map: $zIndex, $key: agreements);
    }
}

.agreementContents {
    background-color: $white;
    // max-height: 100vh;
    overflow: visible;
    position: relative;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transform: translateY(100%);
    transition: transform 500ms;
    &.AMvisible {
        transform: translateY(0);
    }
}
.termSelectors {
    display: flex;
    color: $grey_4;
}
.termSelector {
    cursor: pointer;
    margin-right: 16px;
    &.termActive {
        color: $black;
    }
}
.closeButton {
    position: absolute;
    top: -32px;
    right: 12px;
    cursor: pointer;
    // z-index: 50000000;
    .blackClose {
        display: none;
    }
}
.AgreementHeadArea {
    @include notoSans(18px, 900, normal);
    padding-top: 24px;
    padding-bottom: 10px;
}
.AgreementBodyArea {
    padding-top: 30px;
    overflow: scroll;
    max-height: 85vh;
    p {
        @include notoSans(13px, normal, 1.38);
        color: $grey_6;
    }
    strong {
        display: block;
        color: $black;
        margin-top: 16px;
        // margin-bottom: 8px;
    }
    padding-bottom: 32px;
}

@media (min-width: $breakPointTbl) {
    .AgreementModalWrapper {
        align-items: center;
    }
    .agreementContents {
        background-color: $white;
        // max-height: 100vh;
        overflow: visible;
        position: relative;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transform: unset;
        border-radius: 20px;
        // opacity: 0;
        width: 600px;
        height: 640px;
        transition: transform 500ms;
        &.AMvisible {
            transform: unset;
        }
    }

    .AgreementBodyArea {
        padding-top: 30px;
        overflow: scroll;
        max-height: calc(100% - 95px);
        p {
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }
        strong {
            display: block;
            color: $black;
            margin-top: 16px;
            // margin-bottom: 8px;
        }
        padding-bottom: 32px;
    }

    .closeButton {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
        // z-index: 50000000;
        .blackClose {
            display: block;
        }
        .whiteClose {
            display: none;
        }
    }
}

@media (min-width: $breakPointDt) {
    .AgreementModalWrapper {
        align-items: center;
    }
    .agreementContents {
        background-color: $white;
        // max-height: 100vh;
        overflow: visible;
        position: relative;

        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        transform: unset;
        border-radius: 20px;
        // opacity: 0;
        width: 600px;
        height: 640px;
        transition: transform 500ms;
        &.AMvisible {
            transform: unset;
        }
    }
    .AgreementHeadArea {
        @include notoSans(24px, bold, normal);
        padding-top: 40px;
        padding-bottom: 24px;
    }

    .AgreementBodyArea {
        padding-top: 24px;
        overflow: scroll;
        max-height: calc(100% - 100px);
        p {
            @include notoSans(16px, normal, 1.5);
            color: $grey_6;
        }
        strong {
            display: block;
            color: $black;
            margin-top: 24px;
            // margin-bottom: 8px;
        }
        padding-bottom: 32px;
    }
}
