@import '../../RootVariable.scss';

.cancelConfirmMethodWrapper,
.cancelSubscriptionWrapper,
.skipPaymentMethodWrapper,
.deleteAccountMethodWrapper,
.changePaymentMethodWrapper {

    .topArea {
        .title {
            @include notoSans(18px, 900, 1.44);
            color: #101010;
            display: flex;

            &.center {
                justify-content: center;
                text-align: center;
            }
        }

        .description {
            margin-top: 8px;
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;

            &.center {
                text-align: center;
            }
        }

        .paymentMethodTaps {

            margin-top: 40px;

            display: flex;
            justify-content: space-between;

            .paymentMethod {
                cursor: pointer;
                width: 100%;
                height: 56px;

                margin-right: 8px;

                box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #ebebeb;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #ffffff;


                @include notoSans(13px, bold, 1.38);

                &:last-child {
                    margin-right: unset;
                }

                &.on {
                    background-color: #000000;
                    color: #ffffff;
                    border: solid 1px #101010;
                }
            }
        }
    }

    .cancelReasons {
        margin-top: 24px;

        .btnAndReason {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .cancelReason {
            cursor: pointer;
            // @include notoSans(14px, normal, 1.43);
            display: flex;
            // align-items: center;
            // color: $grey_6;

            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0px;
            }

            .cancelReasonTexts {
                display: flex;
                flex-direction: column;
                margin-left: 6px;
                width: calc(100% - 6px);

                .cancelReasonText {
                    @include notoSans(14px, normal, 1.43);
                }

                .cancelReasonDesc {
                    margin-top: 4px;
                    @include notoSans(12px, normal, normal);
                    color: $red;
                }
            }

            &.on {
                @include notoSans(14px, bold, 1.43);
                color: #000000;

                .cancelReasonBtn {
                    background: url('/assets/mo-component-check-terms-select.png');
                    background-size: cover;
                    width: 19px;
                    height: 18px;
                    // margin-right: 6px;
                }
            }

            .cancelReasonBtn {
                background: url('/assets/mo-component-check-terms-default.png');
                background-size: cover;
                width: 19px;
                height: 18px;
                // margin-right: 6px;

            }

        }

        .cancelReasonBtn2 {
            cursor: pointer;
            border-radius: 6px;
            background-color: #ececec;
            height: 40px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @include notoSans(13px, 500, normal);
            color: #000000;
        }

    }

    .content {
        margin-top: 24px;

        display: flex;
        flex-direction: column;

        .cardTypeRadioBtns {
            display: flex;

            .cardTypeBtn {

                margin-right: 24px;
                @include notoSans(13px, bold, 1.38);
                color: #bbbbbb;

                display: flex;
                align-items: center;

                .radioBtn {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &.on {
                    color: #111111;

                    // .radioBtn {
                    //     width: 16px;
                    //     height: 16px;
                    //     background-image: url("/img/icon/activeRadio16.png");
                    // }

                }
            }
        }

        .cardInfo {
            margin-top: 24px;

            .cardNum,
            .expirationDate,
            .registrationNumber,
            .cardPwdNum {
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;

                margin-bottom: 8px;

            }

            .expirationDate {
                .selectBox {
                    height: 48px;
                    width: 100%;

                    padding-left: 16px;
                    margin-right: 8px;
                    border-radius: 6px;
                    background-color: #f7f7f7;

                    background: url('/assets/m-btn-icon-accordion-arrow-down-gray-16.svg') no-repeat 95% 50%;

                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;

                    &:last-child {
                        margin-right: 0px;
                    }

                }

                select::-ms-expand {
                    display: none;
                }
            }
        }

        .description {
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }

        .dateSelectArea {

            display: grid;
            grid-template-columns: repeat(8, 1fr);
            // grid-template-rows: repeat(4, 1fr);
            row-gap: 10px;
            column-gap: 6px;

            .col {

                text-align: center;
                cursor: pointer;
                pointer-events: none;
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1;

                @include montSerrat(18px, 600, 1.22);
                color: #bbbbbb;

                &.selectAvailable {
                    color: #101010;
                    pointer-events: unset;
                }

                &.on {
                    color: #ffffff;
                    border-radius: 50%;
                    background-color: #101010;
                    box-shadow: 0 6px 8px 0 rgba(16, 16, 16, 0.1);
                }
            }

        }

        .fullGreyArea {
            background-color: #eeeff3;

            margin-left: -32px;
            margin-right: -32px;
            padding: 24px 32px 24px;

            .fullGreyTitle {
                @include notoSans(20px, bold, 1.4);
                color: #111111;
                text-align: center;
            }

            .fullGreyDescription {
                margin-top: 6px;
                @include notoSans(13px, bold, 1.38);
                color: #666666;
                text-align: center;
            }
        }

        .cancelReasonArea {
            padding-top: 32px;
            padding-bottom: 56px;

            .cancelReasonTitle {
                @include notoSans(18px, bold, 1.44);
                margin-bottom: 10px;
            }

            .titleLine {
                height: 2px;
                width: 100%;
                background-color: #000000;
            }

        }

        .greyBlock {
            background-color: #f7f7f7;
            margin-left: -32px;
            margin-right: -32px;
            width: calc(100% + 64px);
            height: 10px;
        }

        .reasonTextArea {
            padding-top: 32px;

            .reasonTextTitle {
                @include notoSans(18px, bold, 1.44);
                color: #000000;
            }

            .reasonTextInput {
                margin-top: 11px;
                width: 100%;
                @include notoSans(14px, 500, 1.43);
                color: #000000;
                border: solid 1px #e0e0e0;
                border-top: solid 2px #000000;

            }
        }
    }

    .cancelBtns {
        display: flex;

    }

    .btn {
        margin-top: 24px;

        width: 100%;
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 6px 8px 0 rgba(237, 46, 27, 0.1);
        background-color: $red;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        margin-right: 8px;

        @include notoSans(14px, bold, 1.43);
        color: #ffffff;

        &.marginTop {
            margin-top: 40px;
        }

        &.black {
            background-color: #222222;
        }

        &:last-child {
            margin-right: 0px;
        }

    }

}


.cancelSubscriptionWrapper {

    .content {
        overflow-y: auto;
        overflow-x: visible;
        padding-right: 32px;
        padding-left: 32px;
        padding-bottom: 32px;
        
        height: calc(100vh - 73px);

        

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .cancelBtns {
        padding-bottom: 24px;
    }
}

.cancelSubscriptionWrapper,
.changePaymentMethodWrapper {
    padding-top: 24px;
    padding-bottom: 24px;
}

.skipPaymentMethodWrapper {
    padding-top: 40px;
    padding-bottom: 24px;
}

@media (min-width: $breakPointTbl) {

    .cancelSubscriptionWrapper {
        .content {
            height: calc(60vh - 73px);
        }
    }
}

@media (min-width: $breakPointDt) {

    .cancelConfirmMethodWrapper,
    .cancelSubscriptionWrapper,
    .skipPaymentMethodWrapper,
    .deleteAccountMethodWrapper,
    .changePaymentMethodWrapper {

        .topArea {

            .title {
                @include notoSans(24px, bold, 1.5);
                color: #101010;
                display: flex;

                &.center {
                    justify-content: center;
                    text-align: center;
                }
            }

            .description {
                margin-top: 8px;
                @include notoSans(16px, 500, 1.5);
                color: $grey_6;

            }

            .paymentMethodTaps {

                margin-top: 48px;

                display: flex;
                justify-content: space-between;

                .paymentMethod {
                    width: 100%;
                    height: 56px;

                    margin-right: 8px;

                    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px #ebebeb;
                    border-radius: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #ffffff;

                    @include notoSans(16px, bold, 1.5);

                    &:last-child {
                        margin-right: unset;
                    }

                    &.on {
                        background-color: #000000;
                        color: #ffffff;
                        border: solid 1px #101010;
                    }
                }
            }
        }

        .content {
            margin-top: 24px;

            display: flex;
            flex-direction: column;

            .cardTypeRadioBtns {
                display: flex;

                .cardTypeBtn {

                    margin-right: 24px;
                    @include notoSans(16px, bold, 1.5);
                    color: #bbbbbb;

                    display: flex;
                    align-items: center;

                    .radioBtn {
                        width: 16px;
                        height: 16px;
                        margin-right: 4px;
                    }

                    &:last-child {
                        margin-right: 0px;
                    }

                    &.on {
                        color: #111111;

                    }
                }
            }

            .cardInfo {
                margin-top: 24px;

                .cardNum,
                .expirationDate,
                .registrationNumber,
                .cardPwdNum {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    align-items: center;

                    margin-bottom: 8px;

                }

                .expirationDate {
                    .selectBox {
                        height: 48px;
                        width: 100%;

                        padding-left: 16px;
                        margin-right: 8px;
                        border-radius: 6px;
                        background-color: #f7f7f7;

                        background: url('/assets/m-btn-icon-accordion-arrow-down-gray-16.svg') no-repeat 95% 50%;

                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &:last-child {
                            margin-right: 0px;
                        }

                    }

                    select::-ms-expand {
                        display: none;
                    }
                }
            }

            .description {
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            .dateSelectArea {

                display: grid;
                grid-template-columns: repeat(8, 1fr);
                row-gap: 2px;
                column-gap: 6px;

                .col {

                    text-align: center;
                    cursor: pointer;
                    pointer-events: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    aspect-ratio: 1;

                    @include montSerrat(24px, bold, 1.17);
                    color: #bbbbbb;

                    &.selectAvailable {
                        color: #101010;
                        pointer-events: unset;
                    }

                    &.on {
                        color: #ffffff;
                        border-radius: 50%;
                        background-color: #101010;
                        box-shadow: 0 6px 8px 0 rgba(16, 16, 16, 0.1);
                    }
                }

            }
        }

        .btn {
            margin-top: 24px;

            width: 100%;
            height: 64px;
            border-radius: 36px;
            box-shadow: 0 6px 8px 0 rgba(237, 46, 27, 0.1);

            @include notoSans(16px, bold, 1.5);

            &.marginTop {
                margin-top: 52px;
            }

        }

    }

    .cancelSubscriptionWrapper {
        .content {
            overflow-y: auto;
            overflow-x: visible;
            padding-right: 48px;
            padding-left: 48px;
            height: calc(60vh - 84px);

            .fullGreyArea {
                background-color: #eeeff3;

                margin-left: -48px;
                margin-right: -48px;
                padding: 24px 48px 24px;

                .fullGreyTitle {
                    @include notoSans(20px, bold, 1.4);
                    color: #111111;
                    text-align: center;
                }

                .fullGreyDescription {
                    margin-top: 6px;
                    @include notoSans(13px, bold, 1.38);
                    color: #666666;
                    text-align: center;
                }
            }
        }
    }

    .skipPaymentMethodWrapper {
        padding-top: 40px;
        padding-bottom: 24px;
    }


}