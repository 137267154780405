@import '../../RootVariable.scss';

.navigation-wrapper {
    padding-top: 20px;
    width: 100%;

    &.visible-dt {
        display: block;
        // padding-left: 0;
        // padding-right: 0;
    }

    .control-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-bottom: 12px;

        .navigation-control {
            cursor: pointer;
            @include notoSans(14px, 700, 20px);
            color: $grey_4;
            text-align: center;

            &.active {
                color: $black;
            }
        }
    }

    .bar {
        width: 100%;
        overflow: visible;
        height: 1px;
        // background-color: $main_line;
        background-color: #ebebeb;
        position: relative;
        .bar-current {
            position: absolute;
            transition: all 0.5s;
            height: 2px;
            background-color: $black;
            // width: 33.33333%;
        }
    }
}

@media (min-width: $breakPointDt) {
    .navigation-wrapper {
        padding-top: 48px;

        &.visible-dt {
            display: block;
            // padding-left: 64px;
            // padding-right: 64px;
        }

        .control-wrapper {
            .navigation-control {
                cursor: pointer;
                @include notoSans(18px, 700, 26px);
                color: $grey_4;

                &.active {
                    color: $black;
                }
            }
        }
    }
}
