@import '../../RootVariable.scss';

.signupContent {
  background-color: $white;
  // height: 3000px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  padding-top: 40px;
  padding-left: 32px;
  padding-right: 32px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.signupContent::-webkit-scrollbar {
  display: none;
}

.signupBenefits {
  // margin-top: 48px;

  .signupBenefit {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: unset;
    }

    .benefitImg {

      background-color: #f7f7f7;
      border-radius: 18px;
      width: 56px;
      height: 56px;
    }

    .benefitTexts {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .benefitTitle {
        @include notoSans(16px, bold, 1.5);
        color: #101010;
      }

      .benefitDesc {
        @include notoSans(13px, normal, 1.38);
        color: #999999;
      }
    }
  }
}

.signupHeadText {
  color: $black;
  @include notoSans(18px, 900, 1.44);
  margin-bottom: 48px;
}

.signupMethodWrapper {
  margin-top: 24px;

  .signupMethodDesc {
    width: 186px;
    background-color: #ebebeb;
    padding: 4px 10px;
    border-radius: 14px;

    @include notoSans(11px, 500, 1.45);
    color: #151515;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 10%;
      border-top: 4px solid #ebebeb;
      border-left: 6px solid transparent;
      border-right: 1px solid transparent;
      border-bottom: 0px solid transparent;

    }
  }

}

.signupMethod {
  border-radius: 24px;
  border: solid 1px $grey_5;
  padding: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  background-color: $white;

  &.hide {
    display: none;
  }

  &.kakao {
    border: unset;
    margin-bottom: 48px;
  }
}

.signupMethodText {
  color: $black;
  @include notoSans(14px, 500, 1.43);
}

.signupMethodTopDesc {

  color: #999;
  @include notoSans(13px, normal, 1.38);
  margin-top: 24px;
}

.signupToggleBtn {
  cursor: pointer;
  margin-top: 32px;
  @include notoSans(13px, normal, 1.23);
  color: #101010;
}

.loginLogo {
  width: 28px;
  height: 28px;
  position: absolute;
  left: 10px;
  top: 10px;

  img {
    width: 100%;
  }
}

.errMsg {
  display: block;
  color: $red;
  @include notoSans(12px, normal, 1.33);
  margin-top: 4px;
  margin-bottom: 16px;
}

.errMsgCheck{
  margin-left: 24px;

}

.signinInCheck {
  margin-top: 10px;

  .errMsg {
    display: block;
    color: $red;
    @include notoSans(12px, normal, 1.33);
    margin-top: 4px;
    margin-bottom: 10px;
  }
}

.signinInCheck:first-of-type {
  margin-top: 16px;
}

.signinButton {
  margin: 32px 0;
  width: 100%;
  background-color: $grey_3;
  transition: background-color 300ms;

  &.active {
    background-color: $black;
  }
}


.loginPasswordWrapper {
  transition: all 500ms;
  max-height: 0;
  overflow: visible;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  margin-top: 8px;

  &.existing {
    max-height: 100px;
    visibility: visible;
    z-index: inherit;
    opacity: 1;
    // overflow: visible;
  }
}

.singUpWrapper {
  margin-top: 48px;
}

.allCheckTermBtnWrapper {
  display: flex;
  align-items: center;
  margin-top: 32px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .allCheckTermBtnBoldText {
    @include notoSans(14px, 500, 1.29);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #101010;
  }

  .allCheckTermBtnText {
    margin-left: 2px;
    @include notoSans(13px, normal, 1.38);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #999;
  }
}

.termBtns {
  margin-top: 18px;
}

.signinInput {
  margin-top: 8px;

  &.mt {
    margin-top: 10px;
  }
}

.bgYellow {
  background-color: #fff26f;
}

.searchAddressBtn {
  margin-left: 8px;
  padding: 15px 19px;
  @include notoSans(14px, 500, 1.43);
  white-space: nowrap;
  border: 1px solid #ebebeb;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }
}

.withBtn {
  display: flex;
  align-items: center;
}

.uploadFileBtn {
  margin-left: 8px;
  padding: 15px 19px;
  @include notoSans(14px, 500, 1.43);
  white-space: nowrap;
  border: 1px solid #ebebeb;
  border-radius: 6px;

  &:hover {
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.alreadyRegisteredModalBg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: map-get($map: $zIndex, $key: modal);
}

.alreadyRegisteredContent {
  background-color: $white;
  width: 312px;
  padding: 32px;
  border-radius: 8px;
}

.alreadyRegisteredMainText {
  @include notoSans(16px, bold, 1.5);
}

.alreadyRegisteredIdContainer {
  margin-top: 24px;
  width: 100%;
  background-color: $grey_2;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;

  img {
    margin-right: 8px;
  }
}

.alreadyRegisteredButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.alreadyRegisteredCancleButton {
  width: calc(50% - 2px);
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px $grey_5;
  border-radius: 7px;
  margin-top: 32px;
  cursor: pointer;
  @include notoSans(13px, 500, 1.38);
}

.alreadyRegisteredLoginButton {
  width: calc(50% - 2px);
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: solid 1px $grey_5;
  background-color: $red;
  border-radius: 7px;
  margin-top: 32px;
  cursor: pointer;
  @include notoSans(13px, 500, 1.38);
  color: $white;
}

.signupPartnerNotice {
  margin-top: 32px;
  color: #999999;
  @include notoSans(13px, normal, 1.38);
}

@media (min-width: $breakPointTbl) {
  .signupContent {
    background-color: $white;
    // height: 3000px;
    // max-height: calc(100vh - 100px);
    min-height: 524px;
    max-height: 712px;
    width: 500px;
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    padding: 40px 32px;
    // margin-bottom: 144px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    border-radius: 20px;
  }

  .signinButton {
    margin-bottom: 0;
  }
}

@media(min-width: $breakPointDt) {

  .signupContent {

    min-height: 524px;
    max-height: 871px;
    padding: 40px 48px;
  }

  .loginLogo {
    width: 40px;
    height: 40px;
  }

  .signupHeadText {
    // color: $black;
    @include notoSans(24px, 900, 1.5);
  }

  .signupMethodText {
    color: $black;
    @include notoSans(16px, bold, 1.5);
  }

  .signupMethod {
    height: 64px;
    border-radius: 36px;
    // border: solid 1px $grey_5;
    // padding: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 16px;
    background-color: $white;

    &:first-child {
      margin-top: 10px;
    }

    &.hide {
      display: none;
    }
  }

  .signupMethodWrapper {
    // margin-top: 48px;

    .signupMethodDesc {
      width: 220px;
      background-color: #ebebeb;
      padding: 6px 12px 4px;
      border-radius: 14px;

      @include notoSans(13px, 500, 1.38);
      color: #151515;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 10%;
        border-top: 4px solid #ebebeb;
        border-left: 6px solid transparent;
        border-right: 1px solid transparent;
        border-bottom: 0px solid transparent;

      }
    }

  }

  .signupBenefits {

    .signupBenefit {

      .benefitImg {
        width: 64px;
        height: 64px;
        display: flex;
      }
    }
  }
}
