@import '../../RootVariable.scss';


.modal-page-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.6);
}

.restocking-modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #FFFFFF;
  max-width: none;
  max-height: 900px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1002;
  padding: 20px;

  .restocking-modal-close-button {
    @include notoSans(20px, bold, normal);
    float: right;
    cursor: pointer;

  }

  .restocking-modal-body {
    padding: 20px;


    .restocking-modal-header {
      @include notoSans(16px, 900, 1.5);
      text-align: center;
      padding-top: 100px;
      color: #101010;
      margin-bottom: 50px;
    }

    input {
      background-color: #f4f4f4;
      text-align: center;
      color: #101010;
      margin-top: 10px;
    }

    input::placeholder {
      text-align: center;
    }

    input:focus::placeholder {
      color: transparent;
    }


    .restocking-modal-button {
      margin-top: 70px;
      width: 100%;
      height: 56px;
      cursor: not-allowed;
      background-color: #dddddd;
      color: #ffffff;
      padding: 17px 0;
      border: 0;
      display: flex;
      justify-content: center;
      border-radius: 30px;
      @include notoSans(15px, bold, normal);
    }

    .active {
      cursor: pointer;
      background-color: #ed2e1b;
      color: #ffffff;
    }
  }
}


@media (min-width: $breakPointTbl) {
  .restocking-modal-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #FFFFFF;
    max-width: 400px;
    max-height: 700px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1002;
    padding: 20px;
  }
}
