@import '../../RootVariable.scss';

.WelcomePageWrapper {
    width: 100%;
    // margin-top: 57px;
    // overflow-x: hidden;

    // .scrollingPosition {
    //     height: 80px;
    //     visibility: hidden;
    // }

    .WelcomePage {
        a {
            color: $black;
            text-decoration: none;

            &:visited {
                color: $black;
            }
        }

        .welcomePageHeaderSection {
            color: $black;
            text-align: center;
            position: relative;
            padding-bottom: 100%;
            background: url('../../assets/hello_page_m.jpg') no-repeat center;
            background-size: 100%;

            //&.login {
            //    background: url('../../assets/hello_page_m.jpg') no-repeat center;
            //    background-size: 100%;
            //}

            .wrapper {
                margin: 0 auto;
                position: absolute;
                top: 18.75%;
                left: 50%;
                transform: translateX(-50%);
            }

            h3 {
                margin-bottom: 2px;
                @include montSerrat(48px, 900, 1.21);
            }

            h5 {
                @include notoSans(16px, 500, 1.5);
            }
        }

        .menuTab {
            width: 100vw;
            display: flex;
            justify-content: space-between;
            position: sticky;
            background-color: #f1f1f1;
            z-index: map-get($map: $zIndex, $key: overFlowImg);
            .menuItem {
                width: 33.3%;
                padding: 16px 20px;
                flex-grow: 1;
                color: #adadad;
                @include notoSans(15px, 900, 1.33);
                text-align: center;
                border-right: 1px solid #dcdcdc;

                &:hover {
                    cursor: pointer;
                }

                &:last-child {
                    border-right: none;
                }

                &.active {
                    color: $white;
                    position: relative;
                    // transition: all 0.3s ease-in-out;
                    background-color: $black;

                    &:after {
                        border-top: 5px solid $black;
                    }

                    &:after {
                        opacity: 1;
                    }
                }

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-bottom: 0px solid transparent;
                    transform: translateX(-50%);
                    opacity: 0;
                }
            }
        }

        .welcomeBodySection {
            padding: 0 32px;

            .welcomeCard {
                margin: 0 0 80px;
                text-align: center;

                &.kakao {
                    width: 100vw;
                    margin-left: -32px;
                    padding: 80px 0;
                    margin-bottom: 0;
                    overflow-x: hidden;
                    background-color: #fae300;
                }

                &.delivery {
                    width: 100vw;
                    margin-left: -32px;
                    padding: 80px 0;
                    background-color: #e6e6e6;
                }

                &.shop {
                    position: relative;

                    .coin {
                        // width: 15.8%;
                        position: absolute;
                        filter: drop-shadow(6px 9px 9px rgba(0, 0, 0, 0.1));

                        &.coin1 {
                            width: 57px;
                            top: 48px;
                            right: 0;
                            transform: rotate(-38deg);
                        }

                        &.coin2 {
                            width: 57px;
                            top: -40px;
                            left: 0;
                            transform: rotate(53deg);
                        }

                        &.coin3 {
                            width: 44px;
                            top: -40px;
                            right: 10px;
                            transform: rotate(35deg);
                        }
                    }
                }

                &:first-child {
                    padding-top: 64px;
                }

                // .number {
                //     width: 28px;
                //     height: 28px;
                //     margin: 0 auto 16px;
                //     border-radius: 50%;
                //     @include montSerrat(15px, 900, normal);
                //     font-style: italic;
                //     text-align: center;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;

                //     &.first {
                //         background-color: $black;
                //         color: $white;
                //     }

                //     &.second {
                //         background-color: $black;
                //         color: $white;
                //     }

                //     &.third {
                //         background-color: #f13a5c;
                //         color: $white;
                //     }
                // }

                .number {
                    width: 58px;
                    height: 32px;
                    margin-bottom: 16px;
                }

                .benefitTitle {
                    margin-bottom: 24px;
                    @include notoSans(24px, 900, normal);

                    span {
                        background-position: 0 80%;
                        background-size: 100% 5px;
                        background-repeat: no-repeat;
                        // transition: background-size 0.5s;

                        &.hundred {
                            background-image: linear-gradient(#ffc600, #ffc600);
                        }

                        &.kakao {
                            background-image: linear-gradient(#ff8000, #ff8000);
                        }

                        &.delivery {
                            background-image: linear-gradient(#ffc600, #ffc600);
                        }
                    }

                    .underlineAnimation {
                        animation: underline 0.3s ease-in;
                    }

                    @keyframes underline {
                        from {
                            background-size: 0 5px;
                        }
                        to {
                            background-size: 100% 5px;
                        }
                    }
                }

                .infoWrapper {
                    padding: 24px;
                    margin-bottom: 64px;
                    border-radius: 17px;
                    background-color: #f4f4f4;
                    text-align: left;
                    position: relative;

                    .title {
                        padding-left: 20px;
                        margin-bottom: 17px;
                        @include notoSans(16px, bold, 1.5);
                        position: relative;
                        display: flex;
                        align-items: center;

                        &:before {
                            display: block;
                            content: '';
                            width: 18px;
                            height: 18px;
                            background: url('../../assets/check-mark.svg')
                                no-repeat center;
                            position: absolute;
                            left: 0;
                        }
                    }

                    .infos {
                        @include notoSans(13px, bold, 1.38);

                        li {
                            padding-left: 8px;
                            margin-bottom: 8px;
                            position: relative;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &:before {
                                display: block;
                                content: '';
                                width: 4px;
                                height: 4px;
                                position: absolute;
                                top: 7px;
                                left: 0;
                                background-color: #999999;
                                border-radius: 50%;
                            }

                            div {
                                padding-left: 8px;
                                margin-top: 4px;
                                @include notoSans(13px, 500, 1.38);
                                color: #999999;
                            }
                        }
                    }
                }

                .hundredShopTitle {
                    padding: 10px 32px;
                    color: $black;
                    @include notoSans(18px, 900, 1.56);
                    border-top: 2px solid $black;
                    border-bottom: 1px solid $black;
                    position: relative;

                    span {
                        @include notoSans(13px, normal, 1.38);
                    }
                }

                .productsListWrapper {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -16px;

                    .productCardWrapper {
                        width: calc(50% - 16px);
                        margin-left: 16px;
                        margin-top: 24px;
                        text-align: left;
                    }
                }

                .desc {
                    @include notoSans(14px, bold, 1.43);
                }

                .descComment {
                    margin-top: 4px;
                    color: rgba(16, 16, 16, 0.5);
                    @include notoSans(13px, normal, 1.38);
                }

                .kakaoCouponImg,
                .freeDeliveryImg {
                    width: 100%;
                    margin-top: 24px;
                    padding: 0 32px;
                }

                .kakaoCouponBtn {
                    margin: 32px 32px 0;
                    padding: 14px 50px;
                    background-color: $black;
                    color: $white;
                    @include notoSans(14px, bold, 1.43);
                    border-radius: 24px;
                    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);

                    &:hover {
                        cursor: pointer;
                    }
                }

                .freeDeliveryImg {
                    margin-top: 32px;

                    &.mb {
                        display: block;
                    }

                    &.dt {
                        display: none;
                    }
                }
            }
        }
    }

    .categoryAndBestProductsListSection {
        padding: 0 32px;
        margin-top: 40px;
        margin-bottom: 48px;
        overflow: hidden;

        .wrapper {
            padding-bottom: 80px;

            &:last-child {
                padding-bottom: 0;
            }
        }

        .categoryTitle {
            @include notoSans(24px, 900, 1.42);
            margin-bottom: 24px;
            text-align: center;
        }

        .categoryWrapper {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 24px 10px;

            .categoryItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .imgWrapper {
                    width: 64px;
                    height: 64px;
                    margin-bottom: 4px;
                    background-color: #f7f7f7;
                    border-radius: 20px;
                }
                img {
                    width: 100%;
                    height: 100%;
                    margin-bottom: 10px;
                }
                span {
                    white-space: nowrap;
                    @include notoSans(11px, 500, 1.27);
                }
            }
        }

        .bestProductsWrapper {
            .homeContainers {
                margin-top: 0;
            }

            .homeProductListname {
                display: none;
            }
        }
    }

    .mobileBtn {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            #fff 41%
        );
        z-index: map-get($map: $zIndex, $key: coveringContent);

        .button {
            padding: 14px 45px;
            margin: 24px 32px;
            background-color: $red;
            border-radius: 24px;
            color: $white;
            @include notoSans(14px, bold, 1.43);
            box-shadow: 0 6px 8px 0 rgba(237, 46, 27, 0.1);
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: 500px) {
    .WelcomePageWrapper
        .categoryAndBestProductsListSection
        .categoryWrapper
        .categoryItem
        .imgWrapper {
        width: unset;
        height: unset;
    }
}

@media (min-width: $breakPointTbl) {
    .WelcomePageWrapper {
        background-color: #dcdbd2;
        // margin-top: 100px;

        .WelcomePage {
            width: 100%;
            padding: 0 64px;
            margin: 0 auto;

            .welcomePageHeaderSection {
                background: url('../../assets/hello_page_p.jpg') no-repeat center;
                background-size: 100%;
                padding-bottom: 67.7%;

                &.login {
                    background: url('../../assets/hello_page_p.jpg') no-repeat
                        center;
                    background-size: 100%;
                }
            }

            .menuTab {
                width: 100%;

                .menuItem {
                    padding: 26px;
                    @include notoSans(18px, 900, 1.44);

                    br {
                        display: none;
                    }
                }
            }

            .welcomeBodySection {
                padding: 0 40px;
                background-color: $white;

                .welcomeCard {
                    &.kakao,
                    &.delivery {
                        width: calc(100% + 80px);
                        margin-left: -40px;
                    }
                    .productsListWrapper {
                        margin-left: -8px;
                        .productCardWrapper {
                            width: calc(25% - 8px);
                            margin-left: 8px;
                            margin-top: 28px;
                        }
                    }

                    &.shop {
                        .coin {
                            &.coin1 {
                                width: 71px;
                            }

                            &.coin2 {
                                width: 64px;
                            }

                            &.coin3 {
                                width: 52px;
                            }
                        }
                    }

                    .kakaoCouponBtn {
                        width: 380px;
                        margin: 0 auto;
                    }

                    .kakaoCouponImg,
                    .freeDeliveryImg {
                        padding: 0 64px;
                    }
                }
            }
        }

        .categoryAndBestProductsListSection {
            background-color: $white;
            margin-bottom: 0;
            padding: 0;

            .wrapper {
                padding: 80px 64px;

                &:last-child {
                    padding: 0 64px 80px;
                }

                .swiper-button-next {
                    right: 64px !important;
                }

                .bestProductsWrapper {
                    width: 100%;
                }
            }

            .categoryWrapper {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 16px;

                .categoryItem {
                    // width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 30px 16px;
                    border: 1px solid #ebebeb;
                    border-radius: 30px;
                    transition: box-shadow 0.4s;

                    .imgWrapper {
                        width: unset;
                        height: unset;
                        margin-bottom: 6px;
                        background-color: $white;
                    }

                    // img {
                    //     width: 130px;
                    //     height: 130px;
                    //     max-width: 160px;
                    //     max-height: 160px;
                    //     margin-bottom: 10px;
                    // }
                    span {
                        @include notoSans(13px, 500, 1.38);
                    }

                    &:hover {
                        cursor: pointer;
                        border: 2px solid $black;
                        box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
                        margin: -1px;

                        // img {
                        //     transform: scale(1.1);
                        //     transition: transform 0.3s ease-in;
                        // }
                    }
                }
            }
        }

        .mobileBtn {
            width: 100vw;
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            background: unset;
            box-shadow: none;
            background-color: $black;

            .button {
                width: fit-content;
                padding: 18px 45px;
                margin: 0 auto;
                border-radius: 0;
                background-color: $black;
                @include notoSans(18px, bold, normal);
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
                position: relative;

                &:after {
                    display: block;
                    width: 18px;
                    height: 18px;
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    background: url('../../assets/whitebg-arrow.svg') no-repeat
                        center;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .WelcomePageWrapper {
        width: 100vw;
        // margin-top: 140px;

        .WelcomePage {
            width: 1200px;
            max-width: 1328px;

            .menuTab {
                .menuItem {
                    padding: 30px;
                    @include notoSans(24px, 900, normal);

                    br {
                        display: none;
                    }

                    &.active {
                        &:after {
                            border-top: 12px solid $black;
                        }
                    }

                    &:after {
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                    }
                }
            }

            .welcomeBodySection {
                .welcomeCard {
                    margin: 0;
                    padding: 140px 0;

                    &.delivery {
                        background-color: #e6e6e6;
                    }

                    &.shop {
                        .coin {
                            // max-width: 155px;

                            &.coin1 {
                                width: 114px;
                                top: 70px;
                                right: 2%;
                                transform: rotate(-38deg);
                            }

                            &.coin2 {
                                width: 100px;
                                top: -80px;
                                left: -50px;
                                transform: rotate(53deg);
                            }

                            &.coin3 {
                                width: 72px;
                                top: -35%;
                                right: 10%;
                                transform: rotate(-35deg);
                            }
                        }
                    }

                    &:first-child {
                        padding-top: 100px;
                    }

                    .number {
                        width: 87px;
                        height: 48px;
                    }

                    .benefitTitle {
                        margin-bottom: 40px;
                        @include notoSans(48px, 900, 1.46);

                        span {
                            background-size: 100% 6px;
                            background-position: 0 90%;
                        }

                        .underlineAnimation {
                            animation: underline 0.3s ease-in;
                        }

                        @keyframes underline {
                            from {
                                background-size: 0 6px;
                            }
                            to {
                                background-size: 100% 6px;
                            }
                        }
                    }

                    .infoWrapper {
                        width: 740px;
                        padding: 56px 64px;
                        margin: 0 auto 140px;
                        border-radius: 24px;

                        .title {
                            padding-left: 32px;
                            margin-bottom: 30px;
                            @include notoSans(28px, 900, 1.29);

                            &:before {
                                width: 26px;
                                height: 26px;
                            }
                        }

                        .infos {
                            @include notoSans(22px, 500, 1.45);

                            li {
                                padding-left: 11px;
                                margin-bottom: 16px;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &:before {
                                    width: 5px;
                                    height: 5px;
                                    top: 14px;
                                }

                                div {
                                    padding-left: 11px;
                                    @include notoSans(18px, 500, 1.44);
                                }
                            }
                        }
                    }

                    .hundredShopTitle {
                        padding: 24px 0;
                        margin: 0 64px;
                        color: $black;
                        @include notoSans(40px, 900, 1.45);

                        span {
                            @include notoSans(18px, 500, 1.44);
                        }
                    }

                    .collectionInfo {
                        margin-top: 8px;
                        @include notoSans(18px, 500, 1.44);
                    }

                    .productsListWrapper {
                        padding: 0 64px;
                        margin-left: -24px;

                        .productCardWrapper {
                            width: calc(25% - 24px);
                            margin-left: 24px;
                            margin-top: 64px;
                        }
                    }

                    .desc {
                        @include notoSans(22px, 500, 1.46);
                    }

                    .descComment {
                        @include notoSans(18px, 500, 1.44);
                    }

                    .kakaoCouponImg,
                    .freeDeliveryImg {
                        margin-top: 72px;
                    }

                    .kakaoCouponImg {
                        max-width: 680px;
                    }

                    .freeDeliveryImg {
                        &.mb {
                            display: none;
                        }

                        &.dt {
                            display: block;
                            max-width: 600px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }

                    .kakaoCouponBtn {
                        width: 440px;
                        margin: 64px auto 0;
                        padding: 20px 50px;
                        @include notoSans(16px, bold, 1.5);
                        border-radius: 36px;
                    }
                }
            }
        }

        .categoryAndBestProductsListSection {
            margin: 140px auto;
            background-color: $white;
            .wrapper {
                width: 1200px;
                max-width: 1328px;
                margin: 0 auto;
                padding: 140px 64px;

                &.first-child {
                    padding-top: 0;
                }

                &:last-child {
                    padding: 0 64px;

                    .categoryTitle {
                        margin-bottom: 40px;
                    }
                }
            }

            .categoryTitle {
                @include notoSans(40px, 900, 1.45);
                margin-bottom: 80px;
                text-align: center;
            }

            .categoryWrapper {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 24px;

                .categoryItem {
                    // width: 25%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 48px 60px 32px;
                    border: 1px solid #ebebeb;
                    border-radius: 30px;
                    transition: box-shadow 0.4s;

                    .imgWrapper {
                        width: unset;
                        height: unset;
                        margin-bottom: 6px;
                        background-color: $white;
                    }

                    img {
                        width: 130px;
                        height: 130px;
                        max-width: 160px;
                        max-height: 160px;
                        margin-bottom: 10px;
                    }
                    span {
                        @include notoSans(18px, bold, 1.44);
                    }

                    &:hover {
                        cursor: pointer;
                        border: 2px solid $black;
                        @include defaultShadow;
                        margin: -1px;

                        img {
                            transform: scale(1.1);
                            transition: transform 0.3s ease-in;
                        }
                    }
                }
            }

            // .bestProductsWrapper {
            //     margin-left: -24px;
            //     display: flex;
            //     flex-wrap: wrap;
            //     .productCardWrapper {
            //         width: calc(25% - 24px);
            //         margin-left: 24px;
            //         margin-top: 64px;
            //     }
            // }
        }

        .mobileBtn {
            .button {
                padding: 24px 45px;
                @include notoSans(22px, bold, normal);

                &:after {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}
