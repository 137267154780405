@import '../../RootVariable.scss';

.BBSHiddenPage {
    // width: 100vw;
    overflow-x: hidden i !important;

    img {
        width: 100%;

        &.click {
            cursor: pointer;
        }

        &.mb {
            display: block;
        }

        &.pc {
            display: none;
        }
    }
}

@media (min-width: $breakPointDt) {
    .BBSHiddenPage {
        margin: 0 auto;
        max-width: 1328px;
        width: 1200px;

        img {
            width: 100%;

            &.click {
                cursor: pointer;
            }

            &.mb {
                display: none;
            }

            &.pc {
                display: block;
            }
        }
    }
}
