@import '../../RootVariable.scss';

.ChangeOptionPopupContainer {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 50px);
    position: fixed;
    bottom: 0;
    background-color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: overDimBg);

    &.underTheDimmer {
        z-index: 39;
    }

    .closeOptionPopupBtn {
        position: absolute;
        top: -30px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .ChangeOptionPopup {
        height: auto;
        max-height: calc(100vh - 50px);
        padding: 32px;
        padding-bottom: 127px;
        overflow: scroll;
        position: static;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &.bundle {
            .optionItemHeadArea {
                display: flex;

                .optionImg {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                    border-radius: 6px;
                }

                .rightSide {
                    flex-grow: 1;
                    .upperSide {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 4px;

                        .optionName {
                            margin-left: 0;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .bottomSide {
                        display: flex;
                        justify-content: space-between;

                        .qty {
                            @include notoSans(12px, normal, 1.33);
                            color: #999999;
                        }

                        .priceSection {
                            .showPrice {
                                .displayPrice {
                                    @include montSerrat(13px, bold, 1.38);
                                }
                            }
                        }
                    }
                }
            }
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .headArea {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            .productImg {
                width: 40px;
                height: 40px;
                margin-right: 8px;
                border-radius: 6px;
            }

            .OptionTitle {
                margin-bottom: 0;
                @include notoSans(14px, bold, 1.43);
            }
        }

        .selectedProductBox {
            padding: 16px;
            margin-bottom: 16px;
            background-color: #f7f7f7;
            border-radius: 6px;

            .optionItemHeadArea {
                .bundleProductName {
                    margin-bottom: 8px;
                    @include notoSans(13px, 500, 1.38);
                }
            }

            .optionItemBodyArea {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }
                }
            }
        }

        .optionDropdown {
            // height: 48px;
            margin-bottom: 8px;
            background-color: $grey_1;

            .leftSide {
                display: flex;
                align-items: center;

                .defaultTextIcon {
                    margin-right: 4px;
                }
            }

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }
        }

        .optionDropdown.isExpanded {
            background-color: $white;

            .defaultTextArea,
            .dropdownItems {
                color: $black;
                @include notoSans(13px, normal, 1.38);

                &.options {
                    img {
                        border-radius: 6px;
                    }
                }

                &:hover {
                    @include notoSans(13px, bold, 1.38);
                    background-color: $grey_2;
                }
            }
        }

        .optionList {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);

                            .dogFoodName {
                                span {
                                    color: #999999;
                                }
                            }

                            .dogFoodPeriod {
                                margin-top: 4px;
                                color: #999999;
                                @include notoSans(12px, 500, 1.33);
                            }
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(14px, bold, 1.43);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                        }
                    }
                }

                &.bundle {
                    margin-top: 16px;
                    border: 1px solid #ebebeb;
                    border-radius: 6px;
                    background-color: #fff;
                }
            }
        }
    }

    .changeOptionBtnContainer {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 7500;

        .bundleProductTotals {
            padding: 0 48px;
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .priceTitle {
                @include notoSans(14px, bold, 1.71);
            }

            .price {
                @include montSerrat(16px, bold, 1.13);
            }
        }

        .changeOptionBtn {
            width: calc(100% - 64px);
            margin: 16px 32px 24px;
            padding: 14px 71px;
            display: block;
            color: $white;
            background-color: $black;
            border-radius: 24px;
            @include notoSans(14px, bold, 1.43);
            text-align: center;
        }
    }
}

@media (min-width: $breakPointTbl) {
    .ChangeOptionPopupContainer {
        width: 640px;
        height: auto;
        max-height: calc(100vh - 200px);
        top: 50%;
        left: 50%;
        bottom: unset;
        transform: translate(-50%, -50%);
        border-radius: 20px;

        .closeOptionPopupBtn {
            top: -40px;
            right: 0;

            img {
                width: 40px;
                height: 40px;
            }
        }

        .ChangeOptionPopup {
            max-height: calc(100vh - 200px);
            padding: 40px 48px 0;
            padding-bottom: 190px;
            border-radius: 20px;
            // overflow: unset;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }

            .optionDropdown {
                margin-bottom: 16px;

                .defaultTextArea {
                    padding: 20px 24px;
                    // @include notoSans(16px, normal, 1.5);
                }
            }

            // .optionDropdown.isExpanded {
            //     .defaultTextArea,
            //     .dropdownItems {
            //         // @include notoSans(16px, normal, 1.5);

            //         &:hover {
            //             @include notoSans(16px, normal, 1.5);
            //             font-weight: bold;
            //         }
            //     }
            // }
        }

        .changeOptionBtnContainer {
            position: absolute;
            bottom: 0;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            .changeOptionBtn {
                width: calc(100% - 96px);
                height: 64px;
                margin: 40px 48px 48px;
                padding: 20px 71px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .ChangeOptionPopupContainer {
        .dropdownWrapper {
            border-radius: 10px;
        }

        .displayPrice {
            @include notoSans(16px, bold, 1.13);
        }

        .ChangeOptionPopup {
            .headArea {
                .productImg {
                    width: 88px;
                    height: 88px;
                    margin-right: 16px;
                    border-radius: 10px;
                }

                .OptionTitle {
                    @include notoSans(24px, bold, 1.5);
                }
            }
            .selectedProductBox {
                border-radius: 10px;

                .optionItemHeadArea {
                    .bundleProductName {
                        @include notoSans(16px, 500, 1.5);
                    }
                }
            }

            .optionDropdown {
                .defaultTextArea {
                    @include notoSans(16px, normal, 1.5);
                }
            }

            .optionList {
                .optionItem {
                    border-radius: 10px;

                    .optionItemHeadArea {
                        margin-bottom: 16px;
                        .imgAndName {
                            .optionName {
                                @include notoSans(16px, 500, 1.5);

                                .dogFoodPeriod {
                                    @include notoSans(14px, 500, 1.29);
                                }
                            }
                        }
                    }

                    .priceSection {
                        .discountPercentage {
                            @include montSerrat(16px, bold, 1.13);
                        }

                        .showPrice {
                            .displayPrice {
                                @include montSerrat(16px, bold, 1.13);
                            }

                            .regularPrice {
                                @include montSerrat(14px, 500, normal);
                            }
                        }
                    }
                }
            }

            .optionDropdown.isExpanded {
                .defaultTextArea,
                .dropdownItems {
                    @include notoSans(16px, normal, 1.5);

                    &:hover {
                        @include notoSans(16px, bold, 1.5);
                    }
                }
            }

            &.bundle {
                .optionItemHeadArea {
                    .optionImg {
                        width: 48px;
                        height: 48px;
                    }

                    .rightSide {
                        .upperSide {
                            .optionName {
                                @include notoSans(14px, 500, 1.43);
                            }
                        }

                        .bottomSide {
                            .qty {
                                @include notoSans(14px, normal, 1.43);
                            }

                            .priceSection {
                                .showPrice {
                                    .displayPrice {
                                        @include montSerrat(14px, bold, 1.14);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
