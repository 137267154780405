@import '../../RootVariable.scss';

.accountPageWrapper {
    
    height: 100%;

    .paddingWrapper {

        padding-top: 24px;
        padding-left: $mobileContentPadding;
        padding-right: $mobileContentPadding;

        &.personalInfoPage {
            display: none;
        }
    }

    .userInfoArea {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        // height: 84px;

        .userName {
            @include notoSans(26px, 900, 1.38);
        }

        .userBtns {
            display: flex;
            align-items: center;
            height: 16px;

            .userDataUpdateBtn {
                cursor: pointer;
                @include notoSans(13px, normal, unset);
                color: #000000;
                margin-right: 12px;
                display: flex;
                align-items: center;
                height: 100%;

                .updateIcon {
                    width: 16px;
                    height: 16px;
                }
            }

            .logoutBtn {
                cursor: pointer;
                @include notoSans(13px, normal, unset);
                color: $grey_6;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }

    .mobileTaps {
        display: flex;
        justify-content: space-between;

        text-align: center;
        width: 100%;
        height: 52px;

        margin-top: 24px;

        .tap {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            @include notoSans(14px, bold, 1.43);
            color: $grey_6;

            border-bottom: 1px solid #ebebeb;

            cursor: pointer;

            &.on {
                border-bottom: 2px solid black;
                color: #000000;
            }
        }
    }

    .contentArea {
        background-color: #f4f4f4;

        min-height: 400px;

        &.personalInfoPage {
            background-color: #fff;
        }
    }
}

@media (min-width: $breakPointTbl) {
    .accountPageWrapper {
        display: flex;
        // margin-top: 100px;
        padding-top: 24px;

        .contentArea {
            border-top: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;

            width: calc(100% - 282px);

            .bestItemsList {
                padding: 0 64px;
            }
        }
    }

    .desktopNavigation {
        position: relative;
        width: 282px;
        padding-bottom: 80px;
        border-top: 1px solid #ebebeb;
        flex-direction: column;

        .paddingWrapper {
            padding: 24px 40px 0 64px;

            display: flex;
            flex-direction: column;

            &.personalInfoPage {
                display: block;
            }

            .userInfoArea {
                display: flex;
                flex-direction: column;
                align-items: unset;

                .userName {
                    @include notoSans(26px, 900, 1.38);
                    color: #101010;
                }

                .userBtns {
                    display: flex;

                    .userDataUpdateBtn {
                        @include notoSans(13px, normal, unset);
                        color: #000000;
                    }

                    .logoutBtn {
                        @include notoSans(13px, normal, unset);
                        color: $grey_6;
                    }
                }
            }

            .mobileTaps {
                margin-top: 40px;
                display: flex;
                flex-direction: column;
                align-items: unset;
                height: unset;

                .tap {
                    @include notoSans(14px, 500, 1.43);

                    display: flex;
                    justify-content: flex-start;

                    border-bottom: unset;

                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .accountPageWrapper {
        max-width: 1328px;
        padding: 0 64px;
        margin: 0 auto;

        width: 100%;
        position: relative;
        justify-content: center;

        .contentArea {
            width: 892px;
            height: fit-content;
            // max-width: 892px;
            border-left: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;

            .bestItemsList {
                padding: 0 64px;
            }
        }
    }

    .accountPageDtPositioning {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: inherit;
    }

    .desktopNavigation {
        position: sticky;
        padding-bottom: 180px;
        // top: 100px;
        left: 0px;
        width: 256px;
        // height: 100vh;
        border-top: 1px solid #ebebeb;
        // border-right: 1px solid #ebebeb;
        flex-direction: column;

        .paddingWrapper {
            padding: 80px 40px 0 64px;

            display: flex;
            flex-direction: column;

            .userInfoArea {
                display: flex;
                flex-direction: column;
                align-items: unset;

                .userName {
                    @include notoSans(44px, 900, 1.36);
                    color: #000000;
                }

                .userBtns {
                    display: flex;
                    margin-top: 24px;

                    .userDataUpdateBtn {
                        @include notoSans(18px, 500, unset);
                        color: #101010;
                    }

                    .logoutBtn {
                        @include notoSans(18px, normal, unset);
                        color: $grey_6;
                    }
                }
            }

            .mobileTaps {
                margin-top: 64px;
                display: flex;
                flex-direction: column;
                align-items: unset;

                .tap {
                    @include notoSans(18px, bold, 1.44);

                    display: flex;
                    justify-content: flex-start;
                    border-bottom: unset;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}
