@import '../../RootVariable.scss';

.homeWrapper {
  padding-left: $mobileContentPadding;
  padding-right: $mobileContentPadding;
  padding-bottom: 88px;
  // overflow: hidden;
  overflow-x: hidden;

  .BBcontainer {
    max-width: 1200px;
    margin-top: 80px;
  }
}

.homeContainers {
  margin-top: 80px;
  width: 100%;

  &.personalizedContainer {
    margin-top: 46px;
  }
}

@media (min-width: $breakPointTbl) {
  .homeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: $TblContentPadding;
    padding-right: $TblContentPadding;
    // .homeContainers {
    //     margin-top: 180px;
    //     max-width: 1200px;
    // }

  }
}

@media (min-width: $breakPointDt) {
  // .homeWrapper {
  //     padding-left: 0;
  //     padding-right: 0;
  //     margin-left: auto;
  //     margin-right: auto;
  // }
  .homeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 180px;

    .homeContainers {
      // margin-top: 180px;
      max-width: 1200px;
    }
  }

  .homeContainers {
    &.personalizedContainer {
      margin-top: 128px;
    }
  }
}
