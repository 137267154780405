@import '../../RootVariable.scss';

.postcodeWrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 23;

    img {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    div {
        &:first-child {
            margin-top: 42px;
            height: 100%;
        }
    }
}

@media (min-width: $breakPointTbl) {
    .postcodeWrapper {
        width: 500px;
        height: 100%;
        border-radius: 20px;

        div {
            &:first-child {
                border-radius: 20px;
            }
        }

        &.cart {
            width: 588px;
            height: calc(100vh - 124px);
            right: 0;
            left: unset;
            border-radius: 0%;
        }

        &.login {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 500px;
        }
    }
}
