@import '../../RootVariable.scss';

.collectionNavWrapper {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // width: 100%;
    // margin-left: -20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px 10px;
}

.homeCollectionNavigationTitle {
    @include notoSans(24px, 900, 1.42);
}

.collectionNavWrapper {
    margin-top: 24px;
}

@media (min-width: $breakPointTbl) {
    .collectionNavWrapper {
        grid-gap: 16px;
    }
}

@media (min-width: $breakPointDt) {
    .homeCollectionNavigationTitle {
        font-size: 40px;
    }
    .collectionNavWrapper {
        margin-top: 56px;
        grid-gap: 24px;
    }
}
