@import '../../RootVariable.scss';
.CollectionProductsContainer {
    .navSliderContentText {
        @include notoSans(14px, bold, 1.43);
        color: $grey_6;

        &.targetEl {
            padding: 6px 12px;
            background-color: $black;
            color: $white;
            border-radius: 17px;
            @include notoSans(13px, bold, 1.38);
        }
    }
}
.CollectionProductsNavSlider {
    position: sticky;
    top: 300px;
}
.collectionProducts {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;

    .productCardWrapper {
        width: calc(50% - 16px);
        margin-left: 16px;
        margin-top: 30px;
    }
}

.subCollectionNavWrapper {
    overflow: hidden;
    margin-right: -32px;
    margin-left: -32px;
    width: 100vw;
    padding-right: 32px;
    padding-left: 32px;
}

// .collectionNavigationDtPosition {
//     overflow: hidden;
//     margin-right: -32px;
//     margin-left: -32px;
//     width: 100vw;
//     padding-right: 32px;
//     padding-left: 32px;

// }

.collectionProductsQuantity {
    @include notoSans(13px, normal, 1.38);
    padding-top: 6px;
    padding-bottom: 6px;
}
.collectionPointText {
    font-weight: bold;
    color: $red;
}
.quantityAndSorting {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
}

.CollectionScrollUp {
}
.collectionFixedPosition {
    opacity: 0;
    width: 100vw;
    animation: fade-in 300ms;
    animation-fill-mode: forwards;
    animation-delay: 300ms;
    background-color: $white;
    left: 0;
    padding-left: $mobileContentPadding;
    border-top: solid 1px $grey_2;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    z-index: map-get($map: $zIndex, $key: onSliderCard);
    z-index: 12;
}

#subCollectionNav {
    // width: calc(100vw + 64px);
    // margin-left: -32px;
    // margin-right: -32px;
    // padding-left: 32px;
    position: sticky;
    background-color: $white;
    z-index: 10;
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: none;
    padding: 2px 0;
}

// .scrollDown {
//     width: 100vw;
//     padding-left: 32px;
//     margin-left: -32px;
//     position: sticky;
//     // top: 50px;
//     background-color: #fff;
//     border-top: 1px solid #ebebeb;
//     z-index: 11;
//     box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
// }

// .scrollUp {
//     width: 100vw;
//     padding-left: 32px;
//     margin-left: -32px;
//     position: sticky;
//     // top: 132px;
//     background-color: #fff;
//     border-top: 1px solid #ebebeb;
//     z-index: 11;
//     box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
// }

// .originPosition {
//     width: 100vw;
//     position: sticky;
//     top: 0;
//     border-top: none;
//     box-shadow: none;
// }

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (min-width: $breakPointTbl) {
    .subCollectionNavWrapper {
        overflow: hidden;
        margin-right: unset;
        margin-left: unset;
        width: unset;
        padding-right: unset;
        padding-left: unset;
    }
    .collectionProducts {
        .productCardWrapper {
            width: calc(33.3% - 16px);
            margin-left: 16px;
            margin-top: 30px;
        }
    }
    .collectionFixedPosition {
        padding-left: $TblContentPadding;
    }

    // #subCollectionNav {
    //     width: calc(100vw + 96px);
    // }

    // .scrollDown,
    // .scrollUp {
    //     padding-left: $TblContentPadding;
    //     margin-left: -$TblContentPadding;
    // }
}

@media (min-width: $breakPointDt) {
    .CollectionProductsContainer {
        .navSliderContentText {
            @include notoSans(18px, bold, 1.44);

            &.targetEl {
                padding: 6px 14px;
                @include notoSans(16px, bold, 1.5);
                border-radius: 20px;
            }
        }
        // .navSlider {
        //     .navSliderNextNav {
        //         top: 52px;
        //         transform: unset;
        //     }
        //     .navSliderPrevNav {
        //         top: 52px;
        //         transform: unset;
        //     }
        // }
    }
    .collectionProducts {
        .productCardWrapper {
            width: calc(25% - 24px);
            margin-left: 24px;
            margin-top: 30px;
        }
    }

    .collectionProductsQuantity {
        @include notoSans(14px, normal, 1.38);
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .quantityAndSorting {
        margin-top: 16px;
    }
    .collectionFixedPosition {
        padding-left: 0;
    }
    .collectionFixedPosition {
        display: flex;
        justify-content: center;
    }
    // .collectionNavigationDtPosition {
    //     width: 100%;
    //     max-width: 1200px;
    //     overflow: hidden;
    // }
    .CollectionProductsNavSlider {
        .swiper-container {
            padding: 32px 0;
        }
    }

    // .scrollDown,
    // .scrollUp {
    //     // width: 100vw;
    //     // margin: 0 auto;
    //     // margin-left: -200px;
    //     padding-left: 0;
    //     border-top: none;

    //     .collectionNavigationDtPosition {
    //         width: 100%;
    //         max-width: 1200px;
    //         margin: 0 auto;
    //     }
    // }
}
