@import '../../RootVariable.scss';

.contentBox {
    background-color: $grey_1;
    // padding: 8px 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 20px;

    &.isSharpBorder {
        border-radius: 0;
    }
    &.isBottomSharp {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    &.isTopSharp {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    // &.contentBoxWithShadow {
    //     @include defaultShadow;
    // }
}
