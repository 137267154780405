@import '../../RootVariable.scss';
.homeTopTextAreaWrapper {
    width: 90%;
    height: 120spx;
    background-color: $white;
    padding: 20px;
    overflow: hidden;
}
.homeTopTextContent {
    animation-name: opacityAnimation;
    animation-duration: 1500ms;
    animation-fill-mode: forwards;
}

.homeTopText {
    @include notoSans(20px, bold, 1.3);
    text-align: center;
}
.homeTopLink {
    height: 15px;
    margin-top: 6px;
    text-align: center;
    cursor: pointer;
    @include notoSans(13px, normal, 1.23);

    &.noPointer {
        cursor: default;
    }
}

.homeTopSubText {
    text-align: center;
    @include notoSans(13px, normal, 1.23);
    color: $grey_6;
    margin-top: 7px;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
        // transform: translateX(100%);
    }

    100% {
        opacity: 1;
        // transform: translateX(0);
        // transform: rotateX(90deg);
    }
}

@media (min-width: $breakPointTbl) {
    .homeTopTextAreaWrapper {
        padding-top: 32px;
        padding-bottom: 24px;
        width: 80%;
        height: 128px;
    }

    .homeTopText {
        // @include notoSans(40px, 900, 1.4);
        // text-align: center;
    }

    .homeTopLink {
        // margin-top: 22px;

        // @include notoSans(18px, normal, 1.44);
        vertical-align: middle;

        img {
            // width: 16px;
            // height: 16px;
            // position: relative;
            // top: 1px;
        }
    }
}

@media (min-width: $breakPointDt) {
    .homeTopTextAreaWrapper {
        padding-top: 32px;
        padding-bottom: 24px;
        width: 792px;
        height: 210px;
    }

    .homeTopText {
        @include notoSans(40px, 900, 1.4);
        // text-align: center;
    }

    .homeTopLink {
        margin-top: 16px;

        @include notoSans(18px, normal, 1.44);
        vertical-align: middle;

        img {
            width: 16px;
            height: 16px;
            position: relative;
            top: 1px;
        }
    }

    .homeTopSubText {
        @include notoSans(16px, normal, 1.23);
        color: $grey_6;
    }
}
