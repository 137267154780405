@import '../../RootVariable.scss';

.CartPage.emptyCart {
    // margin-top: 100px;
}

#ch-plugin {
    opacity: 1;

    &.hide {
        opacity: 0;
    }

    &.mobileHide {
        opacity: 0;

        &.hide {
            opacity: 0 ;
        }
    }
}

.CartPageWrapper {
    //max-height: calc(100vh - 124px);
    // margin-top: 67px;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    padding-bottom: 100px;
    // padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.CartPageWrapper.fullPage {
    display: none;
}

.loginContainers {
    margin-top: 120px;
    width: 100%;
}
.pageTitle {
    padding: 24px 0;
    @include notoSans(26px, 900, 1.38);
}

.extraInfo {
    padding: 16px;
    width: 100%;
    background-color: #00c2b9;
    color: $white;
    border-radius: 16px;
    @include notoSans(14px, bold, 1.71);
    @include defaultShadow;
    margin-top: 24px;
    margin-bottom: 40px;

    .extraInfoLinkText {
        display: flex;
        align-items: center;
        @include notoSans(13px, normal, 1.23);
        margin-top: 4px;
        cursor: pointer;
    }
}

.extraInfo.noShowing {
    display: none;
}

@media (min-width: $breakPointTbl) {
    #ch-plugin {
        opacity: 1;

        &.hide {
            opacity: 0;
        }

        &.mobileHide {
            opacity: 1;
        }
    }

    .CartPage.emptyCart {
        margin: 0 0;
    }
    .CartPage {
        // width: 1200px;
        display: flex;
        margin: 0 64px;
        padding-bottom: 104px;

        .CartPageWrapper {
            width: 54.33%;
            max-height: unset;
            padding: 0;
            overflow: visible;
            margin-right: 40px;

            .extraInfo {
                // padding: 24px 32px;
                // margin-bottom: 56px;
                // border-radius: 16px;
                // @include notoSans(18px, bold, 1.44);
                margin: 0;
                margin-bottom: 24px;

                .extraInfoLinkText {
                    display: flex;
                    align-items: center;
                    // margin-top: 8px;
                    // @include notoSans(16px, normal, 1.5);
                }
            }
        }

        .CartPageWrapper.fullPage {
            display: block;
        }

        .pageTitle {
            padding: 24px 0;
            // margin: 56px 0;
            // @include notoSans(44px, 900, 1.36);
        }
    }

    .extraInfo.noShowing {
        display: none;
    }
}

@media (min-width: $breakPointDt) {
    .CartPage.emptyCart {
        max-width: 100%;
        // margin-top: 85px;
        padding: 0;
    }

    .CartPage {
        // width: 1200px;
        padding: 0 64px 200px;
        max-width: 1328px;
        display: flex;
        margin: 0 auto;

        .CartPageWrapper {
            // width: 690px;
            width: 57.5%;
            margin-right: 0;
            max-height: unset;
            padding: 0;
            overflow: visible;

            .extraInfo {
                padding: 24px 32px;
                margin-bottom: 56px;
                border-radius: 16px;
                @include notoSans(18px, bold, 1.44);

                .extraInfoLinkText {
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    @include notoSans(16px, normal, 1.5);
                }
            }
        }

        .CartPageWrapper.fullPage {
            display: block;
        }

        .pageTitle {
            padding: 0;
            margin: 56px 0;
            @include notoSans(44px, 900, 1.36);
        }
    }

    .extraInfo.noShowing {
        display: none;
    }
}
