@import '../../RootVariable.scss';

.loginPageWrapper {
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    // overflow: hidden;
    overflow-x: hidden;
    // margin-top: 56px;
    // padding-top: 24px;
    min-height: inherit;

    .loginContent {
        padding-top: 24px;
        position: unset;
        padding-left: 0;
        padding-right: 0;
        // padding-top: 0;
        border-radius: 0;
        overflow: visible;

        .closeIcon {
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
            display: none;
        }
    }

    .signupContent {

        position: unset;
        .closeIcon {
            cursor: pointer;
            position: absolute;
            top: 12px;
            right: 12px;
            display: none;
        }
    }
}

.loginContainers {
    margin-top: 120px;
    width: 100%;
}

@media (min-width: $breakPointTbl) {
    .loginPageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: $TblContentPadding;
        padding-right: $TblContentPadding;

        .loginContent {
            width: 420px;
            padding-bottom: 80px;
        }

        .loginBottomArea {
            position: relative;
            margin-top: 64px;
            border-radius: 20px;
            width: 100%;
            margin-left: 0;
            margin-right: 0;

        }
        .loginHeadText {
            text-align: center;
        }
        .loginSubText {
            text-align: center;
        }
    }
}

@media (min-width: $breakPointDt) {
    .loginPageWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        // margin-top: 85px;
        .loginContent {
            width: 500px;
            padding-bottom: 180px;
        }

        .loginContainers {
            margin-top: 180px;
            max-width: 1200px;
        }
        .loginHeadText {
            text-align: center;
            @include notoSans(24px, bold, 1.5);
        }
        .loginSubText {
            text-align: center;
            @include notoSans(16px, 500, 1.5);
        }
        .loginMethod {
            padding: 20px;
            border-radius: 36px;
        }
        .loginMethodText {
            @include notoSans(16px, bold, 1.5);
        }
        .loginLogo {
            width: 40px;
            height: 40px;
            top: 12px;
            left: 12px;
        }
    }
}
