@import '../../RootVariable.scss';

.miniCartPopup2 {
    width: 100%;
    max-height: 88px;
    bottom: 0px;
    left: 0;
    padding: 32px;
    position: fixed;
    background-color: $white;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: miniCartPopup);

    // transition: all 600ms ease;

    &.hide {
        display:none;
    }
    &.expand {
        max-height: calc(100vh - 50px);
        
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        z-index: map-get($map: $zIndex, $key: miniCartPopup) + 1;

        .closeMiniCartPopupBtn {
            display: block;
        }

        .bottom-area {
            .cta-btns2 {
                width: 100%;
                padding-bottom: 0;
                position: relative;
                height: auto;
            }
        }
        
    }

    .closeMiniCartPopupBtn {
        display: none;
    }

    .OptionTitle {
        margin-bottom: 24px;
        @include notoSans(14px, bold, 1.43);
        display: flex;
        color : #111111;

        .productImg {
            width: 40px;
            height: 40px;
            // max-width: 40px;
            // max-height: 40px;
            border-radius : 6px;
        }

        .productInfoArea {

            margin-left: 8px;
            display: flex;
            flex-direction: column;

            .productTitle {

                @include notoSans(14px, bold, 1.43);
            }

            .priceArea {
                margin-top: 6px;
                display: flex;

                .priceInfo {
                    margin-right: 8px;

                    .price {
                        display: flex;
                        @include montSerrat(14px, 700, 1.29);
                    }

                    .won {
                        @include notoSans(14px, bold, 1.29);
                    }

                    .regularPrice {

                        @include montSerrat(12px, 500, 1);
                        text-align: unset;
                    }
                }

                .discount {
                    @include montSerrat(14px, 900, 1.29);
                    color: $red;
                }
            }
        }
    }

    .optionDropdown {
        // height: 48px;
        // margin-bottom: 24px;
        background-color: $grey_1;

        .defaultTextArea {
            padding: 15px 16px;
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }

        &.isExpanded {
            background-color: $white;

            .defaultTextArea,
            .dropdownItems {
                color: $black;
                @include notoSans(13px, normal, 1.38);

                &:hover {
                    @include notoSans(13px, bold, 1.38);
                    background-color: $grey_2;
                }

            }
        }
    }

    .optionList,
    .productBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        &.margin {
            margin-bottom: 16px !important;
        }

        .optionItem {
            display: flex;
            flex-direction: column;
            padding: 16px;
            margin-bottom: 8px;
            background-color: $grey_1;
            border-radius: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            .optionItemHeadArea,
            .optionItemBodyArea {
                display: flex;
                // align-items: center;
                justify-content: space-between;
            }

            .optionItemHeadArea {
                margin-bottom: 8px;

                .imgAndName {
                    display: flex;

                    .optionImg {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                        border-radius: 6px;
                    }

                    .optionName {

                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        color: $black;
                        @include notoSans(13px, 500, 1.38);
                    }
                }

                .deleteOptionBtn {
                    height: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .priceSection {
                display: flex;

                .discountPercentage {
                    margin-right: 10px;
                    color: $red;
                    @include montSerrat(14px, 900, 1.43);
                }

                .showPrice {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;

                    .displayPrice {
                        @include montSerrat(14px, bold, 1.43);
                    }

                    .regularPrice {
                        @include montSerrat(12px, 500, 1);
                    }
                }
            }
        }
    }



    .bottom-area {

        border-top: 1px solid #ebebeb;
        padding-top: 16px;

        .total-price-area {

            align-items: center;

            .total-price-main {
                display: flex;
                justify-content: space-between;
            }

            .total-price-text {
                @include notoSans(13px, bold, 1.38);
                color: $black;
            }

            .total-price {
                @include montSerrat(14px, 700, 1.29);
                color: $black;

                .won {
                    @include notoSans(13px, bold, 1.23);
                    // background-color: $black;
                }
            }
        }

        .cta-btns2 {
            position: absolute;
            width: calc(100% - 64px);
            // left: 0px;
            bottom: 0px;
            height: 88px;
            padding: 16px 0px 24px 0px;
            margin-top: 0px;
            z-index: map-get($map: $zIndex, $key: productMiniCartBtnArea);

            display: flex;
            flex-direction: row;
            
            background-color: white;
            .cta-btn {
                width: 100%;
                margin-left: 8px;
                margin-right: 0px;

                flex: 3;
                
                &:first-child {
                    margin-left: 0px;
                }
                &.small {
                    flex: 1;
                    width: 89px;
                }

                &.disabled {
                    cursor: not-allowed;
                    background-color: #dddddd;
                }
            }
        }
    }

}

@media (min-width: $breakPointTbl) {

    .miniCartPopup2 {

        .OptionTitle {
            margin-bottom: 24px;
            @include notoSans(14px, bold, 1.43);
            display: flex;
            
            .productImg {
                width: 88px;
                height: 88px;
                // max-width: 88px;
                // max-height: 88px;
                border-radius : 10px;
            }

            .productInfoArea {

                margin-left: 8px;
                display: flex;
                flex-direction: column;

                .productTitle {

                    @include notoSans(14px, bold, 1.43);
                }

                .priceArea {
                    margin-top: 6px;
                    display: flex;

                    .priceInfo {
                        // display: flex;
                        margin-right: 8px;

                        .price {
                            display: flex;
                            @include montSerrat(14px, 700, 1.29);
                        }

                        .won {
                            @include notoSans(14px, bold, 1.29);
                        }

                        .regularPrice {

                            @include montSerrat(12px, 500, 1);
                            text-align: unset;
                        }
                    }

                    .discount {
                        @include montSerrat(14px, 900, 1.29);
                        color: $red;
                    }
                }
            }
        }

        .optionDropdown {
            // height: 48px;
            // margin-bottom: 24px;
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }

                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            max-height: 280px;
            overflow: auto;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(14px, bold, 1.43);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                        }
                    }
                }
            }
        }



        .bottom-area {

            border-top: 1px solid #ebebeb;
            padding-top: 16px;

            .total-price-area {

                // display: flex;
                // justify-content: space-between;

                .total-price-text {
                    @include notoSans(13px, bold, 1.38);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(14px, 700, 1.29);
                    color: $black;

                    .won {
                        @include notoSans(13px, bold, 1.23);
                        // background-color: $black;
                    }
                }
            }

            .cta-btns {
                margin-top: 16px;
                display: flex;
                justify-content: space-around;
                height: unset;

                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    color: $white;
                    background-color: $black;
                    border-radius: 24px;
                    @include notoSans(14px, bold, 1.43);
                    text-align: center;
                    line-height: 48px;
                    // vertical-align: middle;
                    margin-right: 8px;
                    cursor: pointer;

                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

        }
    }

}

@media (min-width: $breakPointDt) {

    .miniCartPopup2 {

        display: none;
        width: 640px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 32px;
        position: fixed;
        bottom: unset;
        border-radius: 20px;
        z-index: map-get($map: $zIndex, $key: overDimBg);

        .closeMiniCartPopupBtn {
            position: absolute;
            top: -30px;
            right: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        .OptionTitle {
            margin-bottom: 32px;
            @include notoSans(24px, bold, 1.43);

            .productImg {
                width: 88px;
                height: 88px;
                // max-width: 88px;
                // max-height: 88px;
            }

            .productInfoArea {

                margin-left: 16px;
                display: flex;
                flex-direction: column;

                .productTitle {

                    @include notoSans(24px, bold, 1.5);
                }

                .priceArea {
                    margin-top: 6px;
                    display: flex;

                    .priceInfo {
                        // display: flex;
                        margin-right: 8px;

                        .price {
                            display: flex;
                            @include montSerrat(20px, bold, 1.2);
                        }

                        .won {
                            @include notoSans(18px, bold, unset);
                        }

                        .regularPrice {
                            @include montSerrat(16px, 500, 1.13);
                            text-align: unset;
                        }
                    }

                    .discount {
                        @include montSerrat(20px, 900, 1.2);
                        color: $red;
                    }
                }
            }
        }

        .optionDropdown {
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;

                .selectedText {

                    @include notoSans(16px, normal, 1.5);
                    color: $grey_6;
                }
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }

                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            max-height: 280px;
            overflow: auto;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(16px, 900, 1.13);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(16px, bold, 1.13);
                        }

                        .regularPrice {
                            @include montSerrat(14px, 500, normal);
                        }
                    }
                }
            }
        }



        .bottom-area {

            border-top: 1px solid #ebebeb;
            padding-top: 16px;

            .total-price-area {

                // display: flex;
                // justify-content: space-between;

                .total-price-text {
                    @include notoSans(14px, bold, 1.71);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(16px, 700, 1.13);
                    color: $black;

                    .won {
                        @include notoSans(15px, bold, 1.07);
                    }
                }
            }

            .cta-btns {
                margin-top: 40px;
                display: flex;
                justify-content: space-around;
                height: unset;

                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    border-radius: 36px;
                    @include notoSans(16px, bold, 1.5);
                    line-height: 56px;
                    height: 56px;
                    margin-right: 8px;
                    cursor: pointer;

                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }

                .naverPayBtn.white {
                    padding: 15px;
                    display: flex;
                    border-radius: 32px;

                    .npay-white-btn {

                        height: 24px;
                    }
                }
            }

        }
    }

}