@import '../../RootVariable.scss';

.check-container {
  display: flex;

  .text {
    margin-left: 4px;
    // color: $main_black_2;
    // @include notoSans(300, 13px, 13px);
    @include notoSans(12px, normal, 1.38);
    color: $grey_6;

    &.clicked {
      color: $black;
    }
  }



}

@media (min-width: $breakPointDt) {

  .check-container {
    display: flex;

    .text {
      @include notoSans(14px, normal, 1.5);
      color: $grey_6;

      &.clicked {
        color: $black;
      }
    }

  }
}
