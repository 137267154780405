@import '../../RootVariable.scss';

.CollectionCardWrapper {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    position: relative;
    // width: calc(25% - 20px);
    // margin: 0 0 20px 0;
    // margin-left: 20px;
    cursor: pointer;
    text-align: center;
    width: 64px;
    height: 82px;
    margin: auto;
}
.CollectionCardImage {
    width: 100%;
}

// .makeItRectangle {
//     margin-top: 100%;
// }
.CollectionCardImgArea {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    .imgWrapper {
        position: relative;
        background-color: #f7f7f7;
        border-radius: 20px;
        margin-bottom: 4px;
    }
}

.CollectionCardBadge {
    position: absolute;
    top: 0;
    right: 0;
    height: 14px;
}
.CollectionCardText {
    // position: absolute;
    @include notoSans(11px, bold, 1.27);
    white-space: nowrap;
}

@media (min-width: 500px) {
    .CollectionCardWrapper {
        width: unset;
        height: unset;
        margin: 0;
    }
}

@media (min-width: $breakPointTbl) {
    .CollectionCardWrapper {
        border: solid 1px $grey_5;
        border-radius: 20px;
        background-color: #fff;
        transition: box-shadow 400ms;
    }

    .CollectionCardImgArea {
        .imgWrapper {
            padding-top: 20px;
            background-color: #fff;

            .CollectionCardImage {
                width: 88px;
                height: 88px;
            }
        }
    }

    .imgWrapper {
        margin-bottom: 0;
    }

    .CollectionCardWrapper:hover {
        border: solid 2px $black;
        @include defaultShadow;
        margin: -1px;
    }

    .CollectionCardImage {
        width: 45%;
    }

    .CollectionCardText {
        margin: 14px 0 16px;
        @include notoSans(13px, 500, 1.38);
    }

    .CollectionCardBadge {
        position: absolute;
        top: 8px;
        right: 8px;
        height: 20px;
    }
}

@media (min-width: $breakPointDt) {
    .CollectionCardWrapper {
        border: solid 1px $grey_5;
        border-radius: 30px;
        transition: box-shadow 400ms;
    }

    .CollectionCardWrapper:hover {
        border: solid 2px $black;
        @include defaultShadow;
        img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in;
        }
    }

    .CollectionCardImgArea {
        .imgWrapper {
            padding-top: 48px;
            background-color: #fff;
            border-radius: 30px;

            .CollectionCardImage {
                width: 160px;
                height: 160px;
            }
        }
    }

    .CollectionCardImage {
        width: 120px;
    }

    .CollectionCardText {
        margin: 16px 0 32px;
        @include notoSans(18px, bold, 1.44);
    }

    .CollectionCardBadge {
        position: absolute;
        top: 16px;
        right: 16px;
        height: 24px;
    }
}
