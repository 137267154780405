@import '../../RootVariable.scss';

.desktopNavigation {
    display: none;
}

@media (min-width: $breakPointTbl) {

    .desktopNavigation {
        position: relative;
        display: flex;
        // left: 0px;
        width: 282px;
        flex-direction: column;
        min-height:600px;

        .paddingWrapper {

            padding: 24px 40px 0 64px;
            position: sticky;
            top: 100px;
            display: flex;
            flex-direction: column;

            .userInfoArea {

                display: flex;
                flex-direction: column;
                align-items: unset;

                .userName {
                    @include notoSans(26px, 900, 1.38);
                    color: #101010;
                }

                .userBtns {

                    display: flex;
                    align-items: center;
                    margin-top: 24px;

                    .userDataUpdateBtn {
                        @include notoSans(13px, normal, unset);
                        color: #000000;
                        display: flex;
                        // align-items: flex-end;

                        margin-right: 12px;

                        .updateIcon {
                            width: 16px;
                            height: 16px;

                        }
                    }

                    .logoutBtn {
                        @include notoSans(13px, normal, unset);
                        color: $grey_6;
                    }
                }

            }

            .taps {

                margin-top: 40px;
                display: flex;
                flex-direction: column;
                align-items: unset;

                .tap {
                    @include notoSans(14px, 500, 1.43);
                    display: flex;
                    justify-content: flex-start;
                    border-bottom: unset;
                    margin-bottom: 16px;
                    color: #bbbbbb;

                    cursor: pointer;

                    &.on {
                        @include notoSans(14px, bold, 1.43);
                        color: #101010;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

        }

    }

}

@media (min-width: $breakPointDt) {

    .desktopNavigation {
        position: relative;
        padding-top: 104px;
        width: 307px;
        flex-direction: column;
        min-height:600px;

        .paddingWrapper {

            padding: unset;
            padding-right: 49px;
            position: sticky;
            top: 180px;

            display: flex;
            flex-direction: column;

            .userInfoArea {

                display: flex;
                flex-direction: column;
                align-items: unset;

                .userName {
                    @include notoSans(44px, 900, 1.36);
                    color: #000000;
                }

                .userBtns {

                    display: flex;
                    margin-top: 24px;
                    align-items: center;
                    height: 26px;

                    .userDataUpdateBtn {
                        @include notoSans(18px, 500, unset);
                        color: #101010;
                        height: 100%;

                        .updateIcon {
                            width: 22px;
                            height: 22px;

                        }
                    }

                    .logoutBtn {
                        height: 100%;
                        @include notoSans(18px, normal, unset);
                        color: $grey_6;
                    }
                }

            }

            .taps {

                margin-top: 64px;
                display: flex;
                flex-direction: column;
                align-items: unset;

                .tap {
                    @include notoSans(18px, bold, 1.44);

                    display: flex;
                    justify-content: flex-start;
                    border-bottom: unset;
                    margin-bottom: 16px;

                    &.on {
                        @include notoSans(18px, bold, 1.44);

                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

        }

    }

}