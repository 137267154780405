@import '../../RootVariable.scss';

.SocialInteractContainerWrapper {
    margin-top: 80px;

    .thumbnailPaginationWrapper {
        .swiper-container {
            border-radius: 0;
            box-shadow: none;
        }
    }

    .swiper-container {
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    }
}

.SocialInteractContainerTopArea {
    // display: flex;
    // justify-content: space-between;
    position: relative;
}

.SocialInteractMainTex {
    @include notoSans(24px, 900, 1.43);
}

.SocialInteractImg {
    width: 94px;
    position: absolute;
    right: 0;
    bottom: -10px;
}

.SocialInteractSlider {
    margin-top: 30px;
}

@media (min-width: $breakPointTbl) {
    .SocialInteractMainTex {
        // @include notoSans(40px, 900, normal);
    }
    .SocialInteractImg {
        // width: 194px;
    }
    .SocialInteractSlider {
        margin-top: 44px;
    }
}

@media (min-width: $breakPointDt) {
    .SocialInteractMainTex {
        @include notoSans(40px, 900, normal);
    }
    .SocialInteractImg {
        width: 194px;
    }
    .SocialInteractSlider {
        margin-top: 44px;
    }
}
