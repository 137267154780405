@import '../../RootVariable.scss';

.navContainerWrapper {
    margin-top: 18px;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    padding-bottom: 5px;
    display: flex;
    position: relative;
    justify-content: flex-start;
    // outline: solid 1px blue;
    // overflow: hidden;

    &.mobileHide {
        display: none;
    }

    &.hide {
        display: none !important;
    }

    .navSliderContentText {
        @include notoSans(16px, bold, 1.5);
        color: $black;
    }
    .navSliderContentWrapper {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .swiper-slide {
        width: fit-content !important;
    }

    .swiper-wrapper {
        width: fit-content !important;
        align-items: center;
    }
}
.navContainerPosition {
    width: 100%;
}

.fade {
    position: absolute;
    bottom: 0px;
    right: 0px;
    pointer-events: none;
    display: block;

    width: 100px;
    height: 100%;
    z-index: 1;
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.99) 100%
    );
}
@media (min-width: $breakPointTbl) {
    .navContainerWrapper {
        padding-left: $TblContentPadding;
        padding-right: $TblContentPadding;

        &.mobileHide {
            display: flex;
        }
    }
}

@media (min-width: $breakPointDt) {
    .navContainerPosition {
        width: 100%;
    }
    .navContainerSlider {
        display: flex;
        justify-content: center;
    }
    .navContainerWrapper {
        height: 50.5px;
        overflow: visible;
        justify-content: center;
        border-bottom: 1px solid $grey_5;
        .navSliderContentText {
            @include notoSans(18px, 500, 1.44);
        }
    }
}
