@import '../../RootVariable.scss';

.AddDogInfo {
    width: 100%;
    height: auto;
    padding: 40px 32px 40px;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: map-get($map: $zIndex, $key: overDimBg);
    overflow-x: hidden;
    overflow-y: auto;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .closePopupButton {
        position: absolute;
        top: 12px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .addDogInfoTitle {
        margin-bottom: 8px;
        @include notoSans(18px, 900, 1.44);

        &.mt {
            margin-top: 56px;
            margin-bottom: 16px;
        }
    }

    .neutralizingBtn {
        display: flex;
        align-items: center;
        margin-top: 16px;
        color: #bbbbbb;
        @include notoSans(13px, bold, 1.38);

        &:hover {
            cursor: pointer;
        }

        img {
            margin-right: 4px;
        }

        &.selected {
            color: $black;
        }
    }

    .titleContainer {
        position: relative;

        span {
            position: absolute;
            right: 0;
            bottom: 0;
            color: $grey_6;
            @include notoSans(13px, normal, 1.38);
        }
    }

    .subDesc {
        margin-bottom: 48px;
        color: $grey_6;
        @include notoSans(13px, normal, 1.38);
    }

    .inputContainer {
        position: relative;
    }

    .dogInfoInputs {
        margin-bottom: 8px;

        &.breed {
            &:focus {
                margin-bottom: 0;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            // &:focus-within + .resultSearching {
            //     display: block;
            // }
        }

        &:focus + .clearInputFieldBtn {
            opacity: 1;
        }
    }

    .clearInputFieldBtn {
        opacity: 0;
        position: absolute;
        top: 14px;
        right: 16px;

        &:hover {
            cursor: pointer;
        }
    }

    .resultSearching {
        display: none;
        max-height: 120px;
        overflow-y: auto;
        background-color: $white;
        border: 1px solid $black;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &.show {
            display: block;
        }

        .searchingItem {
            padding: 11px 16px;
            @include notoSans(13px, normal, 1.38);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .buttonGroup {
        width: 100%;
        display: flex;

        &.sex {
            .btnCardWrapper {
                display: flex;
            }
            flex-direction: column;
        }

        &.withImg {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 8px;

            .buttonItemWrapper {
                width: 100%;

                &:hover {
                    cursor: pointer;
                }
                .buttonItem {
                    width: 100%;
                    padding: 12px;

                    img {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &.mb {
            margin-bottom: 56px;
        }

        .buttonItemWrapper {
            width: 50%;
            margin-right: 8px;
            position: relative;

            &:hover {
                cursor: pointer;
            }

            input {
                opacity: 0;
                position: absolute;
            }

            .buttonItem {
                width: 100%;
                padding: 19px 17px;
                border: 1px solid $grey_5;
                border-radius: 6px;
                background-color: #fff;
                text-align: center;
                @include notoSans(13px, bold, 1.38);

                &.selected {
                    background-color: $black;
                    color: #fff;
                    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .divider {
        width: calc(100% + 64px);
        height: 10px;
        background-color: $grey_1;
        position: relative;
        left: -32px;
    }

    .saveButton {
        margin-top: 40px;
        padding: 14px 70px;
        background-color: $red;
        color: #fff;
        @include notoSans(14px, bold, 1.43);
        border-radius: 24px;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }
}

@media (min-width: $breakPointTbl) {
    .AddDogInfo {
        width: 500px;
        // height: auto;
        // max-height: calc(100vh - 100px);
        height: calc(100vh - 150px);
        position: fixed;
        top: 50%;
        left: 50%;
        border-radius: 20px;
        transform: translate(-50%, -50%);
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (min-width: $breakPointDt) {
    .AddDogInfo {
        width: 648px;
        padding: 40px 48px 48px;

        input[type='text'] {
            padding: 20px 24px;
            border-radius: 10px;
            @include notoSans(16px, normal, 1.5);
        }

        .resultSearching {
            max-height: 192px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            &.show {
                display: block;
            }

            .searchingItem {
                padding: 20px 24px;
                @include notoSans(16px, normal, 1.5);
            }
        }

        .clearInputFieldBtn {
            top: 20px;
        }

        .neutralizingBtn {
            @include notoSans(16px, 500, 1.5);
        }

        .closePopupButton {
            top: 16px;
            right: 16px;
        }

        .addDogInfoTitle {
            @include notoSans(24px, bold, 1.5);
        }

        .titleContainer {
            span {
                @include notoSans(14px, normal, 1.43);
            }
        }

        .subDesc {
            @include notoSans(16px, 500, 1.5);
        }

        .buttonGroup {
            &.withImg {
                .buttonItemWrapper {
                    .buttonItem {
                        padding: 16px 24px;

                        img {
                            width: 128px;
                            height: 84px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .buttonItemWrapper {
                .buttonItem {
                    padding: 24px 20px;
                    border-radius: 10px;
                    @include notoSans(16px, bold, 1.5);
                }
            }
        }

        .divider {
            width: calc(100% + 96px);
            height: 16px;
            left: -48px;
        }

        .saveButton {
            padding: 24px 70px;
            @include notoSans(16px, bold, 1.5);
            border-radius: 36px;
        }
    }
}
