@import '../../RootVariable.scss';

.myAccountPopup {
    width: 100%;
    height: auto;
    left:0;
    max-height: calc(100vh - 50px);
    padding: 32px;
    position: fixed;
    bottom: 0;
    background-color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: miniCartPopup);

    .outterCloseBtn {
        position: absolute;
        top: -30px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .innerCloseBtn {
        display: none;
    }

    &.customPadding {
        padding-top:0px;
        padding-bottom:0px;
    }

    &.full {
        max-height: unset;
        top: 0;
        // overflow: auto;

        padding-right: 0px;
        padding-left: 0px;

        border-top-left-radius: unset;
        border-top-right-radius: unset;

        .innerCloseBtn {
            position: absolute;
            top: 12px;
            right: 12px;
            display: unset;

            &:hover {
                cursor: pointer;
            }

        }
    }


}

@media (min-width: $breakPointTbl) {

    .myAccountPopup {
        width: 500px;
        height: auto;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 32px;
        position: fixed;
        bottom: unset;
        border-radius:20px;
        // overflow: auto;
    
        .outterCloseBtn {
            position: absolute;
            top: -30px;
            right: 12px;
    
            &:hover {
                cursor: pointer;
            }
        }

        &.full {
            max-height: 60%;
            top: 20%;
            bottom: 0;
            // overflow: auto;
            transform: translate(-50%, 0%);
            border-radius:20px;

            // .outterCloseBtn {
            //     top: 12px;
            //     right: 12px;
    
            // }

            .innerCloseBtn {
                
                display: none;
    
            }
        }

    }
    
}

@media (min-width: $breakPointDt) {

    .myAccountPopup {
        width: 500px;
        height: auto;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 32px;
        position: fixed;
        bottom: unset;
        border-radius:20px;
    
        .outterCloseBtn {
            position: absolute;
            top: -30px;
            right: 12px;
    
            &:hover {
                cursor: pointer;
            }
        }
        
    }
    
}