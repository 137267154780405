@import '../../RootVariable.scss';

.SurveyDogFoodPreference {
    padding: 32px 0;

    .titleContainer {
        display: flex;
        justify-content: space-between;

        &:hover {
            cursor: pointer;
        }

        &.opened {
            margin-bottom: 32px;
        }

        .title {
            @include notoSans(18px, 900, 1.44);
        }
    }

    .ingredientInputBox {
        margin: 32px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .ingredientType {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .iconWrapper {
            display: flex;
            align-items: center;
        }
        img {
            margin-right: 4px;
        }

        .ingredientTitle {
            @include notoSans(16px, 900, 1.5);
        }

        .selectAllButton {
            display: flex;
            align-items: center;
            color: $grey_4;
            @include notoSans(13px, bold, 1.38);

            &:hover {
                cursor: pointer;
            }

            img {
                margin-right: 4px;
            }

            &.selected {
                color: $black;
            }
        }
    }

    .inputBox {
        padding: 20px;
        margin-bottom: 16px;
        border-radius: 16px;
        background-color: $grey_2;

        .inputTitle {
            margin-bottom: 16px;
            @include notoSans(14px, bold, 1.43);
        }

        .inputContainer {
            // display: grid;
            // grid-template-columns: repeat(auto-fill);
            // grid-gap: 8px;
            // grid-auto-rows: minmax(100px, auto);
            display: flex;
            flex-wrap: wrap;

            &:hover {
                cursor: pointer;
            }

            .inputTitle {
                @include notoSans(14px, bold, 1.43);
            }

            .inputItem {
                width: auto;
                height: auto;
                padding: 8px 14px;
                margin: 4px;
                position: relative;
                border: 1px solid $grey_5;
                border-radius: 17px;
                background-color: #fff;
                white-space: nowrap;
                text-align: center;

                input {
                    position: absolute;
                    opacity: 0;
                }

                span {
                    color: $grey_6;
                    @include notoSans(13px, bold, 1.38);
                }

                &.selected {
                    background-color: $black;
                    span {
                        color: $white;
                    }
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .SurveyDogFoodPreference {
        padding: 42px 0;

        .titleContainer {
            &.opened {
                margin-bottom: 18px;
            }

            .title {
                @include notoSans(24px, bold, 1.5);
            }
        }

        .ingredientType {
            margin-bottom: 16px;

            img {
                margin-right: 8px;
            }

            .ingredientTitle {
                @include notoSans(18px, bold, 1.44);
            }

            .selectAllButton {
                @include notoSans(16px, 500, 1.5);
            }
        }

        .inputBox {
            padding: 24px 32px;

            .inputTitle {
                @include notoSans(18px, bold, 1.44);
            }

            .inputContainer {
                .inputTitle {
                    @include notoSans(18px, bold, 1.44);
                }

                .inputItem {
                    border-radius: 20px;

                    span {
                        @include notoSans(16px, bold, 1.5);
                    }
                }
            }
        }
    }
}
