@import '../../RootVariable.scss';

.cartItemsPositioningWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cartItemsContainer {
    // margin-top: 40px;
    // transition: all 500ms;
    width: 100vw;
    padding-top: 40px;
    // padding-bottom: 48px;

    &.isCartItemsOpen {
        background-color: $grey_2;
    }
}

.cartItemsContainer.isCartItemsOpen {
    padding-bottom: 48px;
}

.dropDownIcon {
    margin-left: auto;

    img {
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
    }
}

.cartItemsTopArea {
    display: flex;
    justify-content: space-between;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
}

.cartItemsTopText {
    @include notoSans(18px, 900, 1.44);
}

.cartContainerExpandButton,
.cartContainerShrinkButton {
    display: flex;
    align-items: center;
    color: $grey_6;
    @include notoSans(13px, 600, 1.38);
    cursor: pointer;
}

.cartContainerExpandButton.noshowing,
.cartContainerShrinkButton.noShowing {
    display: none;
}

.cartItemsBodyArea {
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
}
.cartItemsSummaryBox {
    margin-top: 16px;
    display: flex;
    align-items: center;
    background-color: $grey_2;
    border-radius: 16px;
    padding: 16px;
    transition: all 400ms;
    max-height: 100px;
    opacity: 1;
    visibility: visible;

    &:hover {
        cursor: pointer;
    }

    &.isCartItemsOpen {
        margin: 0;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        z-index: -1;
        opacity: 0;
    }
}
.cartItemsThumbnail {
    width: 64px;
    height: 64px;
    object-fit: contain;
    object-position: center;
    background-color: $grey_3;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
    }
}
.cartItemsSummaryText {
    @include notoSans(14px, bold, 1.43);
    margin-left: 8px;
}

.cartItemsDetail {
    min-height: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    opacity: 0;
    overflow: hidden;
    transition: all 500ms;
    &.isCartItemsOpen {
        // min-height: 500px;
        overflow: visible;
        z-index: unset;
        opacity: 1;
        height: unset;
        // height: auto;
    }
}

.cartItemCategory {
    margin-top: 32px;
    display: flex;
    align-items: center;

    .categoryLogo {
        width: 24px;
        height: 24px;
    }
}

.sub {
    margin-top: 8px;
    color: #999999;
    @include notoSans(13px, normal, 1.43);
}

.cartItemCategoryText {
    @include notoSans(16px, 900, 1.63);
    margin-left: 4px;
}

.cartItemBox {
    margin-top: 16px;
    background-color: $white;
    border-radius: 16px;
    @include defaultShadow;
    // padding: 20px 16px;
    position: relative;
}

.cartItemHeadArea {
    padding: 20px 16px;
    display: flex;
    border-bottom: 1px dashed $grey_5;

    &.dogFood {
        flex-direction: column;

        .dogFoodContent {
            display: flex;
            margin-bottom: 20px;
        }

        .changeQty {
            width: 88px;
        }
    }
}

.cartItemInfoTexts {
    flex-grow: 1;
    margin-left: 8px;
    @include notoSans(12px, normal, 1.33);

    &.baconbox {
        margin-left: 0;
    }
}

.itemName {
    width: 80%;
    margin-bottom: 6px;
    @include notoSans(13px, bold, 1.38);

    &:hover {
        cursor: pointer;
    }
}

.dogfoodOption {
    margin-bottom: 6px;
    color: #999999;
    @include notoSans(12px, normal, 1.33);
}

.itemType {
    color: $red;
    @include notoSans(12px, normal, 1.33);
}

.cartItemBodyArea {
    padding: 20px 16px;
    border-bottom: 1px solid $grey_5;
}

.optionItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    &:last-child {
        margin-bottom: 4px;
    }
}

.optionProductName {
    width: 65%;
    margin-right: 24px;
    color: $grey_6;
    @include notoSans(12px, normal, 1.33);
}

.displayPrice {
    text-align: right;
    color: $black;
    @include montSerrat(13px, bold, 1.38);
}

.regularPrice {
    text-align: right;
    color: $grey_4;
    @include montSerrat(12px, 500, 1.25);
    text-decoration: line-through;
}

.showTotalPrice {
    white-space: nowrap;

    .displayPrice {
        @include montSerrat(14px, bold, 1.29);
    }

    .regularPrice {
        @include montSerrat(12px, 500, 1.25);
    }
}

.changeQty {
    min-width: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000000;

    span {
        @include montSerrat(14px, bold, 1.29);

        &.soldout {
            color: #bbbbbb;
        }
    }

    .qtyInput {
        width: 40px;
        max-width: 100px;
        padding: 0;
        background-color: transparent;
        -webkit-appearance: textfield;
        appearance: textfield;
        @include montSerrat(14px, bold, 1.29);
        text-align: center;

        &.soldout {
            color: #bbbbbb;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            box-shadow: none;
        }
    }

    img {
        &:hover {
            cursor: pointer;
        }
    }
}

.cartItemBottomArea {
    padding: 22px 16px 23px 16px;
    display: flex;
    justify-content: space-between;

    &.lite,
    &.simple {
        justify-content: flex-end;
    }
}

.bottomAreaRightSection {
    display: flex;

    &.dogFood {
        flex-direction: column;

        .upper {
            display: flex;
            justify-content: flex-end;
        }

        .dogFoodNextPurchaseInfo {
            margin-top: 8px;
            margin-bottom: 2px;
            @include notoSans(12px, normal, 1.33);
            text-align: right;
        }

        .cancelInfo {
            color: #999999;
            @include notoSans(12px, normal, 1.33);
            text-align: right;
        }
    }

    .firstMonthFreeLabel {
        height: 20px;
        padding: 2px 4px 4px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        background-color: $red;
        color: #fff;
        border-radius: 4px;
        @include notoSans(11px, normal, 1.27);
    }
}

.changeOptionBtn {
    // width: 71px;
    // height: 28px;
    height: fit-content;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    background-color: $grey_2;
    border-radius: 16px;
    color: $grey_6;
    @include notoSans(12px, bold, 1.33);

    &:hover {
        cursor: pointer;
    }
}

//일반상품
.cartItemBox.withOption,
.cartItemBox.withoutOption,
.cartItemBox.soldout,
.cartItemBox.subItems {
    .itemDesc {
        color: $grey_6;
        @include notoSans(12px, normal, 1.33);
        display: flex;
        flex-direction: column;

        .optionName {
            margin-left: 0;

            &.baconbox {
                color: $red;
            }
        }

        .deliveryInfo {
            color: #00c2b9;
            font-weight: bold;
            margin-left: auto;
        }
    }

    .itemDesc.soldout {
        .optionName {
            margin-bottom: 4px;
        }

        .soldoutNotice {
            color: $red;
        }
    }

    .cartItemHeadArea {
        border-bottom: none;
    }

    .cartItemBottomArea {
        padding-top: 0;
        justify-content: space-between;
        align-items: center;

        .controlBox {
            display: flex;
        }

        .changeQty {
            margin-right: 8px;
        }
    }
}

.cartItemBox.soldout {
    .changeQty {
        color: $grey_4;

        .qtyBtn {
        }
    }
}

.cartItemBox.subItems {
    .cartItemHeadArea {
        display: flex;
        flex-direction: column;
        border-bottom: 1px dashed $grey_5;

        .cartItemInfo {
            display: flex;
            margin-bottom: 25px;

            &.baconbox {
                margin-bottom: 0;
            }
        }

        .cartItemQtyAndPrice {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .cartItemBodyArea {
        .itemList {
            display: flex;
            justify-content: space-between;
            margin-bottom: 13px;

            &.bundle {
                margin-bottom: 4px;
                margin-top: 13px;
            }

            &:last-child {
                margin-bottom: 4px;
            }

            .optionInfo {
                display: flex;
                flex-grow: 1;
            }

            .itemName {
                margin-bottom: 0;
            }

            .itemOptionName {
                width: 65%;
                color: $grey_6;
                @include notoSans(12px, normal, 1.33);
            }
        }
    }

    .optionThumbnail {
        width: 40px;
        height: 40px;
        border-radius: 8px;
    }

    .itemDesc {
        display: flex;
        flex-direction: column;

        .optionName {
            margin-bottom: 8px;
        }

        .deliveryInfo {
            text-align: rignt;
        }
    }

    .cartItemBottomArea {
        padding-top: 22px;
    }
}

.cartItemBoxCloseButton {
    position: absolute;
    top: 20px;
    right: 16px;
    cursor: pointer;
    // z-index: 1;
}

.cartItemsBottomArea {
    padding: 0 32px;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .clearCartItems {
        color: $grey_6;
        @include notoSans(13px, normal, 1.38);
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    .clearCartItems.noShowing {
        display: none;
    }

    .cartContainerShrinkButton {
        font-weight: normal;
    }
}

.freeItemWrapper {
    .cartItemHeadArea {
        border-bottom: none;
    }
}

.cartQtyInput {
    width: auto;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    background-color: #fff;
    @include montSerrat(14px, bold, 1.29);
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

@media (min-width: $breakPointTbl) {
    .cartItemsContainer {
        width: 100%;
        padding: 0;

        .cartItemsTopArea {
            padding: 0;
            .cartItemsTopText {
                padding: 16px 0;
            }
        }

        .dropDownIcon {
            margin-left: auto;

            &:hover {
                cursor: pointer;
            }
        }

        .cartItemsBodyArea {
            padding: 0;

            .cartItemsSummaryBox {
                max-height: 128px;
                margin-top: 0;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .cartItemsContainer.isCartItemsOpen {
        background-color: $white;

        .cartItemsSummaryBox.isCartItemsOpen {
            display: none;
        }
    }

    .cartItemBox {
        border: 1px solid $grey_5;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
    }

    .optionProductName {
        width: 200px;
    }

    .subscribtionItemWrapper,
    .normalItemWrapper,
    .freeItemWrapper {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    .cartItemBox.withOption .itemDesc .optionName,
    .cartItemBox.withoutOption .itemDesc .optionName,
    .cartItemBox.soldout .itemDesc .optionName,
    .cartItemBox.subItems .itemDesc .optionName,
    .cartItemBox.withOption .itemDesc .deliveryInfo,
    .cartItemBox.withoutOption .itemDesc .deliveryInfo,
    .cartItemBox.soldout .itemDesc .deliveryInfo,
    .cartItemBox.subItems .itemDesc .deliveryInfo,
    .cartItemBox.withOption .itemDesc.soldout .soldoutNotice,
    .cartItemBox.withoutOption .itemDesc.soldout .soldoutNotice,
    .cartItemBox.soldout .itemDesc.soldout .soldoutNotice,
    .cartItemBox.subItems .itemDesc.soldout .soldoutNotice {
        @include notoSans(12px, normal, 1.33);
    }

    .cartItemsBottomArea {
        padding: 0;
        margin-bottom: 24px;
    }

    .freeItemWrapper {
        .cartItemHeadArea {
            border-bottom: none;
        }
    }
}

//destop mediaquery
@media (min-width: $breakPointDt) {
    .cartItemsContainer {
        width: 100%;
        padding: 0;

        .cartItemsTopArea {
            padding: 0;
            .cartItemsTopText {
                padding: 24px 0;
                @include notoSans(26px, bold, 1.46);
            }
        }

        .dropDownIcon {
            margin-left: auto;

            img {
                width: 32px;
                height: 32px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .cartItemsBodyArea {
            padding: 0;

            .cartItemsSummaryBox {
                max-height: 128px;
                padding: 24px 32px;
                border-radius: 16px;

                &:hover {
                    cursor: pointer;
                }

                .cartItemsThumbnail {
                    width: 80px;
                    height: 80px;
                    border-radius: 8px;
                }

                .cartItemsSummaryText {
                    margin-left: 16px;
                    @include notoSans(18px, bold, 1.44);
                }
            }
        }
    }

    .cartItemsContainer.isCartItemsOpen {
        background-color: $white;

        .cartItemsSummaryBox.isCartItemsOpen {
            display: none;
        }
    }

    .cartItemCategory {
        margin-top: 25px;

        img {
            width: 28px;
            height: 28px;
        }
    }

    .cartItemCategoryText {
        margin-left: 8px;
        @include notoSans(18px, bold, 1.44);
    }

    .cartItemBox {
        margin-top: 21px;
        border: 1px solid $grey_5;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
    }

    .cartItemBoxCloseButton {
        width: 20px;
        height: 20px;
        top: 32px;
        right: 32px;
    }

    .cartItemHeadArea {
        padding: 32px;
    }

    .cartItemsThumbnail {
        width: 96px;
        height: 96px;
    }

    .cartItemInfoTexts {
        margin-left: 16px;
    }

    .itemName {
        margin-bottom: 8px;
        @include notoSans(16px, bold, 1.5);
    }

    .dogfoodOption {
        margin-bottom: 8px;
        @include notoSans(14px, normal, 1.43);
    }

    .itemType {
        @include notoSans(14px, normal, 1.43);
    }

    .cartItemBodyArea {
        padding: 25px 32px;
    }

    .optionProductName {
        width: 356px;
        @include notoSans(14px, normal, 1.43);
    }

    .displayPrice {
        @include montSerrat(20px, bold, 1.2);
    }

    .regularPrice {
        @include montSerrat(16px, 500, 1.13);
    }

    .optionPrice {
        .displayPrice {
            @include montSerrat(16px, bold, 1.13);
        }

        .regularPrice {
            @include montSerrat(14px, 500, 1.13);
        }
    }

    .showTotalPrice {
        .displayPrice {
            @include montSerrat(20px, bold, 1.2);
        }

        .regularPrice {
            @include montSerrat(16px, 500, 1.13);
        }
    }

    .cartItemBottomArea {
        padding: 25px 32px 39px;
    }

    .bottomAreaRightSection {
        .firstMonthFreeLabel {
            height: 22px;
            padding: 4px 6px;
            margin-right: 8px;
            display: flex;
            align-items: center;
            background-color: $red;
            color: #fff;
            border-radius: 4px;
            @include notoSans(13px, normal, 1.08);
        }

        &.dogFood {
            .dogFoodNextPurchaseInfo {
                @include notoSans(14px, normal, 1.43);
            }

            .cancelInfo {
                @include notoSans(14px, normal, 1.43);
            }
        }
    }

    .changeOptionBtn {
        width: 87px;
        height: 32px;
        padding: 6px 16px;
        border-radius: 20px;
        text-align: center;
        @include notoSans(14px, bold, 1.43);
    }

    .subscribtionItemWrapper,
    .normalItemWrapper,
    .freeItemWrapper {
        margin-bottom: 57px;

        &:last-child {
            margin-bottom: 16px;
        }
    }

    .cartItemBox.withOption .itemDesc .optionName,
    .cartItemBox.withoutOption .itemDesc .optionName,
    .cartItemBox.soldout .itemDesc .optionName,
    .cartItemBox.subItems .itemDesc .optionName {
        @include notoSans(14px, normal, 1.43);
        margin-bottom: 8px;
    }

    .cartItemBox.withOption .itemDesc .deliveryInfo,
    .cartItemBox.withoutOption .itemDesc .deliveryInfo,
    .cartItemBox.soldout .itemDesc .deliveryInfo,
    .cartItemBox.subItems .itemDesc .deliveryInfo {
        @include notoSans(14px, bold, 1.43);
    }

    .cartItemBox.withOption .cartItemBottomArea .changeQty,
    .cartItemBox.withoutOption .cartItemBottomArea .changeQty,
    .cartItemBox.soldout .cartItemBottomArea .changeQty,
    .cartItemBox.subItems .cartItemBottomArea .changeQty {
        margin-right: 20px;
    }

    .cartItemBox.withOption .itemDesc.soldout .soldoutNotice,
    .cartItemBox.withoutOption .itemDesc.soldout .soldoutNotice,
    .cartItemBox.soldout .itemDesc.soldout .soldoutNotice,
    .cartItemBox.subItems .itemDesc.soldout .soldoutNotice {
        @include notoSans(14px, normal, 1.43);
    }

    .cartItemBox.subItems {
        .cartItemBodyArea {
            padding: 15px 32px;
        }

        .optionThumbnail {
            width: 56px;
            height: 56px;
        }

        .cartItemInfoTexts {
            margin-left: 12px;

            &.baconbox {
                margin-left: 0;

                .itemOptionName {
                    @include notoSans(14px, normal, 1.43);
                }
            }
        }
    }

    .cartItemsBottomArea {
        padding: 0;
        margin-bottom: 56px;

        .clearCartItems,
        .cartContainerShrinkButton {
            @include notoSans(14px, normal, 1.43);
        }
    }

    .freeItemWrapper {
        .cartItemHeadArea {
            border-bottom: none;
        }
    }
}
