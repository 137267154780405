@import '../../../RootVariable.scss';
.StaticModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: map-get($map: $zIndex, $key: staticModal);
    // z-index: map-get($map: $zIndex, $key: header);
}
