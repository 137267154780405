@import '../../RootVariable.scss';

.EmptyCartNotice {
    width: 100%;
    overflow-x: hidden;

    .noticeSection {
        padding: 48px 32px 40px;
        margin-bottom: 40px;
        background-color: $grey_2;

        .emptyCartNoticeTitle {
            margin-bottom: 4px;
            @include notoSans(18px, bold, 1.44);
        }

        .emptyCartNoticeDesc {
            margin-bottom: 32px;
            color: $grey_4;
            @include notoSans(13px, 500, 1.38);
        }

        .emptyCartNoticeBtn {
            width: 129px;
            padding: 14px 24px;
            background-color: $red;
            border-radius: 24px;
            color: $white;
            @include notoSans(14px, bold, 1.43);
            box-shadow: 0 6px 8px 0 rgba(237, 46, 27, 0.1);
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .bestItemsList {
        padding: 0 32px;

        .listTitle {
            margin-bottom: 20px;
            @include notoSans(24px, 900, 1.42);
        }
    }

    .homeProductListsContainer {
        margin-top: 0;
    }

    .homeProductListname {
        display: none;
    }
}

@media (min-width: $breakPointTbl) {
    .EmptyCartNotice {
        .noticeSection {
            padding-left: 64px;
            padding-right: 64px;
            margin-bottom: 41px;
        }

        .bestItemsList {
            padding: 0 64px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            top: 35% !important;
        }

        .swiper-button-next {
            right: 64px !important;
        }
    }
}

@media (min-width: $breakPointDt) {
    .EmptyCartNotice {
        .noticeSection {
            width: calc(100vw + 128px);
            margin-left: -64px;
            margin-right: -64px;
            padding-top: 140px;
            padding-bottom: 80px;
            margin-bottom: 120px;
            margin: 0 auto 120px;

            .contentWrapper {
                max-width: 1368px;
                margin: 0 auto;
            }

            .emptyCartNoticeTitle {
                margin-bottom: 16px;
                @include notoSans(26px, 900, 1.46);
            }

            .emptyCartNoticeDesc {
                margin-bottom: 48px;
                @include notoSans(16px, 500, 1.5);
            }

            .emptyCartNoticeBtn {
                width: 176px;
                padding: 20px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
                text-align: center;
            }
        }

        .bestItemsList {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;

            .listTitle {
                @include notoSans(40px, 900, 1.45);
            }
        }
    }
}
