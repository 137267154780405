@import '../../RootVariable.scss';

.pc-title {
    display: none;
}
.cardArea {
    padding: 32px 32px 80px 32px;

    &.expandBg {
        height: calc(100vh - 203px);
    }

    .activeSubscriptions,
    .nonActiveSubscriptions {
        // margin-top: 12px;
        
        .textBtn {
            cursor: pointer;
            display: flex;

            @include notoSans(13px, normal, 1.23);
            color:#101010;
        }
    }

    .seeMoreBtn {

        margin-top: 16px;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 20px;
        border: solid 1px #ebebeb;

        color: $grey_6;
        @include notoSans(13px, normal, 1.38);

        cursor: pointer;
    }

    .subscriptionTaps {
        display: flex;
        padding: 0 0 24px 0;

        .tap {
            margin-right: 16px;
            @include notoSans(14px, 500, 1.43);
            cursor: pointer;
            color: #bbbbbb;

            &.on {
                @include notoSans(14px, bold, 1.43);
                color: #101010;
            }
        }
    }

    .noticeSection {
        padding-top: 24px !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}

.cardWrapper {
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    padding: 20px;

    border-radius: 16px;
    background-color: #ffffff;

    .cardInfoArea {
        cursor: pointer;
    }
    
    .cardTop {
        display: flex;
        justify-content: space-between;

        .orderStatus {
            @include notoSans(12px, normal, 1.33);

            color: $grey_6;

            &.red {
                color: $red;
            }

            &.mint {
                color: #00c2b9;
            }
        }

        .orderCardInfo {
            display: flex;
            align-items: center;

            .orderDate {
                @include montSerrat(12px, normal, 1.25);
                color: $grey_6;
                margin-right: 8px;
            }

            .line {
                width: 1px;
                height: 10px;
                background-color: #dddddd;
                margin-right: 8px;
            }

            .orderId {
                @include montSerrat(12px, normal, 1.25);
                color: $grey_6;
                cursor: pointer;
                .pathArrow {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .orderDescription {
        padding-top: 16px;
        // padding: 16px 0 24px;
        .productName {
            @include notoSans(13px, bold, 1.38);
            color: #111111;
            margin-bottom: 2px;
        }

        .price {
            @include montSerrat(14px, bold, 1.29);
            color: #101010;

            display: flex;
        }

        .details {
            margin-top: 12px;
            display: flex;
            flex-direction: column;

            .detail {
                @include notoSans(12px, normal, 1.33);
                color: $grey_6;

                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .btns {
        display: flex;
        justify-content: space-between;
        // margin-top: 24px;

        .btn {
            margin-top: 24px;

            width: 100%;
            height: 40px;
            @include notoSans(14px, 500, 1.43);
            color: #101010;
            // padding: 10px 34px 10px 35px;
            border: solid 1px #ebebeb;
            border-radius: 6px;

            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .pc-title {
        display: block;
        background-color: #ffffff;
        // align-items: center;

        padding-top: 24px;
        padding-left: $TblContentPadding;
        height: 84px;
        @include notoSans(26px, 900, 1.38);
        color: #101010;
    }

    .cardArea {
        padding: 40px $TblContentPadding 80px $TblContentPadding;

        &.expandBg {
            height: calc(100vh - 304px);
        }
    }

    .subscriptionsWrapper {
        .cardArea {
            padding-top: 36px;
        }
    }

    .cardWrapper {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        padding: 20px;

        border-radius: 16px;
        background-color: #ffffff;
        .cardTop {
            display: flex;
            justify-content: space-between;

            .orderStatus {
                @include notoSans(12px, normal, 1.33);

                color: $grey_6;

                &.red {
                    color: $red;
                }

                &.mint {
                    color: #00c2b9;
                }
            }

            .orderCardInfo {
                display: flex;
                align-items: center;

                .orderDate {
                    @include montSerrat(12px, normal, 1.25);
                    color: $grey_6;
                    margin-right: 8px;
                }

                .line {
                    width: 1px;
                    height: 10px;
                    background-color: #dddddd;
                    margin-right: 8px;
                }

                .orderId {
                    @include montSerrat(12px, normal, 1.25);
                    color: $grey_6;
                    cursor: pointer;
                }
            }
        }

        .orderDescription {
            padding: 16px 0px 0px 0px;

            .productName {
                @include notoSans(13px, bold, 1.38);
                color: #111111;
                margin-bottom: 2px;
            }

            .price {
                @include montSerrat(14px, bold, 1.29);
                color: #101010;

                display: flex;
            }
        }

        .btns {
            display: flex;
            justify-content: space-between;

            // margin-top: 24px;

            .btn {
                width: 100%;
                height: 40px;
                @include notoSans(14px, 500, 1.43);
                color: #101010;
                // padding: 10px 34px 10px 35px;
                border: solid 1px #ebebeb;
                border-radius: 6px;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .pc-title {
        padding-top: 104px;
        padding-bottom: 32px;
        padding-left: 80px;
        height: unset;
        @include notoSans(40px, 900, 1.45);
        color: #101010;
    }

    .cardArea {
        padding :64px 80px 180px 80px;

        &.expandBg {
            height: calc(100vh - 304px);
        }

        .seeMoreBtn {
            margin-top: 32px;
            
            width: 100%;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 36px;
            border: solid 1px #ebebeb;

            color: $grey_6;
            @include notoSans(15px, normal, 1.47);

            cursor: pointer;
        }

        .subscriptionTaps {
            // margin-top: 12px;
            // margin-bottom: 12px;
            display: flex;
            padding: 0 0 32px 0;

            .tap {
                margin-right: 33px;
                @include notoSans(18px, 500, 1.44);
                cursor: pointer;
                color: #bbbbbb;

                &.on {
                    @include notoSans(18px, bold, 1.44);
                    color: #101010;
                }
            }
        }

        .noticeSection {
            padding-top: 24px !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: unset !important;
        }

        .emptyCartNoticeDesc {
            margin-bottom: 48px !important;
        }
        .emptyCartNoticeBtn {
            width: 176px !important;
            height: 64px !important;
            border-radius: 36px !important;
            margin: unset;
            padding: 20px 40.9px 20px 41px !important;

            // background-color:$red !important;
        }
    }

    .subscriptionsWrapper {
        .cardArea {
            padding-top: 64px;
        }
    }

    .orders {
        padding: 40px 80px 180px 80px;

        .seeMoreBtn {
            // margin-top: 32px;
            height: 56px;
            @include notoSans(15px, normal, 1.47);
        }
    }

    .cardWrapper {

        padding: 32px 30px 32px 32px;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
        
        border-radius: 16px;
        background-color: #ffffff;
        .cardTop {
            display: flex;
            justify-content: space-between;

            .orderStatus {
                @include notoSans(14px, normal, 1.43);

                color: $grey_6;

                &.red {
                    color: $red;
                }

                &.mint {
                    color: #00c2b9;
                }
            }

            .orderCardInfo {
                display: flex;
                align-items: center;

                .orderDate {
                    @include montSerrat(14px, normal, 1.14);
                    color: $grey_6;
                    margin-right: 8px;
                }

                .line {
                    width: 1px;
                    height: 10px;
                    background-color: #dddddd;
                    margin-right: 8px;
                }

                .orderId {
                    @include montSerrat(14px, normal, 1.14);
                    color: $grey_6;
                    cursor: pointer;
                }
            }
        }

        .orderDescription {
            padding: 16px 0px 0px 0px;

            .productName {
                @include notoSans(16px, bold, 1.5);
                color: #111111;
                margin-bottom: 6px;
            }

            .price {
                @include montSerrat(20px, bold, 1.2);
                color: #101010;

                display: flex;

                .won {
                    @include notoSans(20px, bold, unset);
                    color: #101010;
                }
            }
        }

        .btns {
            display: flex;
            justify-content: space-between;

            .btn {
                width: 100%;
                height: 48px;
                @include notoSans(16px, 500, 1.5);
                color: #101010;
                border: solid 1px #ebebeb;
                border-radius: 6px;

                display: flex;
                justify-content: center;
                align-items: center;

                margin-right: 8px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
