@import '../../RootVariable.scss';

.ChangePersonalInfoPopup {
    width: 100%;
    height: auto;
    padding: 24px 32px 96px;
    position: fixed;
    bottom: 0;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: map-get($map: $zIndex, $key: overDimBg);

    .closePopupBtn {
        position: absolute;
        top: -30px;
        right: 12px;
    }

    .personalInfoDetail {
        height: auto;
        .typeInfo {
            margin-bottom: 40px;
            @include notoSans(18px, 900, 1.44);
        }

        .inputsContainer {
            .inputFieldContainer {
                position: relative;

                input {
                    margin-bottom: 8px;
                }

                .resetInputFieldBtn {
                    width: 20px;
                    height: 20px;
                    opacity: 0;
                    position: absolute;
                    top: 15px;
                    right: 16px;
                    background: url('/assets/m-btn-text-field-delete-20.svg')
                        no-repeat;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .alertMsg {
                    display: none;
                    margin-bottom: 16px;
                    color: $red;
                    @include notoSans(12px, normal, 1.33);
                }

                &:focus-within {
                    .resetInputFieldBtn {
                        opacity: 1;
                    }
                }

                &.alert {
                    input {
                        margin-bottom: 4px;

                        &::placeholder {
                            color: $red;
                        }
                    }

                    .alertMsg {
                        display: block;
                    }
                }
            }

            .zipcodeLine {
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                input {
                    margin-bottom: 0;
                    // margin-right: 8px;
                }

                .searchAddressBtn {
                    height: 48px;
                    padding: 14px 18px;
                    border: 1px solid $grey_5;
                    border-radius: 6px;
                    background-color: #fff;
                    @include notoSans(14px, 500, 1.43);
                    white-space: nowrap;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .chageInfoBtn {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;

        div {
            margin: 24px 32px;
            padding: 14px 70px;
            background-color: $red;
            border-radius: 24px;
            color: #fff;
            @include notoSans(14px, bold, 1.43);
            text-align: center;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .ChangePersonalInfoPopup {
        width: 500px;
        padding: 40px 32px 120px;
        top: 50%;
        left: 50%;
        bottom: unset;
        border-radius: 20px;
        transform: translate(-50%, -50%);

        .personalInfoDetail {
            .typeInfo {
                margin-bottom: 32px;
            }
        }

        .chageInfoBtn {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            div {
                margin: 32px 32px 40px;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .ChangePersonalInfoPopup {
        padding: 40px 48px 152px;

        .personalInfoDetail {
            .typeInfo {
                margin-bottom: 48px;
                @include notoSans(24px, bold, 1.5);
            }

            .inputsContainer {
                .inputFieldContainer {
                    input {
                        padding: 20px 24px;
                    }
                    .resetInputFieldBtn {
                        width: 24px;
                        height: 24px;
                        top: 17px;
                        right: 20px;
                        background: url('/assets/m-btn-text-field-delete-24.svg')
                            no-repeat;
                    }
                }
                .zipcodeLine {
                    .searchAddressBtn {
                        height: 64px;
                        padding: 20px 32px;
                        @include notoSans(16px, 500, 1.5);
                    }
                }
            }
        }

        .chageInfoBtn {
            div {
                padding: 20px 80px;
                margin: 32px 48px 56px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }
        }
    }
}
