@import '../../RootVariable.scss';

.loginModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    // display: none;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: opacity 500ms;
    &.logInModalVisible {
        display: flex;
        visibility: visible;
        opacity: 1;
        z-index: map-get($map: $zIndex, $key: loginModal);
    }


    .closeIcon {
        cursor: pointer;
        position: absolute;
        top: 12px;
        right: 12px;
    }
}

@media (min-width: $breakPointTbl) {
    .loginModalWrapper {
        padding-top: 100px;
        padding-bottom: 20px;
    }

}
