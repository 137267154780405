@import '../../RootVariable.scss';

.progressBarWrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.progressBar {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: rgba(16, 16, 16, 0.15);
    .progress {
        transition: width 400ms;
        background-color: $black;
        position: absolute;
        height: 2px;
        top: 0;
        left: 0;
    }
}

.progressBarIndex {
    @include montSerrat(12px, normal, 1.17);
    letter-spacing: normal;
    margin-right: 8px;
    strong {
        font-weight: bold;
    }
}

// .withProgress {
//     position: relative;
// }

@media (min-width: $breakPointDt) {
    .progressBarIndex {
        @include montSerrat(18px, normal, 1.11);
        margin-right: 23px;
        strong {
            font-weight: bold;
        }
    }

    .progressBar {
        height: 3px;
        .progress {
            height: 3px;
        }
    }
}
