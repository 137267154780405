@import '../../RootVariable.scss';


.shippingInfoForm {
    height: auto;

    .typeInfo {
        margin-bottom: 40px;
        @include notoSans(18px, 900, 1.44);
    }

    .inputsContainer {
        .inputFieldContainer {
            position: relative;

            input {
                margin-bottom: 8px;
            }

            .resetInputFieldBtn {
                width: 20px;
                height: 20px;
                opacity: 0;
                position: absolute;
                top: 15px;
                right: 16px;
                background: url('/assets/m-btn-text-field-delete-20.svg') no-repeat;

                &:hover {
                    cursor: pointer;
                }
            }

            .alertMsg {
                display: none;
                margin-bottom: 16px;
                color: $red;
                @include notoSans(12px, normal, 1.33);
            }

            &:focus-within {
                .resetInputFieldBtn {
                    opacity: 1;
                }
            }

            &.alert {
                input {
                    margin-bottom: 4px;

                    &::placeholder {
                        color: $red;
                    }
                }

                .alertMsg {
                    display: block;
                }
            }
        }

        .zipcodeLine {
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            input {
                margin-bottom: 0;
                margin-right: 8px;
            }

            .searchAddressBtn {
                height: 48px;
                padding: 14px 18px;
                border: 1px solid $grey_5;
                border-radius: 6px;
                background-color: #fff;
                @include notoSans(14px, 500, 1.43);
                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.shippingInfoFormBtns {
    width: 100%;
    background-color: #fff;

    .btn {
        margin-top: 24px;
        padding: 14px 70px;
        background-color: $red;
        border-radius: 24px;
        color: #fff;
        @include notoSans(14px, bold, 1.43);
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }
}

@media (min-width: $breakPointTbl) {

        .shippingInfoForm {
            .typeInfo {
                margin-bottom: 32px;
            }
        }

        .shippingInfoFormBtns {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;

            .btn {
                // margin-top: 24px;
                margin-top: 32px;
            }
        }
}

@media (min-width: $breakPointDt) {

        .shippingInfoForm {
            .typeInfo {
                margin-bottom: 48px;
                @include notoSans(24px, bold, 1.5);
            }

            .inputsContainer {
                .inputFieldContainer {
                    .resetInputFieldBtn {
                        width: 24px;
                        height: 24px;
                        top: 20px;
                        right: 24px;
                        background: url('/assets/m-btn-text-field-delete-24.svg') no-repeat;
                    }
                }

                .zipcodeLine {
                    .searchAddressBtn {
                        height: 64px;
                        padding: 20px 32px;
                        @include notoSans(16px, 500, 1.5);
                    }
                }
            }
        }

        .shippingInfoFormBtns {
            .btn {
                padding: 20px 80px;
                margin-top: 32px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }
        }
}