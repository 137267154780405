@import '../../RootVariable.scss';

.dropdownWrapper {
  user-select: none;
  cursor: pointer;
  border: solid 1px transparent;
  border-radius: 6px;
  transition: all 500ms;

  .dropDowns2 {
    // display: flex;
    height: 0;
    min-height: 0px;
    transition: all 500ms;
    overflow: hidden;
  }

  &.isExpanded {
    border: solid 1px $black;
    background-color: $white;

    .defaultTextArea {
      color: $black;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .dropDowns2 {
      overflow: auto;
      height: auto;
      max-height: 250px;
      // min-height: 30px;
    }

    .dropdownItems,
    .defaultTextArea {
      &:hover {
        background-color: $grey_2;
        @include notoSans(13px, bold, 1.38);

        .optionName {
          font-weight: bold;
        }
      }
    }
  }
}

.defaultTextArea {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .flex {
      display: flex;
    }

    .leftImg {
      margin-right: 8px;
      height: 24px;
    }

    .selectedText {

    }
  }
}

.miniCart-dropdownItems {
  min-height: 40px;
  padding: 11px 16px;
  color: $black;
  display: flex;
  justify-content: space-between;

  .restocking-wrapper {
    display: flex;
    align-items: center;

    .restocking-icon {
      width: 20px;
      display: flex;

      img {
        width: 16px;
        height: 16px;
        display: flex;
      }
    }

    .restocking-description {
      @include notoSans(12px, 500, normal);
      color: #00c2b9;
    }
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &.options {
    // height: 68px;
    padding: 10px 15px;
    display: flex;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }

    .optionInfo {
      display: flex;
      flex-direction: column;
      width: 100%;

      .optionName {
        margin-left: 0;
        margin-bottom: 4px;
        @include notoSans(13px, normal, 1.38);

        &.soldout {
          color: #999999;
        }

        .dropdownOptionSpan {
          color: #999999;
        }
      }

      .optionPrice {

        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 2px;

        .optionPrice-wrapper {
          display: flex;
          align-items: center;
        }

        .displayPrice.options {
          @include montSerrat(13px, bold, 1.38);

          .won {
            @include notoSans(12px, bold, 1.33);
          }

          &.soldout {
            color: $red;
            @include notoSans(12px, normal, 1.33);
          }
        }

        .regularPrice {
          margin-left: 2px;
          color: $grey_4;
          @include montSerrat(12px, 500, 1.25);
          text-decoration: line-through;
        }

        &.soldout {
          color: $red;
          @include notoSans(12px, normal, 1.33);
        }

        .discountRate {
          margin-left: 8px;

          @include montSerrat(12px, bold, 1.25);
          color: #ed2e1b;

        }
      }
    }
  }
}

.miniCart-dropdownItems,
.defaultTextArea {
  &:hover {
    background-color: $grey_2;
    @include notoSans(13px, bold, 1.38);

    .optionName {
      font-weight: bold;
    }
  }
}

@media (min-width: $breakPointTbl) {
}

@media (min-width: $breakPointDt) {
  .dropdownWrapper {
    user-select: none;
    cursor: pointer;
    border: solid 1px transparent;
    border-radius: 10px;
    transition: all 500ms;

    &.isExpanded {
      border: solid 1px $black;
      background-color: $white;

      .defaultTextArea {
        color: $black;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      .dropDowns2 {
        overflow: auto;
        height: auto;
        max-height: 250px;
        // min-height: 30px;
      }

      .miniCart-dropdownItems,
      .defaultTextArea {
        &:hover {
          background-color: $grey_2;
          @include notoSans(13px, bold, 1.38);

          .optionName {
            font-weight: bold;
          }
        }
      }
    }
  }

  .defaultTextArea {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .flex {
        display: flex;
      }

      .leftImg {
        margin-right: 8px;
        height: 24px;
      }

      .selectedText {
        @include notoSans(16px, normal, 1.5);
      }
    }
  }

  .dropDowns2 {
    // display: flex;
    height: 0;
    min-height: 0px;
    transition: all 500ms;
    overflow: hidden;
  }

  .miniCart-dropdownItems {
    min-height: 40px;
    padding: 11px 16px;
    color: $black;


    .restocking-wrapper {


      .restocking-description {
        @include notoSans(13px, 500, normal)
      }
    }

    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.options {
      // height: 68px;
      padding: 17px 24px;
      display: flex;
      align-items: center;

      img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      .optionInfo {
        display: flex;
        flex-direction: column;

        .optionName {
          margin-left: 0;
          margin-bottom: 4px;
          @include notoSans(16px, normal, 1.5);

          &.soldout {
            color: #999999;
          }

          .dropdownOptionSpan {
            color: #999999;
          }
        }

        .optionPrice {
          display: flex;
          align-items: center;
          margin-right: 2px;

          .displayPrice.options {
            @include montSerrat(16px, bold, 1.13);

            .won {
              @include notoSans(15px, bold, 1.07);
            }

            &.soldout {
              color: $red;
              @include notoSans(14px, normal, 1.43);
            }
          }

          .regularPrice {
            margin-left: 2px;
            color: $grey_4;
            @include montSerrat(14px, 500, normal);
            text-decoration: line-through;
          }

          &.soldout {
            color: $red;
            @include notoSans(16px, normal, 1.5);
          }

          .discountRate {
            margin-left: 8px;

            @include montSerrat(16px, bold, 1.13);
            color: #ed2e1b;

          }
        }
      }
    }
  }
}
