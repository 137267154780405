@import '../../RootVariable.scss';


.terms-page-wrapper{
  padding: 40px 40px;
  .AgreementBodyArea{
    overflow: visible;
    max-height: none;
  }
}


@media (min-width: $breakPointDt) {
  .terms-page-wrapper{
    padding: 120px 120px;
  }

}

