@import '../../RootVariable.scss';

.CollectionTopContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .sliderProgressPosition {
        margin-top: 14px;
    }
}

.CollectionTopTextContent {
    padding-top: 24px;
    padding-bottom: 24px;
    align-self: flex-start;
}

.CollectionTopMainText {
    @include notoSans(26px, 900, 1.38);
}

.CollectionTopSubText {
    @include notoSans(13px, 500, 1.38);
    color: $grey_4;
    margin-top: 4px;
}

.CollectionTopImageContent {
    margin-bottom: 32px;
}

.CollectionTopSlider {
    position: relative;
    align-self: flex-start;
    margin-bottom: 32px;
    width: 100%;

    .swiper-button-next,
    .swiper-button-prev {
        top: 50% !important;
    }
}
.CollectionTopImage {
    width: 100vw;
}

.CollectionTopSlideImg {
    // @include defaultShadow;
    width: 100%;
    border-radius: 10px;
}
.TopSliderCardImg {
    width: 100%;
}

.reviewCountWrapper {
    display: flex;
    align-items: center;
    @include notoSans(11px, normal, 1.27);
    margin-top: 8px;
}
.reviewStarIcon {
    width: 10px;
    margin-right: 2px;
}
.reviewNumber {
    font-weight: bold;
    margin-left: 2px;
}

.dogfoodCollecitonImgs {
    margin-bottom: 32px;
    img {
        width: 100%;

        &.mb {
            display: block;
        }

        &.pc {
            display: none;
        }

        &.click {
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .CollectionTopSlider {
        .swiper-button-next,
        .swiper-button-prev {
            top: 50% !important;
        }

        .swiper-button-next {
            right: -17px !important;
        }

        .swiper-button-prev {
            left: -17px !important;
        }
    }
    .CollectionTopImage {
        width: 100%;
        border-radius: 20px;
    }
}

@media (min-width: $breakPointDt) {
    .CollectionTopTextContent {
        padding-top: 56px;
        padding-bottom: 56px;
        align-self: flex-start;
    }

    .CollectionTopMainText {
        @include notoSans(44px, 900, 1.38);
    }

    .CollectionTopSubText {
        @include notoSans(16px, 500, 1.38);
        color: $grey_4;
        margin-top: 16px;
    }

    .CollectionTopImageContent {
        margin-bottom: 56px;
    }

    .CollectionTopImage {
        border-radius: 24px;
    }

    .CollectionTopSlider {
        // position: relative;
        // align-self: flex-start;
        margin-bottom: 56px;
        // width: 100%;

        .swiper-button-next {
            right: -35px !important;
        }

        .swiper-button-prev {
            left: -35px !important;
        }
    }
    @include setSliderNavigationSizeAndPosition(72px, 47%);

    .reviewCountWrapper {
        display: flex;
        align-items: center;
        @include notoSans(14px, normal, 1.43);
        margin-top: 8px;
        cursor: pointer;
    }
    .reviewStarIcon {
        width: 14px;
        margin-right: 2px;
    }
    .reviewNumber {
        font-weight: bold;
        margin-left: 2px;
    }

    .dogfoodCollecitonImgs {
        margin-bottom: 56px;
        img {
            &.mb {
                display: none;
            }

            &.pc {
                display: block;
            }
        }
    }
}
