@import '../../RootVariable.scss';
.sortingSelectorWrapper {
    position: relative;
    user-select: none;
}

.sortingOptions {
    // height: 0;
    max-height: 0;
    transition: all 500ms;
    overflow: hidden;
    background-color: $white;
    position: absolute;
    @include defaultShadow;
    border-radius: 6px;
    border: solid 1px $black;
    top: 100%;
    right: 0;
    z-index: 5;
    width: 120px;
    border: solid 1px transparent;

    &.open {
        border: solid 1px $black;
        // height: auto;
        max-height: 273px;
    }
}
.collectionCurSort {
    @include notoSans(13px, normal, 1.38);
    display: flex;
    align-items: center;
    cursor: pointer;
    .expandButton {
        opacity: 1;
    }
}

.collectionSort {
    width: 100%;
    text-align: center;
    padding-top: 11px;
    padding-bottom: 11px;
    @include notoSans(13px, normal, 1.38);
    cursor: pointer;
    &.focus {
        background-color: $grey_2;
        font-weight: bold;
    }
}

@media (min-width: $breakPointDt) {
    .collectionCurSort {
        @include notoSans(14px, normal, 1.38);
    }
    .collectionSort {
        @include notoSans(14px, normal, 1.38);
    }
    .sortingOptions {
        width: 140px;

        &.open {
            max-height: 287px;
        }
    }
}
