@import '../../RootVariable.scss';

.productCardWrapper {
  display: flex;
  flex-direction: column;
  color: $black;

  p {
    margin: 0;
  }
}

.productCardThumbNailArea {
  // background-color: $grey_1;
  position: relative;
  // height: fit-content;
  display: flex;
  width: 100%;
  padding-top: 100%;
  // border-radius: 10px;
  border-radius: 8px;
  background-color: $grey_1;
}

.productCardThumbNail {
  width: 100%;
  cursor: pointer;
  position: absolute;
  border-radius: 8px;
  top: 0;
  object-fit: cover;
  height: 100%;

  div {
    border-radius: 10px;
  }
}

.productCardCartIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  background: url('/assets/m-icon-gnb-btn-cart.svg') no-repeat center;
}

.productCardTitle {
  margin-top: 8px;
  @include notoSans(14px, bold, 1.29);
  // font-stretch: normal;
  // font-style: normal;e

  &.dogfood {
    font-size: 13px;
  }
}

.productCardPriceWrapper {
  display: flex;
  align-items: center;
}

.productCardMainPrice {
  margin-top: 8px;
  @include montSerrat(14px, bold, 1.29);

  &.hundred {
    color: #aaaaaa;
    text-decoration: line-through;
  }
}

.hundredDiscountedPrice {
  margin-top: 8px;
  margin-left: 3px;
  color: $red;
  @include montSerrat(16px, bold, 1.13);
}

.productCardDiscountRate {
  margin-top: 8px;
  margin-left: 4px;
  @include montSerrat(14px, bold, 1.29);
  color: $red;
}

.productCardRegularPrice {
  @include montSerrat(12px, 500, 1.25);
  color: $disable-color;
  text-decoration: line-through;
}

.outofstock {
  margin-top: 8px;
  @include notoSans(12px, 500, 1.33);
  color: $red;
  // text-decoration: line-through;
}

.productCardBadge {
  margin-top: 8px;
  padding: 2px 4px 4px;
  background-color: $grey_2;
  border-radius: 4px;
  @include notoSans(11px, normal, 1.27);
  width: fit-content;
  color: $thickGrey;
}

.productCardRank {
  position: absolute;
  top: 0;
  left: 0;
  z-index: map-get($map: $zIndex, $key: rankText);
  width: 34px;
  height: 34px;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @include montSerrat(11px, bold, normal);
  color: $white;
}

.labelForDogFood {
  width: fit-content;
  padding: 2px 4px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $black;
  color: $white;
  @include notoSans(10px, 500, 1.4);
  z-index: map-get($map: $zIndex, $key: rankText);
}

.productCardOnTheImageBadge {
  position: absolute;
  background-color: $black;
  @include notoSans(11px, 500, unset);
  color: $white;
  border-radius: 4px;
  padding: 5px 5px;
  padding-bottom: 7px;
  transform: rotate(-20deg);
  left: -10px;
  top: 40px;
  display: none;
}

.swiper-slide-active {
  .productCardOnTheImageBadge {
    display: block;
  }
}

.arrowIcon {
  width: 10px;
  height: 10px;
}

@media (min-width: $breakPointTbl) {
  .productCardCartIcon {
    &:hover {
      width: 30px;
      height: 30px;
      background: url('/assets/m-icon-gnb-btn-cart-over.svg') no-repeat center;
    }
  }
}

@media (min-width: $breakPointDt) {
  .productCardTitle {
    @include notoSans(16px, bold, 1.13);
    margin-top: 10px;

    &.dogfood {
      font-size: 16px;
    }
  }

  .outofstock {
    @include notoSans(14px, 500, 1.43);
  }

  .productCardMainPrice {
    margin-top: 10px;
    @include montSerrat(16px, bold, 1.13);
  }

  .hundredDiscountedPrice {
    margin-top: 10px;
    @include montSerrat(20px, bold, 0.9);
  }

  .productCardPriceWrapper {
    @include montSerrat(16px, bold, 1.13);
  }
  .productCardRegularPrice {
    margin-top: 4px;
    @include montSerrat(14px, 500, 1.14);
    color: $disable-color;
    text-decoration: line-through;
  }
  .productCardDiscountRate {
    margin-top: 10px;
    margin-left: 8px;
    @include montSerrat(16px, bold, 1.13);
    color: $red;
  }
  .productCardRank {
    width: 48px;
    height: 48px;
    @include montSerrat(16px, bold, 1.13);
  }

  .labelForDogFood {
    padding: 3px 6px;
    @include notoSans(13px, 500, 1.38);
  }

  .productCardThumbNailArea {
    border-radius: 16px;
  }
  .productCardBadge {
    margin-top: 16px;
    padding: 4px 6px;
    background-color: $grey_2;
    border-radius: 4px;
    @include notoSans(13px, normal, 1.08);
    width: fit-content;
    color: $thickGrey;
  }

  .productCardCartIcon {
    width: 40px;
    height: 40px;
    bottom: 12px;
    right: 12px;

    &:hover {
      width: 40px;
      height: 40px;
    }
  }
}
