@import '../../RootVariable.scss';

.headerWrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 13px 24px;
    .leftEl {
        display: flex;
        // height: 100%;
    }
    .centerEl {
        display: flex;
        // height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .rightEl {
        // height: 100%;
        display: flex;
        align-items: center;
    }
}

.appTopArea {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: map-get($map: $zIndex, $key: header);
    background-color: $white;
}

.logoInHeader {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    cursor: pointer;

    &.mobileHide {
        display: none;
    }
}
.logoInHeader:last-of-type {
    margin-right: 0;
}
.headerDtVisible {
    display: none;
}

// .backBtn {

// }

@media (min-width: $breakPointTbl) {
    .hamburger {
        display: none;
    }

    .logoInHeader {
        &.mobileHide {
            display: unset;
        }
    }

    .backBtn {
        display: none;
    }

    .textInHeader {
        color: $black;
        @include notoSans(13px, 500, 1.54);

        &.headerDtVisible {
            margin-right: 16px;

            &:hover {
                cursor: pointer;
            }
        }

        &.signup {
            position: relative;
        }
    }

    .labelForSignup {
        padding: 2px 7px 4px;
        background-color: $red;
        color: $white;
        border-radius: 12px;
        @include notoSans(11px, 500, 1.45);
        white-space: nowrap;
        position: absolute;
        top: 25px;
        left: -35%;
        text-align: center;

        &:before,
        &:after {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: transparent;
            border-bottom-color: $red;
            border-width: 3px;
            margin-left: -3px;
        }

        &:before {
            border-color: transparent;
            border-bottom-color: $red;
            border-width: 4px;
            margin-left: -4px;
        }
    }

    .headerAccount {
        margin-right: 16px;
        @include notoSans(13px, 500, 1.54);
        color: $black;

        &:hover {
            cursor: pointer;
        }

        &.headerDtVisible {
            display: flex;
            align-items: center;
        }
    }

    .headerDtVisible {
        display: block;
    }
}

@media (min-width: $breakPointDt) {
    .headerWrapper {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 90px;
        padding-right: 70px;
        padding-top: 32px;
        .leftEl {
            display: flex;
            // height: 100%;
        }
        .centerEl {
            display: flex;
            // height: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        .rightEl {
            // height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .textAccount {
        @include notoSans(14px, 500, 1.43);
        cursor: pointer;
        color: $black;
    }
    .textInHeader {
        @include notoSans(14px, 500, 1.43);
        margin-right: 16px;
        cursor: pointer;
    }

    .textInHeader:last-of-type {
        @include notoSans(14px, 500, 1.43);
        margin-right: 24px;
    }

    .headerAccount {
        display: flex;
        align-items: center;
    }
    .logoInHeader {
        width: 40px;
        height: 40px;
        margin-right: 16px;
        cursor: pointer;
    }
}
