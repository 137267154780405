@import '../../RootVariable.scss';

.orderInfo {

    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    padding-bottom: 48px;
    background-color: #ffffff;

    &.subscription {
        padding-bottom: 40px;
    }

    .topArea {

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-top: 24px;
        padding-bottom: 24px;

        // height: 84px;

        .orderStatus {
            @include notoSans(26px, 900, 1.38);
            color: $red;
            // height: 100%;
        }

        .orderEtc {

            .accountNum {
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                padding: 6px 12px;

                @include notoSans(12px, bold, 1.33);
                border-radius: 16px;
                background-color: #f4f4f4;
                color: $grey_6;

            }
        }
    }

    .OrderInfoBox {

        width: 100%;
        height: 56px;
        border-radius: 16px;
        background-color: #f4f4f4;
        padding: 17px 20px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .orderId {
            @include montSerrat(18px, bold, 1.22);
            color: #101010;

            .pathArrow {
                width: 10px;
                height: 10px;
            }
        }

        .orderDate {
            @include montSerrat(13px, 500, 1.38);
            color: $grey_6;
        }
    }

}

.subscriptionInfoBox {
    margin-top: 16px;

    padding: 20px;
    border-radius: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #ebebeb;
    background-color: #ffffff;

    .row {

        display: flex;
        
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }
        .name {
            width: 80px;
            margin-right: 8px;

            @include notoSans(13px, normal, 1.38);
            color:$grey_6;
        }

        .value {

            // width: 100%;
            @include notoSans(13px, normal, 1.38);
            color:#111111;
        }
    }
}

.subscriptionBtns {
    margin-top: 16px;

    display: flex;
    
    .subscriptionBtn {

        @include notoSans(13px, normal, 1.23);
        color: $grey_6;
        cursor: pointer;
    }

    .line {
        margin-left: 8px;
        margin-right: 8px;

        width: 1px;
        height: 10px;
        background-color: #dddddd;

    }
}  

.subscriptionChatArea {
    margin-top: 40px;

    .descText {
        @include notoSans(14px, bold, 1.43);
        color:#101010;
    }

    .btn {
        cursor: pointer;
        margin-top: 16px;

        width: 100%;
        height: 48px;
        border-radius: 24px;
        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
        background-color: #101010;

        display: flex;
        justify-content: center;
        align-items: center;
        color:#ffffff;
        @include notoSans(14px, bold, 1.43);
        
    }

}

.productContentArea {

    background-color: #f4f4f4;

    .productInfo {
     
        .orderItemsTopArea {

            display: flex;
            align-items: center;

            .orderItemsTopText {
                padding: unset;
            }

        }
    }
    
}

.orderPriceArea,
.shippingArea {

    padding: 40px 32px 40px;

    background-color: $white;

    .titleArea {
        width: 100%;
        // height: 58px;
        margin-bottom: 32px;

        display: flex;
        justify-content: space-between;

        .title {
            @include notoSans(18px, 900, 1.44);
        }

        .btn {
            height: 28px;

            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            border-radius: 16px;
            background-color: #f4f4f4;

            @include notoSans(12px, bold, 1.33);
            color: $grey_6;
        }
    }


    .priceDetailArea,
    .priceTotal,
    .priceAdditionalDetailArea {
        .row {
            display: flex;
            justify-content: space-between;

            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0px;
            }

            .keyArea {
                display: flex;
                align-items: flex-end;
                position: relative;

                .addIcon {
                    cursor: pointer;

                }

                .deliveryInfoBox {
                    width: 230px;
                    padding: 20px 16px;
                    position: absolute;
                    top: 22px;
                    left: -8%;
                    border: 1px solid $black;
                    border-radius: 6px;
                    background-color: $white;
                    color: $grey_6;
                    @include notoSans(12px, normal, 1.33);
                    @include defaultShadow;
                    z-index: 1;
            
                    &:before,
                    &:after {
                        bottom: 100%;
                        left: 22%;
                        border: solid transparent;
                        content: '';
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }
            
                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-bottom-color: #fff;
                        border-width: 5px;
                        margin-left: -5px;
                    }
            
                    &:before {
                        border-color: rgba(16, 16, 16, 0);
                        border-bottom-color: #101010;
                        border-width: 6px;
                        margin-left: -6px;
                    }
            
                    &.noShowing {
                        display: none;
                    }
                }

            }
            .name {
                @include notoSans(13px, 500, 1.38);
                color: #111111;

                &.highlight {
                    @include notoSans(14px, bold, 1.48);
                }
            }

            .value {
                display: flex;

                @include montSerrat(13px, bold, 1.39);
                color: #101010;

                .won {
                    @include notoSans(12px, bold, unset);
                    color: #101010;
                }

            }
            
        }
    }

    .priceDetailArea {
        // padding-top: 16px;
        padding-bottom: 20px;

        .question {
            margin-left: 4px;
            width: 14px;
            height: 14px;

        }
    }

    .priceAdditionalDetailArea {

        margin-left: -32px;
        margin-right: -32px;
        padding: 20px 32px;
        background-color: #f7f7f7;

        &.mini {
            padding: 0px 32px;
            margin-left: 0px;
            margin-right: 0px;
            height: 1px;
        }

        .row{
            .value, .won {
                color:$red !important;
            }
            
        }
    }

    .borderMarginTop {
        width: 100%;
        height: 1px;
        background-color: #101010;

        margin-top: 16px;

    }

    .priceTotal {

        margin-top: 20px;

        .paymentMethod {

            margin-top: -4px;
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }

    }
}

.orderDetailInfoArea {

    margin-top: 10px;

    .bottom-btns {
        padding : 40px 32px 80px;
        
    }

}
.shippingArea {

    margin-top: 10px;
    padding-bottom: 80px;
    .shippingDetailArea {
        padding: 16px 0;

        .row {
            display: flex;

            .name {
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
                width: 80px;
            }

            .value {
                // width: 100%;
                @include notoSans(13px, 500, 1.38);
                margin-left: 8px;
                color: #111111;
                max-width: calc(100% - 88px);
            }

            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
}

.relatedOrders {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 31px 32px 40px;

    background-color: #ffffff;

    &.expanded {
        padding-top: 40px;
    }
    .titleArea {

        display: flex;
        justify-content: space-between;

        .title {
            @include notoSans(18px, 900, 1.44);
        }

        .expandButton {
            transition: transform 500ms;
            transform-origin: center center;
            cursor: pointer;
            transform: rotate(180deg);
            opacity: 0.35;
            &.expanded {
                transform: rotate(0);
                opacity: 1;
            }
        }
        
    }    

    .relatedOrderCardWrapper {
        margin-top: 16px;

        .relatedOrderCard {
            cursor: pointer;
            margin-bottom: 10px;

            padding: 20px;
            border-radius: 16px;
            background-color: #f4f4f4;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:last-child {
                margin-bottom: 0px;
            }

            .status {
                color: red;
                @include notoSans(12px, normal, 1.33);
            }

            .details {
                display: flex;
                align-items: center;

                .date, .id {

                    @include montSerrat(12px, normal, 1.25);
                    color: $grey_6;
                    display: flex;
                    align-items: flex-start;

                }

                .line {
                    margin-left: 8px;
                    margin-right: 8px;

                    width: 1px;
                    height: 10px;
                    background-color: #dddddd;

                }

            }

            
        }
    }

}

.bottom-btns {
    // margin-top: 10px;
    // padding : 32px $mobileContentPadding 80px $mobileContentPadding;
    padding-top: 32px;

    background-color: #ffffff;

    display: flex;

    .bottom-btn {
        @include notoSans(13px, normal, 1.38);
        color: $grey_6;
        cursor: pointer;
        text-decoration: underline;
        margin-right: 13px;

        &:last-child {
            margin-right: 0px;
        }

    }

}

@media (min-width: $breakPointTbl) {

    .orderInfo {

        padding-left: $TblContentPadding;
        padding-right: $TblContentPadding;
        padding-bottom: 24px;
        background-color: #ffffff;
    
        &.subscription {
            padding-bottom: 40px;

        }
        .topArea {
    
            display: flex;
            justify-content: space-between;
            // align-items: baseline;
            padding-top: 24px;
            padding-bottom: 24px;
    
            // height: 84px;
    
            .orderStatus {
                @include notoSans(26px, 900, 1.38);
                color: $red;
                // height: 100%;
            }
    
            .orderEtc {
    
                .accountNum {
                    @include notoSans(13px, normal, 1.38);
                    color: $grey_6;
                }
    
                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    padding: 6px 12px;

                    cursor: pointer;
    
                    @include notoSans(12px, bold, 1.33);
                    border-radius: 16px;
                    background-color: #f4f4f4;
                    color: $grey_6;
    
                }
            }
        }
    
        .OrderInfoBox {
    
            width: 100%;
            height: 56px;
            border-radius: 16px;
            background-color: #f4f4f4;
            padding: 17px 20px;
    
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            .orderId {
                @include montSerrat(18px, bold, 1.22);
                color: #101010;
            }
    
            .orderDate {
                @include montSerrat(13px, 500, 1.38);
                color: $grey_6;
            }
        }
    
    }
    
    .productContentArea {
    
        padding : 40px $TblContentPadding 48px $TblContentPadding;
        background-color: #f4f4f4;
    }

    .subscriptionChatArea {
        // margin-top: 40px;
    
        // .descText {
        //     @include notoSans(14px, bold, 1.43);
        //     color:#101010;
        // }
    
        .btn {
            // margin-top: 16px;
    
            width: 195px;
            // height: 48px;
            // border-radius: 24px;
            // box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
            // background-color: #101010;
    
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // color:#ffffff;
            // @include notoSans(14px, bold, 1.43);
            
        }
    
    }
    
    .orderPriceArea,
    .shippingArea {
    
        padding: 40px $TblContentPadding 40px;
    
        background-color: $white;
    
        .titleArea {
            width: 100%;
            // height: 58px;
    
            display: flex;
            justify-content: space-between;
    
            .title {
                @include notoSans(18px, 900, 1.44);
            }
    
            .btn {
                height: 28px;
    
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                border-radius: 16px;
                background-color: #f4f4f4;

                cursor: pointer;
    
                @include notoSans(12px, bold, 1.33);
                color: $grey_6;
            }
        }
    
    
        .priceDetailArea,
        .priceTotal,
        .priceAdditionalDetailArea {
            .row {
                display: flex;
                justify-content: space-between;
    
                margin-bottom: 8px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
    
                .keyArea {
                    display: flex;
    
                    .addIcon {
                        cursor: pointer;
    
                    }
    
                }
                .name {
                    @include notoSans(13px, 500, 1.38);
                    color: #111111;
                }
    
                .value {
                    display: flex;
    
                    @include montSerrat(13px, bold, 1.39);
                    color: #101010;
    
                    .won {
                        @include notoSans(12px, bold, unset);
                        color: #101010;
                    }
    
                }
                
            }
        }
    
        .priceDetailArea {
            // padding-top: 16px;
            padding-bottom: 20px;
    
            .question {
                margin-left: 4px;
                width: 14px;
                height: 14px;
    
            }
        }
    
        .priceAdditionalDetailArea {
    
            // width:100vw;
            // margin-left:calc(-50vw + 50%);
            margin-left: -64px;
            margin-right: -64px;
            padding: 20px 64px;
            background-color: #f7f7f7;
    
            .row{
                .value, .won {
                    color:$red !important;
                }
                
            }
        }
    
        .priceTotal {
    
            margin-top: 20px;
    
            .paymentMethod {
    
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }
    
        }
    }
    
    .shippingArea {
        margin-top: 10px;
        padding-bottom: 24px;
        .shippingDetailArea {
            padding: 16px 0;
    
            .row {
                display: flex;
    
                .name {
                    @include notoSans(13px, normal, 1.38);
                    color: $grey_6;
                    // width: 45%;
                }
    
                .value {
                    // width: 100%;
                    @include notoSans(13px, 500, 1.38);
                    color: #111111;
                }
    
                margin-bottom: 10px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
    
    .relatedOrders {
        // margin-top: 10px;
        // margin-bottom: 10px;
        padding: 40px $TblContentPadding 40px;
    
        // background-color: #ffffff;
    
        &.expanded {
            padding-top: 40px;
        }

        .titleArea {
    
            display: flex;
            justify-content: space-between;
    
            .title {
                @include notoSans(18px, 900, 1.44);
            }
    
            .expandButton {
                transition: transform 500ms;
                transform-origin: center center;
                cursor: pointer;
                transform: rotate(180deg);
                opacity: 0.35;
                &.expanded {
                    transform: rotate(0);
                    opacity: 1;
                }
            }
            
        }    
    
        .relatedOrderCardWrapper {
            margin-top: 16px;
    
            .relatedOrderCard {
                margin-bottom: 10px;
    
                padding: 20px;
                border-radius: 16px;
                background-color: #f4f4f4;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                &:last-child {
                    margin-bottom: 0px;
                }
    
                .status {
    
                    color: red;
                    @include notoSans(12px, normal, 1.33);
                }
    
                .details {
    
                    display: flex;
                    align-items: center;
    
                    .date, .id {
    
                        @include montSerrat(12px, normal, 1.25);
                        color: $grey_6;
                        display: flex;
                        align-items: flex-start;
    
                    }
    
                    .line {
                        margin-left: 8px;
                        margin-right: 8px;
    
                        width: 1px;
                        height: 10px;
                        background-color: #dddddd;
    
                    }
    
                }
                
            }
        }
    
    }

    .orderDetailInfoArea {
    
        margin-top: 10px;

        .bottom-btns {
            padding : 40px 64px 80px;
            
        }
    }
    
    .bottom-btns {
        // margin-top: 10px;
        // padding-left: $TblContentPadding;
        padding-bottom: 80px;

        .bottom-btn {
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
            cursor: pointer;
            text-decoration: underline;
            margin-right: 13px;

            &:last-child {
                margin-right: 0px;
            }
        }
    
    }

    
}

@media (min-width: $breakPointDt) {

    .orderInfo {

        // padding : 105px 80px 32px;
        padding-top: 105px;
        padding-right: 80px;
        padding-left: 80px;
        padding-bottom: 32px;
        background-color: #ffffff;

        &.subscription {
            padding-bottom: 56px;
        }
            
        .topArea {
    
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: unset;
            padding-bottom: 24px;
    
            // height: 84px;
    
            .orderStatus {
                @include notoSans(40px, 900, 1.45);
                color: $red;
            }
    
            .orderEtc {
    
                .accountNum {
                    @include notoSans(14px, normal, 1.43);
                    color: $grey_6;
                }
    
                .btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    padding: 6px 12px;

                    cursor: pointer;
    
                    @include notoSans(14px, bold, 1.43);
                    border-radius: 16px;
                    background-color: #f4f4f4;
                    color: $grey_6;
    
                }
            }
        }
    
        .OrderInfoBox {
    
            width: 100%;
            height: 64px;
            border-radius: 16px;
            background-color: #f4f4f4;
            padding: 18px 32px;
    
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            .orderId {
                @include montSerrat(18px, bold, 1.22);
                color: #101010;

                .pathArrow {
                    width: 12px;
                    height : 12px;
                
                }
            }
    
            .orderDate {
                @include montSerrat(13px, 500, 1.38);
                color: $grey_6;
            }
        }
    
    }
    
    .subscriptionInfoBox {
        margin-top: 56px;
    
        padding: 32px;
        border-radius: 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px #ebebeb;
        background-color: #ffffff;
    
        .row {
    
            display: flex;
            margin-bottom: 10px;
    
            &:last-child {
                margin-bottom: 0px;
            }

            .name {
                width: 96px;
                margin-right: 8px;
                @include notoSans(16px, normal, 1.5);
                color:$grey_6;
            }
    
            .value {
    
                // width: 100%;
                @include notoSans(16px, normal, 1.5);
                color:#111111;
            }
        }
    }

    .subscriptionBtns {
        margin-top: 16px;
    
        display: flex;
        
        .subscriptionBtn {
    
            @include notoSans(16px, normal, 1.5);
            color: $grey_6;
        }
    
        .line {
            margin-top: 5px;
            margin-left: 12px;
            margin-right: 12px;
    
            width: 1px;
            height: 14px;
            background-color: #dddddd;
    
        }
    }  

    .subscriptionChatArea {
        margin-top: 56px;
    
        .descTexts {
            display: flex;   
        }

        .descText {
            @include notoSans(18px, bold, 1.44);
            color:#101010;
            display: flex;
            flex-direction: column;
        }
        
    
        .btn {
            margin-top: 24px;
    
            width: 248px;
            height: 64px;
            // padding:20px 40px;
            border-radius: 36px;
            box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
            background-color: #101010;
    
            display: flex;
            justify-content: center;
            align-items: center;
            color:#ffffff;
            @include notoSans(16px, bold, 1.5);
            
        }
    
    }
    
    .productContentArea {
    
        padding : 64px 80px 80px 80px;
    }
    
    .orderPriceArea,
    .shippingArea {
    
        padding: 80px 80px 80px;
        padding-bottom: unset;
        background-color: $white;
    
        .titleArea {
            width: 100%;
            margin-bottom: 24px;
    
            display: flex;
            justify-content: space-between;
    
            .title {
                @include notoSans(26px, bold, 1.46);
            }
    
            .btn {
                
                cursor: pointer;
                height: 32px;
    
                display: flex;
                justify-content: center;
                align-items: center;
    
                border-radius: 20px;
                background-color: #f4f4f4;
    
                @include notoSans(14px, bold, 1.43);
                color: $grey_6;
            }
        }
    
    
        .priceDetailArea,
        .priceTotal,
        .priceAdditionalDetailArea {
            .row {
                display: flex;
                justify-content: space-between;
    
                margin-bottom: 8px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
    
                .keyArea {
                    display: flex;
    
                    .addIcon {
                        cursor: pointer;
                    }
    
                }
                .name {
                    @include notoSans(16px, 500, 1.5);
                    color: #101010;
                }
    
                .value {
                    display: flex;
    
                    @include montSerrat(16px, bold, 1.13);
                    color: #101010;
    
                    .won {
                        @include notoSans(15px, bold, unset);
                        color: #101010;
                    }
    
                }
                
            }
        }
    
        .priceDetailArea {
            // padding-top: 16px;
            padding-bottom: 20px;
    
            .question {
                margin-left: 4px;
                width: 14px;
                height: 14px;
    
            }
        }
    
        .priceAdditionalDetailArea {
    
            margin-left: -80px;
            margin-right: -80px;
            padding: 20px 80px;
            background-color: #f7f7f7;
    
            .row{
                .value, .won {
                    color:$red !important;
                }
                
            }
        }
    
        .priceTotal {
    
            margin-top: 20px;
    
            .paymentMethod {
    
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }
    
        }
    }

    .orderPriceArea {
        padding-top: 86px;
        padding-bottom: 84px;
    }
    
    .shippingArea {
    
        margin-top: 16px;
        // margin-bottom: 56px;
        // padding-bottom: 180px;

        .shippingDetailArea {
            padding: 16px 0;
    
            .row {
                display: flex;
    
                .name {
                    @include notoSans(16px, normal, 1.5);
                    color: $grey_6;
                    margin-right: 8px;
                    // width: 45%;
                    width: 96px
                }
    
                .value {
                    // width: 100%;
                    @include notoSans(16px, 500, 1.5);
                    color: #111111;
                }
    
                margin-bottom: 10px;
    
                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .relatedOrders {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 80px $TblContentPadding 80px;
    
        // background-color: #ffffff;
    
        &.expanded {
            padding-top: 80px;
        }

        .titleArea {
    
            display: flex;
            justify-content: space-between;
    
            .title {
                @include notoSans(26px, bold, 1.46);
            }
    
            .expandButton {
                width: 40px;
                height: 40px;
                transition: transform 500ms;
                transform-origin: center center;
                cursor: pointer;
                transform: rotate(180deg);
                opacity: 0.35;
                &.expanded {
                    transform: rotate(0);
                    opacity: 1;
                }
            }
            
        }    
    
        .relatedOrderCardWrapper {
            margin-top: 16px;
    
            .relatedOrderCard {
                margin-bottom: 10px;
    
                padding: 32px;
                border-radius: 16px;
                background-color: #f4f4f4;
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                &:last-child {
                    margin-bottom: 0px;
                }
    
                .status {
                    color: $red;
                    @include notoSans(14px, normal, 1.43);
                }
    
                .details {
    
                    display: flex;
                    align-items: center;
    
                    .date, .id {
    
                        @include montSerrat(14px, normal, 1.14);
                        color: $grey_6;
                        display: flex;
                        align-items: flex-start;
    
                    }
    
                    .line {
                        margin-left: 8px;
                        margin-right: 8px;
    
                        width: 1px;
                        height: 10px;
                        background-color: #dddddd;
    
                    }
    
                }
                
            }
        }
    
    }
    
    .orderDetailInfoArea {
    
        margin-top: 10px;

        .bottom-btns {
            padding : 56px 64px 180px;
            
        }
    }
    .bottom-btns {
        padding-bottom: 180px;
    
        .bottom-btn {
            @include notoSans(14px, normal, 1.43);
            margin-right: 24px;

            &:last-child {
                margin-right: 0px;
            }
        }
    
    }
    
}