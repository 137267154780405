@import '../../RootVariable.scss';

.ErrorNotice {
    padding: 120px 32px 97px;
    height: 100vh;
    background-color: $grey_2;
    text-align: center;

    img {
        margin-bottom: 20px;
    }

    .errorMsg {
        margin-bottom: 6px;
        @include notoSans(26px, 900, 1.38);
    }

    .errorMsgDetail {
        margin-bottom: 28px;
        color: $grey_6;
        @include notoSans(13px, 500, 1.38);
    }

    .moveToHomeBtn {
        padding: 14px 24px;
        margin-bottom: 38px;
        background-color: $red;
        color: $white;
        border: none;
        border-radius: 24px;
        @include notoSans(14px, bold, 1.43);

        &:hover {
            cursor: pointer;
        }
    }

    .BtnGroup {
        color: $grey_3;

        button {
            background-color: transparent;
            border: none;
            color: $black;
            @include notoSans(13px, normal, 1.38);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .ErrorNotice {
        padding: 160px 0 200px;

        .dtLayout {
            width: 1200px;
            margin: 0 auto;
        }

        img {
            margin-bottom: 56px;
        }

        .errorMsg {
            margin-bottom: 16px;
            @include notoSans(44px, 900, 1.36);
        }

        .errorMsgDetail {
            margin-bottom: 72px;
            @include notoSans(16px, 500, 1.5);
        }

        .moveToHomeBtn {
            padding: 20px 60px;
            margin-bottom: 32px;
            border-radius: 36px;
            @include notoSans(16px, bold, 1.5);
        }

        .BtnGroup {
            button {
                @include notoSans(16px, normal, 1.5);
            }
        }
    }
}
