@import '../../RootVariable.scss';

.BBOptionSelectorWrapper {
    padding-top: 40px;
    padding-left: $mobileContentPadding;
    padding-right: $mobileContentPadding;
    max-height: calc(100vh - 124px);
    // overflow-y: scroll;
    overflow-x: hidden;
}
.BBOptionTitle {
    @include notoSans(18px, 900, 1.44);
}

.BBOptionDesc {
    margin-top: 40px;
    @include notoSans(14px, 500, 1.44);
}

.BBOptions {
    margin-top: 16px;
    margin-bottom: 80px;
}

@media (min-width: $breakPointTbl) {
    .BBOptionSelectorWrapper {
        overflow-y: unset;
    }
}

@media (min-width: $breakPointDt) {
    .BBOptionTitle {
        @include notoSans(24px, 900, 1.44);
    }

    .BBOptionDesc {
        margin-top: 48px;
        @include notoSans(16px, 500, 1.44);
    }
}
