@import '../../RootVariable.scss';

.pcOnly {
    display: none;
}

.pcHide {
    .highlightBox {
        margin-bottom: 16px;
    }
}

.underlineTextBtn {
    cursor: pointer;
    text-decoration: underline;
}

.highlightBox {
    margin-top: 16px;
    padding: 12px;
    border-radius: 6px;
    border: solid 1px #ed2e1b;
    background-color: #fff;
    color: #ed2e1b;
    @include notoSans(12px, normal, 1.33);
    &.clickable {
        cursor: pointer;
    }
}

.closeMiniCartPopupBtn {
    position: absolute;
    top: -30px;
    right: 12px;

    &:hover {
        cursor: pointer;
    }
}

.miniCartTopArea {
    max-height: calc(100vh - 250px);
    overflow: auto;
}

.OptionTitle {
    margin-bottom: 24px;
    @include notoSans(14px, bold, 1.43);
}

.optionDropdown {
    // height: 48px;
    // margin-bottom: 24px;
    background-color: $grey_1;

    margin-bottom: 8px;

    &:last-child {
        margin-bottom: unset;
    }

    .defaultTextArea {
        padding: 15px 16px;
        @include notoSans(13px, normal, 1.38);
        color: $grey_6;
    }

    &.isExpanded {
        background-color: $white;

        .defaultTextArea,
        .dropdownItems {
            color: $black;
            @include notoSans(13px, normal, 1.38);

            &:hover {
                @include notoSans(13px, bold, 1.38);
                background-color: $grey_2;
            }
        }
    }
}

.optionList,
.productBox {
    display: flex;
    flex-direction: column;
    margin-bottom: unset !important;
    margin-top: 16px;

    .optionItem {
        display: flex;
        flex-direction: column;
        padding: 16px;
        margin-bottom: 8px;
        background-color: $grey_1;
        border-radius: 6px;

        &:last-child {
            margin-bottom: 0;
        }

        .optionItemHeadArea,
        .optionItemBodyArea {
            display: flex;
            // align-items: center;
            justify-content: space-between;
        }

        .bundleOptionProductArea {
            margin-top: 16px;

            .bundle-optionItemBox {
                display: flex;
                background-color: #ffffff;
                padding: 16px;

                margin-top: 8px;
                border-radius: 6px;

                &:first-child {
                    margin-top: 0px;
                }

                .imgArea {
                    margin-right: 8px;

                    .optionImg {
                        width: 40px;
                        height: 40px;
                        // width: 100%;
                        // height: 100%;
                    }
                }

                .infoArea {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .nameAndQty {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .bundle-optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }

                        .qtyArea {
                            @include notoSans(12px, normal, 1.33);
                            color: $grey_6;
                        }
                    }

                    .rightArea {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-end;

                        .deleteOptionBtn {
                            height: 16px;

                            &:hover {
                                cursor: pointer;
                            }
                        }

                        .bundle-optionPrice {
                            @include montSerrat(13px, bold, 1.38);
                            color: #101010;

                            .won {
                                @include notoSans(12px, bold, 1.33);
                                color: #101010;
                            }
                        }
                    }
                }
            }
        }

        .optionItemHeadArea {
            margin-bottom: 8px;

            .imgAndName {
                display: flex;

                .optionImg {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                    border-radius: 6px;
                }

                .optionName {
                    color: $black;
                    @include notoSans(13px, 500, 1.38);

                    .optionNameDesc {
                        margin-top: 2px;

                        @include notoSans(13px, 500, 1.29);
                        color: $grey_6;
                    }
                }
            }

            .deleteOptionBtn {
                height: 16px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .priceSection {
            display: flex;

            .discountPercentage {
                margin-right: 10px;
                color: $red;
                @include montSerrat(14px, 900, 1.43);
            }

            .showPrice {
                display: flex;
                flex-direction: column;
                // align-items: center;

                .displayPrice {
                    @include montSerrat(14px, bold, 1.43);
                }

                .regularPrice {
                    @include montSerrat(12px, 500, 1);
                }
            }
        }
    }
}

.bottom-area {
    margin-top: 16px;
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
    
    // margin-bottom: 16px;

    .total-price-area {
        
        .total-price-main {
            display: flex;
            justify-content: space-between;
        }
        
        .total-price-text {
            @include notoSans(13px, bold, auto);
            color: $black;
        }

        .total-price {
            @include montSerrat(14px, 700, 1.29);
            color: $black;

            display: flex;
            align-items: center;

            .won {
                @include notoSans(13px, bold, auto);
                // background-color: $black;
            }
        }
    }

    .priceAddtionalArea {
        text-align: end;

        margin-top:5px;

        .priceAddtionalText {
            @include notoSans(12px, normal, 1.33);
            color:#666666;
        }
    }

    .loginPromotionText {
        @include notoSans(13px, normal, 1.38);
        color: $grey_6;
        text-align: end;
    }

    .cta-btns2 {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // height: 48px;

        .btnRow {
            display: flex;
            margin-bottom: 16px;

            // &.pcOnly {
            //     display: none;
            // }

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .cta-btn {
            // padding: 14px 71px;
            width: 99%;
            color: $white;
            background-color: $black;
            border-radius: 24px;
            @include notoSans(14px, bold, 1.43);
            text-align: center;
            margin-right: 8px;
            cursor: pointer;
            padding: 14px 0;

            display: flex;
            justify-content: center;

            &.red {
                background-color: $red;
            }

            &.white {
                // background-color: white;
                border: solid 1px #ebebeb !important;
                background-color: #ffffff !important;
            }

            &.small {
                width: 40%;
            }

            &.disabled {
                cursor: not-allowed;
                background-color: #dddddd;
            }

            //마지막은 margin-right x
            &:last-child {
                margin-right: 0;
            }
        }

        .naverPayBtn {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .naverPayBtnImg {
                height: 20px;
            }
        }

        .naverPayBtn.white {
            padding: 13px;
            display: flex;

            .npay-white-btn {
                height: 20px;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    // .pcHide {
    //     // display: none;
    // }

    .optionImg {
        width: 48px;
        height: 48px;
    }

    .miniCartTopArea {
        max-height: calc(100vh - 350px);
        overflow: auto;
    }
}

@media (min-width: $breakPointDt) {
    .pcHide {
        display: none;
    }

    .pcOnly {
        display: unset;

        .highlightBox {
            margin-top: -32px;
            margin-bottom: 48px;
            padding: 12px;
            border-radius: 6px;
            border: solid 1px #ed2e1b;
            background-color: #fff;
            @include notoSans(13px, normal, 1.54);
            color: #ed2e1b;
        }
    }

    .optionDropdown {
        .defaultTextArea {
            padding: 20px 24px;
            @include notoSans(16px, normal, 1.5);

            .expandButton {
                width: 24px;
                height: 24px;
            }
        }
    }

    .optionList,
    .productBox {
        margin-top: 16px;

        .optionItem {
            padding: 16px 24px;
            margin-bottom: 8px;
            border-radius: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            .optionItemHeadArea,
            .optionItemBodyArea {
                display: flex;
                // align-items: center;
                justify-content: space-between;
            }

            .bundleOptionProductArea {
                margin-top: 16px;

                .bundle-optionItemBox {
                    height: 80px;
                    padding: 16px;

                    margin-top: 8px;
                    border-radius: 10px;

                    &:first-child {
                        margin-top: 0px;
                    }

                    .imgArea {
                        margin-right: 8px;

                        .optionImg {
                            width: 48px;
                            height: 48px;
                        }
                    }

                    .infoArea {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .nameAndQty {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .bundle-optionName {
                                color: $black;
                                @include notoSans(14px, 500, 1.43);
                            }

                            .qtyArea {
                                @include notoSans(14px, normal, 1.43);
                                color: $grey_6;
                            }
                        }

                        .rightArea {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;

                            .deleteOptionBtn {
                                height: 20px;

                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            .bundle-optionPrice {
                                @include montSerrat(14px, bold, 1.14);
                                // color: #101010;

                                .won {
                                    @include notoSans(13px, bold, 1.23);
                                    // color: #101010;
                                }
                            }
                        }
                    }
                }
            }

            .optionItemHeadArea {
                margin-bottom: 8px;

                .imgAndName {
                    display: flex;

                    .optionImg {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                        border-radius: 6px;
                    }

                    .optionName {
                        color: $black;
                        @include notoSans(16px, 500, 1.5);

                        .optionNameDesc {
                            margin-top: 4px;

                            @include notoSans(14px, 500, 1.29);
                            color: $grey_6;
                        }
                    }
                }

                .deleteOptionBtn {
                    height: 20px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .priceSection {
                display: flex;

                .discountPercentage {
                    margin-right: 10px;
                    color: $red;
                    @include montSerrat(16px, bold, 1.13);
                }

                .showPrice {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;

                    .displayPrice {
                        @include montSerrat(16px, bold, 1.13);
                    }

                    .regularPrice {
                        @include montSerrat(14px, 500, normal);
                    }
                }
            }
        }
    }

    .bottom-area {

        .priceAddtionalArea {
            text-align: end;
            margin-top: 8px;
    
            .priceAddtionalText {
                @include notoSans(13px, normal, 1.38);
                color:#666666;
            }
        }

        .total-price-area {
            margin-bottom: 48px;

            .total-price-text {
                @include notoSans(14px, bold, 1.71);
            }

            .total-price {
                @include montSerrat(16px, bold, 1.13);

                .won {
                    @include notoSans(15px, bold, 1.07);
                }
            }
        }

        .cta-btns2 {
            .cta-btn {
                padding: 16px 0;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }

            .btnRow {
                &.pcOnly {
                    display: flex !important;
                }
            }
        }
    }
}
