@import '../../RootVariable.scss';
.CommonPopupContainer {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: map-get($map: $zIndex, $key: commonPopup) - 1;

    .CommonPopup {
        width: 100%;
        height: auto;
        max-height: calc(100vh - 50px);
        position: fixed;
        bottom: 0;
        background-color: $white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
        z-index: map-get($map: $zIndex, $key: commonPopup);

        .commonPopupCloseIcon {
            position: absolute;
            top: -30px;
            right: 12px;

            &:hover {
                cursor: pointer;
            }
        }
        .commonPopupWrapper {
            height: auto;
            max-height: calc(100vh - 50px);
            padding: 40px 32px 60px;
            // padding-bottom: 127px;
            // overflow: scroll;
            position: static;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            text-align: center;

            .title {
                color: $black;
                @include notoSans(16px, 900, 1.5);
                margin-bottom: 10px;
            }
            .content {
                color: #999999;
                @include notoSans(13px, normal, 1.38);
            }
        }

        .commonPopupBtnWrapper {
            padding: 0 32px 24px;
            display: flex;
            align-items: center;
            position: relative;

            .btn {
                padding: 14px 20px;
                margin-right: 8px;
                white-space: nowrap;
                color: $white;
                @include notoSans(14px, bold, 1.43);
                border-radius: 24px;
                text-align: center;
                flex-grow: 1;
                box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);

                &:hover {
                    cursor: pointer;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .btnExtraInfo {
                padding: 4px 10px;
                position: absolute;
                @include notoSans(11px, 500, 1.45);
                background-color: #ebebeb;
                border-radius: 14px;
                top: -36px;
                right: 32px;
                white-space: nowrap;

                &:after {
                    position: absolute;
                    content: '';
                    top: 100%;
                    left: 80%;
                    border-top: 8px solid #ebebeb;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 0px solid transparent;
                    transform: translateX(-80%);
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .CommonPopupContainer {
        .CommonPopup {
            width: 640px;
            height: auto;
            max-height: calc(100vh - 200px);
            top: 50%;
            left: 50%;
            bottom: unset;
            transform: translate(-50%, -50%);
            border-radius: 20px;

            .commonPopupCloseIcon {
                position: absolute;
                top: -40px;
                right: 0;
                width: 40px;
                height: 40px;

                &:hover {
                    cursor: pointer;
                }
            }

            .commonPopupWrapper {
                max-height: calc(100vh - 200px);
                // padding: 40px 48px 0;
                // padding-bottom: 190px;
                // border-radius: 20px;
                // overflow: unset;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */

                &::-webkit-scrollbar {
                    display: none;
                }

                .title {
                    @include notoSans(18px, 900, 1.44);
                    margin-bottom: 4px;
                }
            }

            .commonPopupBtnWrapper {
                padding: 0 32px 40px;
            }
        }
    }
}

@media (min-width: $breakPointDt) {
    .CommonPopupContainer {
        .CommonPopup {
            .commonPopupWrapper {
                padding: 40px 48px 74px;

                .title {
                    @include notoSans(24px, bold, 1.5);
                    margin-bottom: 8px;
                }

                .content {
                    @include notoSans(16px, 500, 1.5);
                }
            }

            .commonPopupBtnWrapper {
                padding: 0 48px 48px;

                .btn {
                    padding: 20px;
                    @include notoSans(16px, bold, 1.5);
                    border-radius: 36px;
                }

                .btnExtraInfo {
                    padding: 6px 12px 4px;
                    @include notoSans(13px, 500, 1.38);
                    top: -42px;
                    right: 48px;
                }
            }
        }
    }
}
