@import '../../RootVariable.scss';

.CompleteOrderContainer {
    width: 100%;
    padding-bottom: 80px;
    margin-top: 48px;

    .buttonContainers {
        width: 100%;
        padding: 0 32px;
        display: flex;
        justify-content: space-between;

        div {
            width: 50%;
            padding: 14px 20px;
            border-radius: 24px;
            background-color: $red;
            color: #fff;
            @include notoSans(14px, bold, 1.43);
            text-align: center;

            &:first-child {
                margin-right: 8px;
                background-color: #101010;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .CompleteOrderContainer {
        padding-bottom: 120px;

        .buttonContainers {
            padding: 0 120px;
        }
    }
}

@media (min-width: $breakPointDt) {
    .CompleteOrderContainer {
        .buttonContainers {
            max-width: 588px;
            padding: 0;
            margin: 0 auto;

            div {
                padding: 20px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);

                &:first-child {
                    margin-right: 24px;
                }
            }
        }
    }
}
