@import '../../RootVariable.scss';
.homeProductListsContainer {
    margin-top: 80px;
    .homeProductListsSlider {
        .navSliderContentText {
            @include notoSans(14px, bold, 1.5);
            user-select: none;
            color: $grey_4;
            cursor: pointer;
        }
        .swiper-slide {
            width: fit-content !important;
        }

        .swiper-wrapper {
            width: fit-content !important;
        }

        .targetEl {
            color: $black;
        }
    }
    .homeProductListsProductsSlider {
        .defaultPagenation {
            display: flex;
            justify-content: center;
        }

        &.fix {
            .swiper-button-next,
            .swiper-button-prev {
                margin-top: 0;
            }

            .swiper-container {
                overflow: visible;
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        visibility: hidden;
    }
}

.homeProductListsSlider {
    margin-top: 8px;
}

.homeProductListsProductsSlider {
    margin-top: 8px;
}
.homeProductListTitle {
    @include notoSans(24px, 900, 1.42);
    margin-bottom: 0;
    margin-top: 0;
}

.homeProductListsButton {
    margin-top: 24px;
    width: 100%;
    height: 40px;
    background-color: transparent;
    border: solid 1px $grey_5;
    @include notoSans(13px, normal, 1.38);
    color: $grey_6;
    cursor: pointer;

    &.noShow {
        display: none !important;
    }

    &:hover {
        border-color: $black;
        color: $black;
        transition: all 0.3s ease-in;
    }
}
.homeProductListname {
    @include notoSans(24px, 900, 1.42);

    &.withoutCategory {
        margin-bottom: 24px;
    }
}
@media (min-width: $breakPointTbl) {
    @include setSliderNavigationSizeAndPosition(32px, 33%);
    .homeProductListsButton {
        width: 344px;
        margin-left: auto;
        margin-right: auto;

        &:hover {
            width: 360px;
        }
    }

    .homeProductListsContainer {
        .swiper-button-next,
        .swiper-button-prev {
            top: 33% !important;
        }

        .swiper-button-next {
            right: -17px !important;
        }

        .swiper-button-prev {
            left: -17px !important;
        }

        .homeProductListsProductsSlider {
            &.fix {
                .swiper-container {
                    overflow: hidden;
                }
            }
        }
    }
}
@media (min-width: $breakPointDt) {
    .homeProductListsContainer {
        .navSliderContentWrapper {
            margin-top: 32px;
            margin-bottom: 32px;
        }
        .homeProductListsSlider {
            .navSliderContentText {
                @include notoSans(18px, bold, normal);
                user-select: none;
                color: $grey_4;
                cursor: pointer;
            }
            .targetEl {
                color: $black;
            }
        }

        .homeProductListname {
            @include notoSans(40px, 900, normal);

            &.withoutCategory {
                margin-bottom: 56px;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 56px !important;
            height: 56px !important;
            top: 35% !important;
            position: absolute;
            visibility: visible;
            // display: block;
        }

        .swiper-button-next {
            right: -30px !important;
        }

        .swiper-button-prev {
            left: -30px !important;
        }

        .swiper-button-next::after {
            width: 56px;
            height: 56px;
            display: block;
        }
        .swiper-button-next:hover::after {
            width: 56px;
            height: 56px;
            display: block;
        }

        .swiper-button-prev::after {
            width: 56px;
            height: 56px;
            display: block;
        }

        .swiper-button-prev:hover::after {
            width: 56px;
            height: 56px;
            display: block;
        }

        .swiper-button-prev.swiper-button-disabled::after {
            width: 56px;
            height: 56px;
            display: block;
        }
        .swiper-button-next.swiper-button-disabled::after {
            width: 56px;
            height: 56px;
            display: block;
        }

        .homeProductListsProductsSlider {
            .defaultPagenation {
                display: flex;
                justify-content: flex-end;
                // position: relative;
                // top: 10px;
                margin-top: 62px;
            }

            &.fix {
                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }
        }
    }
    .homeProductListTitle {
        @include notoSans(40px, 900, normal);
    }
    .homeProductListsSlider {
        margin-top: 0;
    }
    .homeProductListsProductsSlider {
        margin-top: 0;
    }

    .homeProductListsButton {
        margin-top: 0;
        margin-left: unset;
        margin-right: unset;
        width: 176px;
        height: 56px;
        background-color: transparent;
        border: solid 1px $grey_5;
        @include notoSans(15px, normal, 1.38);
        color: $grey_6;
        cursor: pointer;
        position: relative;
        margin-top: 40px;
        // top: -36px;

        &:hover {
            width: 192px;
        }
    }
}
