@import '../../RootVariable.scss';

.HomeServiceSummaryContainer {
    margin-top: 80px;
}

@media (min-width: $breakPointTbl) {
    .HomeServiceSummaryContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: $breakPointDt) {
    .HomeServiceSummaryContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
