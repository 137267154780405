@import '../../RootVariable.scss';

.EmptyOrderNotice {
    width: 100%;
    margin-bottom: 80px;

    .noticeSection {
        padding: 48px 32px 40px;
        margin-bottom: 40px;
        background-color: $grey_2;

        .emptyMyAccountNoticeTitle {
            margin-bottom: 4px;
            @include notoSans(18px, 900, 1.44);
        }

        .emptyCartNoticeDesc {
            margin-bottom: 32px;
            color: $grey_4;
            @include notoSans(13px, 500, 1.38);
        }

        .emptyCartNoticeBtn {
            text-align: center;
            width: 129px;
            padding: 14px 24px;
            background-color: $red;
            border-radius: 24px;
            color: $white;
            @include notoSans(14px, bold, 1.43);
            box-shadow: 0 6px 8px 0 rgba(237, 46, 27, 0.1);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .bestItemsList {
        padding-left: 32px;

        .listTitle {
            @include notoSans(24px, 900, 1.42);
        }
    }

    .homeProductListsContainer {
        margin-top: 0;
    }

    .homeProductListname {
        display: none;
    }

    .swiper-container {
        overflow: visible !important;
    }

    .homeProductListsProductsSlider {
        padding-right: 32px !important;
    }
    
}

@media (min-width: $breakPointTbl) {
    .EmptyOrderNotice {
        margin-bottom: 0px;
        min-height: 100vh;
        .noticeSection {
            padding-left: 64px;
            padding-right: 64px;
            margin-bottom: 41px;

        }

        .bestItemsList {
            padding-left: 64px;
        }
    }
}

@media (min-width: $breakPointDt) {
    .EmptyOrderNotice {
        .noticeSection {
            padding-top: 103px;
            padding-bottom: 112px;
            margin-bottom: 120px;
            text-align: unset;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;

            .emptyMyAccountNoticeTitle {
                margin-bottom: 16px;
                @include notoSans(26px, 900, 1.46);
            }

            .emptyCartNoticeDesc {
                margin-bottom: 72px;
                @include notoSans(16px, 500, 1.5);
            }

            .emptyCartNoticeBtn {
                width: 176px;
                // margin: 0 auto;
                padding: 20px;
                border-radius: 36px;
                @include notoSans(16px, bold, 1.5);
            }
        }

        .bestItemsList {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0;

            .listTitle {
                @include notoSans(40px, 900, 1.45);
            }
        }
    }
}
