@import '../../RootVariable.scss';

.FollowPopup {
    width: 100%;
    height: auto;
    left: 0;
    max-height: calc(100vh - 50px);
    padding: 32px;
    position: fixed;
    bottom: 0;
    background-color: $white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
    z-index: map-get($map: $zIndex, $key: CartAddCompletePopup);

    .closeOptionPopupBtn {
        position: absolute;
        top: -30px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .topArea {
        max-height: calc(100vh - 250px);
        overflow: auto;

        .headText {
            margin-bottom: 14px;
            @include notoSans(18px, 900, 1.44);
            color: #101010;
        }

        .descriptionText {
            margin-bottom: 16px;
            @include notoSans(14px, 500, 1.43);
            color: $red;
        }

        .slideWrapper {

            .swiper-button-next-content {
                display: none;

            }

            .swiper-button-prev-content {
                display: none;
            }
        }
    }

    .ProgressBarWrapper {
        margin-top: 16px;
    }

    .optionDropdown {
        // height: 48px;
        margin-bottom: 24px;
        background-color: $grey_1;

        .defaultTextArea {
            padding: 15px 16px;
            @include notoSans(13px, normal, 1.38);
            color: $grey_6;
        }

        &.isExpanded {
            background-color: $white;

            .defaultTextArea,
            .dropdownItems {
                color: $black;
                @include notoSans(13px, normal, 1.38);

                &:hover {
                    @include notoSans(13px, bold, 1.38);
                    background-color: $grey_2;
                }
            }
        }
    }

    .optionList,
    .productBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 32px;

        .optionItem {
            display: flex;
            flex-direction: column;
            padding: 16px;
            margin-bottom: 8px;
            background-color: $grey_1;
            border-radius: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            .optionItemHeadArea,
            .optionItemBodyArea {
                display: flex;
                // align-items: center;
                justify-content: space-between;
            }

            .optionItemHeadArea {
                margin-bottom: 8px;

                .imgAndName {
                    display: flex;

                    .optionImg {
                        width: 40px;
                        height: 40px;
                        margin-right: 8px;
                        border-radius: 6px;
                    }

                    .optionName {
                        color: $black;
                        @include notoSans(13px, 500, 1.38);
                    }
                }

                .deleteOptionBtn {
                    height: 16px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .priceSection {
                display: flex;

                .discountPercentage {
                    margin-right: 10px;
                    color: $red;
                    @include montSerrat(14px, 900, 1.43);
                }

                .showPrice {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;

                    .displayPrice {
                        @include montSerrat(14px, bold, 1.43);
                    }

                    .regularPrice {
                        @include montSerrat(12px, 500, 1);
                    }
                }
            }
        }
    }

    .bottom-area {
        border-top: unset;
        padding-top: 16px;

        .total-price-area {
            display: flex;
            justify-content: space-between;

            .total-price-text {
                @include notoSans(13px, bold, 1.38);
                color: $black;
            }

            .total-price {
                @include montSerrat(14px, 700, 1.29);
                color: $black;

                .won {
                    @include notoSans(13px, bold, 1.23);
                    // background-color: $black;
                }
            }
        }

        .cta-btns {
            margin-top: 16px;
            display: flex;
            justify-content: space-around;
            height: 48px;
            .cta-btn {
                // padding: 14px 71px;
                width: 99%;
                color: $white;
                background-color: $black;
                border-radius: 24px;
                @include notoSans(14px, bold, 1.43);
                text-align: center;
                line-height: 48px;
                // vertical-align: middle;
                margin-right: 8px;
                cursor: pointer;
                &.red {
                    background-color: $red;
                }

                //마지막은 margin-right x
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .FollowPopup {
        width: 540px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 32px;
        position: fixed;
        bottom: unset;
        // background-color: $white;
        border-radius: 20px;

        &.productPage {
            width: 100%;
            height: auto;
            left: 0;
            max-height: calc(100vh - 50px);
            transform:unset;

            padding: 32px;
            position: fixed;
            bottom: 0;
            background-color: $white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            // overflow: auto; 활성화하면 클로즈 버튼 사라짐...
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
            z-index: map-get($map: $zIndex, $key: CartAddCompletePopup);
        }

        .closeOptionPopupBtn {
            position: absolute;
            top: -30px;
            right: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        .topArea {
            max-height: calc(100vh - 250px);
            overflow: auto;

            .headText {
                // margin-bottom: 14px;
                @include notoSans(18px, 900, 1.44);
                color: #101010;
            }

            .descriptionText {
                margin-bottom: 16px;
                @include notoSans(14px, 500, 1.43);
                color: $red;
            }

            .slideWrapper {

                .swiper-button-next-content {
                    cursor: pointer;
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: calc(50% - 40px);
                    right: -20px;

                    // background:url('/');
                    background: url('/assets/p-btn-arrow-next-40.svg') no-repeat;

                }

                .swiper-button-prev-content {
                    cursor: pointer;
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: calc(50% - 40px);
                    left: -20px;

                    background: url('/assets/p-btn-arrow-prev-40.svg') no-repeat;
                }
            }
        }

        .ProgressBarWrapper {
            display: none;

            margin-top: 16px;
        }

        .optionDropdown {
            // height: 48px;
            margin-bottom: 24px;
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }
                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(14px, bold, 1.43);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                        }
                    }
                }
            }
        }

        .bottom-area {
            border-top: unset;
            padding-top: 16px;

            .total-price-area {
                display: flex;
                justify-content: space-between;

                .total-price-text {
                    @include notoSans(13px, bold, 1.38);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(14px, 700, 1.29);
                    color: $black;

                    .won {
                        @include notoSans(13px, bold, 1.23);
                        // background-color: $black;
                    }
                }
            }

            .cta-btns {
                margin-top: 16px;
                display: flex;
                justify-content: space-around;
                height: 48px;
                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    color: $white;
                    background-color: $black;
                    border-radius: 24px;
                    @include notoSans(14px, bold, 1.43);
                    text-align: center;
                    line-height: 48px;
                    // vertical-align: middle;
                    margin-right: 8px;
                    cursor: pointer;
                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}


@media (min-width: $breakPointDt) {
    .FollowPopup {
        width: 864px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 50px);
        padding: 40px 48px 40px;
        position: fixed;
        bottom: unset;
        border-radius: 20px;

        &.productPage {
            width: 864px;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: calc(100vh - 50px);
            padding: 40px 48px 40px;
            position: fixed;
            bottom: unset;
            background-color: $white;
            border-radius: 20px;
            // overflow: auto; 활성화하면 클로즈 버튼 사라짐...
            box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.07);
            z-index: map-get($map: $zIndex, $key: overDimBg);
        }
        .closeOptionPopupBtn {
            position: absolute;
            top: -30px;
            right: 12px;

            &:hover {
                cursor: pointer;
            }
        }

        .topArea {
            max-height: calc(100vh - 250px);
            overflow: auto;

            .headText {
                margin-bottom: 40px;
                @include notoSans(24px, bold, 1.5);
                color: #101010;
            }

            .descriptionText {
                margin-bottom: 32px;
                @include notoSans(18px, bold, 1.44);
                color: $red;
            }

            .slideWrapper {

                .swiper-button-next-content {
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: calc(50% - 70px);
                    right: 0px;

                    width: 40px;
                    height: 40px;
                    background: url('/assets/p-btn-arrow-next-40.svg') no-repeat;

                    &.swiper-button-disabled {
                        background: url('/assets/p-btn-arrow-next-disable-40.svg') no-repeat;
                    }

                }

                .swiper-button-prev-content {
                    display: block;
                    z-index: 50;
                    position: absolute;
                    top: calc(50% - 70px);
                    left: 0px;

                    width: 40px;
                    height: 40px;
                    background: url('/assets/p-btn-arrow-prev-40.svg') no-repeat;

                    &.swiper-button-disabled {
                        background: url('/assets/p-btn-arrow-prev-disable-40.svg') no-repeat;
                        // transform: rotate(30deg);
                    }
                }
            }
        }

        .ProgressBarWrapper {
            margin-top: 16px;
        }

        .optionDropdown {
            // height: 48px;
            margin-bottom: 24px;
            background-color: $grey_1;

            .defaultTextArea {
                padding: 15px 16px;
                @include notoSans(13px, normal, 1.38);
                color: $grey_6;
            }

            &.isExpanded {
                background-color: $white;

                .defaultTextArea,
                .dropdownItems {
                    color: $black;
                    @include notoSans(13px, normal, 1.38);

                    &:hover {
                        @include notoSans(13px, bold, 1.38);
                        background-color: $grey_2;
                    }
                }
            }
        }

        .optionList,
        .productBox {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            .optionItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                margin-bottom: 8px;
                background-color: $grey_1;
                border-radius: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                .optionItemHeadArea,
                .optionItemBodyArea {
                    display: flex;
                    // align-items: center;
                    justify-content: space-between;
                }

                .optionItemHeadArea {
                    margin-bottom: 8px;

                    .imgAndName {
                        display: flex;

                        .optionImg {
                            width: 40px;
                            height: 40px;
                            margin-right: 8px;
                            border-radius: 6px;
                        }

                        .optionName {
                            color: $black;
                            @include notoSans(13px, 500, 1.38);
                        }
                    }

                    .deleteOptionBtn {
                        height: 16px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .priceSection {
                    display: flex;

                    .discountPercentage {
                        margin-right: 10px;
                        color: $red;
                        @include montSerrat(14px, 900, 1.43);
                    }

                    .showPrice {
                        display: flex;
                        flex-direction: column;
                        // align-items: center;

                        .displayPrice {
                            @include montSerrat(14px, bold, 1.43);
                        }

                        .regularPrice {
                            @include montSerrat(12px, 500, 1);
                        }
                    }
                }
            }
        }

        .bottom-area {
            border-top: unset;
            padding-top: 16px;

            .total-price-area {
                display: flex;
                justify-content: space-between;

                .total-price-text {
                    @include notoSans(13px, bold, 1.38);
                    color: $black;
                }

                .total-price {
                    @include montSerrat(14px, 700, 1.29);
                    color: $black;

                    .won {
                        @include notoSans(13px, bold, 1.23);
                        // background-color: $black;
                    }
                }
            }

            .cta-btns {
                margin-top: 16px;
                display: flex;
                justify-content: space-around;
                height: 56px;
                .cta-btn {
                    // padding: 14px 71px;
                    width: 99%;
                    color: $white;
                    background-color: $black;
                    border-radius: 24px;
                    @include notoSans(16px, bold, 1.5);
                    text-align: center;
                    line-height: 56px;
                    margin-right: 8px;
                    cursor: pointer;
                    &.red {
                        background-color: $red;
                    }

                    //마지막은 margin-right x
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
