@import '../../RootVariable.scss';

.LGPromotionPage {
    width: 100%;
    padding: 100px 0 80px;

    img {
        width: 100%;
        &.mb {
            display: block;
        }

        &.pc {
            display: none;
        }

        &.click {
            cursor: pointer;
        }
    }
}

@media (min-width: $breakPointDt) {
    .LGPromotionPage {
        width: 1200px;
        max-width: 1328px;
        padding: 100px 64px 160px;
        margin: 0 auto;

        img {
            &.mb {
                display: none;
            }

            &.pc {
                display: block;
            }

            &.radius {
                border-radius: 24px;
            }
        }
    }
}
