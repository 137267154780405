@import '../../RootVariable.scss';

.OrderAndCheckoutContainer {
    width: 100%;
    height: 100%;
    //height: calc(100vh - 124px);
    padding: 0 32px;
    padding-bottom: 200px;
    position: fixed;
    bottom: 0;
    background-color: $white;
    overflow-x: hidden;
    // overflow-y: scroll;
    visibility: hidden;
    z-index: map-get($map: $zIndex, $key: overDimBg);

    &.addDimmer {
        z-index: calc(map-get($map: $zIndex, $key: overDimBg) - 1);
    }

    // .orderAndCheckoutContentsArea {
    //     overflow-y: scroll;
    // }

    .closeBtn {
        // position: absolute;
        position: fixed;
        top: 12px;
        right: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    .orderAndCheckoutTitle {
        padding: 40px 0 24px;
        @include notoSans(26px, 900, 1.38);
    }

    .orderAndCheckoutSubTitle {
        padding: 16px 0;
        @include notoSans(18px, 900, 1.44);
    }

    .ordererInfo,
    .deliveryInfo {
        margin-bottom: 24px;
    }

    .deliveryInfo {
        input {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .addressInput {
            display: flex;
            align-items: center;
            margin-bottom: 8px;

            input {
                margin-bottom: 0;
                margin-right: 8px;
            }

            .findAddressBtn {
                padding: 14px 19px;
                border: 1px solid $grey_5;
                border-radius: 6px;
                @include notoSans(14px, 500, 1.43);
                white-space: nowrap;

                &:hover {
                    cursor: pointer;
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }

    .checkoutMethods {
        margin-bottom: 32px;

        .btnsGroup {
            .group {
                width: 100%;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-bottom: 8px;
                }

                div {
                    width: 144px;
                    padding: 19px;
                    @include notoSans(13px, bold, 1.38);
                    border: 1px solid $grey_5;
                    border-radius: 6px;
                    text-align: center;
                    white-space: nowrap;
                    flex-grow: 1;
                    cursor: pointer;

                    &:first-child {
                        margin-right: 8px;
                    }

                    &.selected {
                        background-color: $black;
                        color: $white;
                    }

                    // &:hover,
                    // &:focus {
                    //     background-color: $black;
                    //     color: $white;
                    // }
                }
            }
        }
    }
}

@media (min-width: $breakPointTbl) {
    .OrderAndCheckoutContainer {
        width: 588px;
        padding-bottom: 100px;
        position: fixed;
        top: 0;
        right: -588px;
        overflow: visible;
        overflow-y: scroll;
        animation: slide 0.3s forwards;

        .checkoutMethods {
            margin-bottom: 81px;
        }
    }
}

@keyframes slide {
    0% {
        right: -588px;
    }
    100% {
        right: 0;
    }
}

@media (min-width: $breakPointDt) {
    .OrderAndCheckoutContainer {
        width: 588px;
        padding: 0 48px;
        position: fixed;
        top: 0;
        right: 0;
        overflow: visible;
        overflow-y: scroll;
        padding-bottom: 100px;

        input {
            padding: 20px 24px;
            @include notoSans(16px, normal, 1.5);
        }

        .closeBtn {
            top: 16px;
            right: 16px;
        }

        .orderAndCheckoutTitle {
            padding: 56px 0;
            @include notoSans(44px, 900, 1.36);
        }

        .orderAndCheckoutSubTitle {
            padding: 24px 0;
            @include notoSans(26px, bold, 1.46);
        }

        .ordererInfo,
        .deliveryInfo {
            margin-bottom: 33px;
        }

        .deliveryInfo {
            .addressInput {
                .findAddressBtn {
                    padding: 20px 32px;
                    @include notoSans(16px, 500, 1.5);
                }
            }
        }

        .checkoutMethods {
            margin-bottom: 68px;

            .btnsGroup {
                .group {
                    div {
                        width: 242px;
                        padding: 24px;
                        @include notoSans(16px, bold, 1.5);
                    }
                }
            }
        }
    }
}
