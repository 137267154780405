@import '../../RootVariable.scss';

.NaverPayButton {
    width: 100%;
    height: 48px;
    margin-bottom: 40px;
    padding: 14px 93px 14px 94px;
    border: 1px solid $grey_5;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
        width: 109px;
        height: 20px;
        background-image: url('../../assets/common-logo-npay@2x.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:hover {
        cursor: pointer;
    }
}

@media (min-width: $breakPointTbl) {
    .NaverPayButton {
        display: none;
    }
}

@media (min-width: $breakPointDt) {
    .NaverPayButton {
        display: none;
    }
}

@media (min-width: $breakPointTbl) {
    .NaverPayButtonMobile {
        display: none;
    }
}

@media (min-width: $breakPointDt) {
    .NaverPayButtonMobile {
        display: none;
    }
}

