@import '../../RootVariable.scss';

.homeTopContainerWrapper.homeContainers {
    // margin-top: 130px;
    margin-top: 24px;
}
.homeTopContainerWrapper {
    display: flex;
    justify-content: center;
    position: relative;

    height: fit-content;

    .progressBarWrapper {
        margin-top: 110px;
    }

    .swiper-button-next::after {
        display: none;
    }
    .swiper-button-next:hover::after {
        display: none;
    }

    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-prev:hover::after {
        display: none;
    }

    .swiper-button-prev.swiper-button-disabled::after {
        display: none;
    }

    .swiper-button-next.swiper-button-disabled::after {
        display: none;
    }
}

.homeTopSliderImg {
    width: 100%;
}
.homeTopSliderContentBox {
    padding: 0;
    @include defaultShadow;
    width: 100%;
    height: 0;
    padding-top: 100%;
    position: relative;
}

.homeTopTextPositioning {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 25px;
    z-index: map-get($map: $zIndex, $key: homeSliderText);
    // @include defaultShadow;
}

@media (min-width: $breakPointTbl) {
    .homeTopContainerWrapper.homeContainers {
        // margin-top: 130px;
        margin-top: 48px;
    }

    .homeTopContainerWrapper {
        max-width: unset;

        .progressBarWrapper {
            margin-top: 96px;
        }
        @include setSliderNavigationSizeAndPosition(40px, 43%);

        // .swiper-button-next::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }
        // .swiper-button-next:hover::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }

        // .swiper-button-prev::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }

        // .swiper-button-prev:hover::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }

        // .swiper-button-prev.swiper-button-disabled::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }
        // .swiper-button-next.swiper-button-disabled::after {
        //     width: 72px;
        //     height: 72px;
        //     display: block;
        // }

        // .swiper-button-next,
        // .swiper-button-prev {
        //     width: 72px !important;
        //     height: 72px !important;
        //     // top: 44% !important;
        //     // position: absolute;
        // }

        // .swiper-button-next {
        //     right: -36px !important;
        // }

        // .swiper-button-prev {
        //     left: -36px !important;
        // }
    }

    .homeTopSliderContentBox {
        padding-top: 53%;
    }
}

@media (min-width: $breakPointDt) {
    .homeWrapper {
        .homeTopContainerWrapper.homeContainers {
            // margin-top: 201px;
        }
    }

    .homeTopTextPositioning {
        bottom: 45px;
    }

    .homeTopContainerWrapper {
        max-width: 1200px;

        .progressBarWrapper {
            margin-top: 184px;
        }

        .swiper-button-next::after {
            width: 72px;
            height: 72px;
            display: block;
        }
        .swiper-button-next:hover::after {
            width: 72px;
            height: 72px;
            display: block;
        }

        .swiper-button-prev::after {
            width: 72px;
            height: 72px;
            display: block;
        }

        .swiper-button-prev:hover::after {
            width: 72px;
            height: 72px;
            display: block;
        }

        .swiper-button-prev.swiper-button-disabled::after {
            width: 72px;
            height: 72px;
            display: block;
        }
        .swiper-button-next.swiper-button-disabled::after {
            width: 72px;
            height: 72px;
            display: block;
        }

        .swiper-button-next,
        .swiper-button-prev {
            width: 72px !important;
            height: 72px !important;
            // top: 44% !important;
            // position: absolute;
        }

        .swiper-button-next {
            right: -36px !important;
        }

        .swiper-button-prev {
            left: -36px !important;
        }
    }
}
