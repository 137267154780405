@import '../../RootVariable.scss';


.detail-image-wrapper{


    width: 100%;
    padding-top: min(100% , 500px) ;
    position: relative;
    // height: 100%;
}

.detail-inner-position{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 500px;
    min-height: inherit;
    
}


@media (min-width:$breakPointDt) {

.detail-image-wrapper{

    // min-height: 900px;
    min-height: 800px;
    width: 100%;
    padding-top: min(100% , 500px) ;
    position: relative;
    // height: 100%;
}

    .detail-inner-position{
        // min-height: 900px;
        min-height: 800px;
        max-height: inherit;
        
    }
        
 


}


